import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useSelector, useDispatch } from 'react-redux'
import { deleteFaculty } from '@features/faculty/facultySlice'

const DeleteFaculty = ({ deleteModal, setDeleteModal, faculty }) => {
  const dispatch = useDispatch()
  const handleDeleteFaculty = (faculty) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteFaculty(faculty?.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Факультет <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить факультет {faculty.name}</div>
            <button className="modal_delete_button button" onClick={() => handleDeleteFaculty(faculty)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteFaculty
