import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice base-reviews requests
export const getPracticeBaseReview = createAsyncThunk(
  'practiceBaseReview/practiceBaseReviewById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-base-reviews/${data.baseUserId}/practices/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeBaseReviews = createAsyncThunk(
  'practiceBaseReview/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-base-reviews/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeBaseReviewQrs = createAsyncThunk(
  'practiceBaseReview/qrs',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-base-reviews/qr/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeBaseReview = createAsyncThunk(
  'practiceBaseReview/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-base-reviews/${data.practice_id}`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeBaseReview = createAsyncThunk(
  'practiceBaseReview/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)
    try {
      const response = await axios.put(`${url}/api/practice-base-reviews/${data.uuid}`, data, config)
      
      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateSignaturePracticeBaseReview = createAsyncThunk(
  'practiceBaseReview/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-base-reviews/${data.uuid}/signature`, data, config)

      dispatch(createAlert({
        message: "Подпись поставлен",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeBaseReview = createAsyncThunk(
  'practiceBaseReview/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceBaseReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-base-reviews/${data.teacher_id}/practice/${data.practice_id}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceBaseReviewSlice = createSlice({
  name: 'practiceBaseReview',
  initialState: {
    loading: 'idle',
    practiceBaseReview: null,
    practiceBaseReviews: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice BaseReview reducers
    // One
    [getPracticeBaseReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeBaseReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReview = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeBaseReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeBaseReviews.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeBaseReviews.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReviews = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeBaseReviews.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    [getPracticeBaseReviewQrs.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeBaseReviewQrs.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReviews = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeBaseReviewQrs.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeBaseReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeBaseReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReview = action.payload.data
        state.practiceBaseReviews.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeBaseReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeBaseReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeBaseReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReview = action.payload.data
        state.practiceBaseReviews = state.practiceBaseReviews.map(practiceBaseReview =>
          practiceBaseReview.uuid === action.payload.data.uuid ? practiceBaseReview = action.payload.data : practiceBaseReview
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeBaseReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update signature
    [updateSignaturePracticeBaseReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateSignaturePracticeBaseReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReview = action.payload.data
        state.practiceBaseReviews = state.practiceBaseReviews.map(practiceBaseReview =>
          practiceBaseReview.uuid === action.payload.data.uuid ? practiceBaseReview = action.payload.data : practiceBaseReview
        )
        state.currentRequestId = undefined
      }
    },
    [updateSignaturePracticeBaseReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeBaseReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeBaseReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceBaseReview = null
        state.practiceBaseReviews = state.practiceBaseReviews.filter(practiceBaseReview => practiceBaseReview.id !== action.payload.teacher_id)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeBaseReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticeBaseReviewLoading = state => state.practiceBaseReview.loading
export const selectPracticeBaseReviewFilterLoading = state => state.practiceBaseReview.filterLoading
export const selectPracticeBaseReview = state => state.practiceBaseReview.practiceBaseReview
export const selectPracticeBaseReviews = state => state.practiceBaseReview.practiceBaseReviews

export default practiceBaseReviewSlice.reducer
