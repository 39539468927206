import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice schedules requests
export const getPracticeStudentReview = createAsyncThunk(
  'practiceStudentReview/practiceStudentReviewById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudentReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-student-reviews/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStudentReviews = createAsyncThunk(
  'practiceStudentReview/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudentReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-student-reviews/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeStudentReview = createAsyncThunk(
  'practiceStudentReview/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudentReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-student-reviews/${data.practiceId}/students/${data.studentId}`, data, config)
      data.history.push(`/student-practices/${data.practiceId}/students/${data.studentId}/plan`)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeStudentReview = createAsyncThunk(
  'practice/updateReview',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudentReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-student-reviews/${data.uuid}`, data, config)

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeStudentReview = createAsyncThunk(
  'practiceStudentReview/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudentReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-student-reviews/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const addStudentSignature = createAsyncThunk(
  'practice/addStudentSignature',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, addSignatureLoading } = getState().practiceStudentReview
    if (addSignatureLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-student-reviews/${data.uuid}/signature`, data, config)

      dispatch(createAlert({
        message: "Подпись поставлен",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceStudentReviewSlice = createSlice({
  name: 'practiceStudentReview',
  initialState: {
    loading: 'idle',
    addSignatureLoading: 'idle',
    practiceStudentReview: null,
    practiceStudentReviews: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Student reducers
    // One
    [getPracticeStudentReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudentReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudentReview = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudentReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeStudentReviews.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudentReviews.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudentReviews = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudentReviews.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeStudentReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeStudentReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudentReview = action.payload.data
        state.practiceStudentReviews.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeStudentReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeStudentReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeStudentReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudentReview = action.payload.data
        state.practiceStudentReviews = state.practiceStudentReviews.map(practiceStudentReview =>
          practiceStudentReview.uuid === action.payload.data.uuid ? practiceStudentReview = action.payload.data : practiceStudentReview
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeStudentReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeStudentReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeStudentReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudentReview = null
        state.practiceStudentReviews = state.practiceStudentReviews.filter(practiceStudentReview => practiceStudentReview.uuid !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeStudentReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Add signature
    [addStudentSignature.pending]: (state, action) => {
      if (state.addSignatureLoading === 'idle') {
        state.addSignatureLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [addStudentSignature.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.addSignatureLoading === 'pending' && state.currentRequestId === requestId) {
        state.addSignatureLoading = 'idle'
        state.practiceStudentReview = action.payload.data
        state.practiceStudentReviews = state.practiceStudentReviews.map(practiceStudentReview =>
          practiceStudentReview.uuid === action.payload.data.uuid ? practiceStudentReview = action.payload.data : practiceStudentReview
        )
        state.currentRequestId = undefined
      }
    },
    [addStudentSignature.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.addSignatureLoading === 'pending' && state.currentRequestId === requestId) {
        state.addSignatureLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

  }
})

export const selectPracticeStudentReviewLoading = state => state.practiceStudentReview.loading
export const selectAddSignatureLoading = state => state.practiceStudentReview.addSignatureLoading
export const selectPracticeStudentReview = state => state.practiceStudentReview.practiceStudentReview
export const selectPracticeStudentReviews = state => state.practiceStudentReview.practiceStudentReviews

export default practiceStudentReviewSlice.reducer
