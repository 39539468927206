import React from 'react'
import { Link } from 'react-router-dom'
import Info from './Info'

const Settings = () => {
  return (
    <div className="settings">
      <Info />
    </div>
  )
}

export default Settings
