import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { Multiselect } from 'multiselect-react-dropdown'

import trash from '@icons/trash.png'
import Spinner from '@components/layout/Spinner'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPracticeStudents, createPracticeStudent, selectPracticeStudentLoading, selectPracticeStudents } from '@features/practice/practiceStudentSlice'
import { getGroups, selectGroups, selectGroupLoading } from '@features/group/groupSlice'
import { filterStudents, selectFilteredStudents, selectFilterStudentLoading, resetFilteredStudents } from '@features/student/studentSlice'
import { getPractice, selectPractice } from '@features/practice/practiceSlice'

const CreatePracticeStudent = ({ match }) => {
  const [filtered, setFiltered] = useState(false)
  const { handleSubmit, register, setValue } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const groups = useSelector(selectGroups)
  const groupLoading = useSelector(selectGroupLoading)
  const practiceStudents = useSelector(selectPracticeStudents)
  const filteredStudents = useSelector(selectFilteredStudents)
  const filterStudentLoading = useSelector(selectFilterStudentLoading)
  const practice = useSelector(selectPractice)

  useEffect(() => {
    dispatch(getPracticeStudents(match.params.practiceId))
    dispatch(getPractice(match.params.practiceId))
    dispatch(getGroups())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data
    }

    dispatch(filterStudents(validData))
    setFiltered(true)
  }

  useEffect(() => {
    register("group_id")
  }, [register])

  const onSelect = (selectedList, selectedItem) => {
    setValue("group_id", selectedItem.id)
  }

  const onRemove = () => {
    setValue("group_id", "none")
  }

  const handleAddStudent = (student) => {
    const data = {
      student_id: student?.id,
      practice_id: practice?.id
    }

    dispatch(createPracticeStudent(data))
  }

  const handleResetFilter = () => {
    setFiltered(false)
    dispatch(resetFilteredStudents())
  }

  return (
    <Fragment>
        <form className="filter_block mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="filter_block_search">
            <div className="filter_block_group">
              <label htmlFor="last_name">
                Фамилия
              </label>
              <input id="last_name" name="last_name" ref={register} />
            </div>

            <div className="filter_block_group">
              <label htmlFor="first_name">
                Имя
              </label>
              <input id="first_name" name="first_name" ref={register} />
            </div>
            
            <div className="filter_block_group">
              <label htmlFor="middle_name">
                Отчество
              </label>
              <input id="middle_name" name="middle_name" ref={register} />
            </div>
          </div>

          <div className="filter_block_bottom mt-10">
            <Multiselect
              options={groups}
              loading={groupLoading === "pending"}
              loadingMessage="Загружем..."
              emptyRecordMsg="Групп не найдено"
              avoidHighlightFirstOption={true}
              placeholder="Группа"
              hidePlaceholder={true}
              displayValue="name"
              onSelect={onSelect}
              onRemove={onRemove}
              selectionLimit={1}
            />

            <button className="button filter_button">Фильтровать</button>
            { filtered && <div className="filter_button reset_filter_button" onClick={() => handleResetFilter()}>Сбросить фильтр</div> }
          </div>
        </form>

      {filteredStudents && filteredStudents.length > 0 && filterStudentLoading === "pending" ? <Spinner /> : ( <div className="table">
        <div className="table_create_title">
          <div className="table_title">Поиск</div>
          <Link to={`/chair-practices/${match.params.practiceId}/students`} className="table_create link">Список Практикантов</Link>
        </div>

        {filterStudentLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Группа</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {filteredStudents?.map((student, index) => (
              <tr key={student.id}>
                <td>{++index}</td>
                <td>{`${student.user.last_name_ru} ${student.user.first_name_ru}`}</td>
                <td>{`${student.group.name}`}</td>
                {
                  practiceStudents?.some(s => s.student.id === student.id) ? <td>Добавлен</td> : <td><button onClick={() => handleAddStudent(student)} className="button_add">+</button></td>
                }
              </tr>
            ))}
          </tbody>
        </table></div>}
      </div> )}
    </Fragment>
  )
}

export default CreatePracticeStudent
