export const is = (roleName, user) => {
  return user?.roles.find(role => role.name === roleName)
}

export const roleFilterById = (roleId, user) => {
  return user?.roles.find(roleItem => roleItem.id === roleId)
}

export const formatDate = (date) => {
  const newDate = date?.replace(/-/g, '.')
  return newDate?.split('.').reverse().join('.')
}

export const getUserFullName = (user) => {
  if (!user.last_name_ru || !user.first_name_ru || !user.middle_name_ru) {
    return ''
  }
  
  return `${user.last_name_ru} ${user.first_name_ru} ${user.middle_name_ru}`
}

export const isNameNull = (user) => {
  if (!user) {
    return false
  }

  return !user.last_name_ru || !user.first_name_ru || !user.middle_name_ru || !user.last_name_kz || !user.first_name_kz || !user.middle_name_kz || !user.last_name_en || !user.first_name_en || !user.middle_name_en
}

export const required = {
  required: "Это поле обязательна для заполнения"
}

export const getRoleName = (role) => {
  switch (role.name) {
    case 'admin':
      return 'Администратор'
    case 'student':
      return 'Студент'
    case 'teacher':
      return 'Преподаватель'
    case 'headOfChair':
      return 'Заведующий кафедрой'
    case 'dean':
      return 'Декан'
    case 'practiceChairLeader':
      return 'Руководитель практики (на кафедре)'
    case 'practiceBaseLeader':
      return 'Руководитель практики (на базе)'
    case 'practiceComission':
      return 'Комиссия для практики'
    case 'methodCenterUser':
      return 'Сотрудник УМЦ'
    default:
      return 'Кто-то другой'
  }
}