import React from 'react'
import { Link } from 'react-router-dom'
import { toggleMenu } from '@features/layout/layoutSlice'
import Icon from '@components/layout/Icon'

import { useDispatch } from 'react-redux'

const ListItem = ({ img, title, link }) => {
  const dispatch = useDispatch()

  const handleMenuClose = () => {
    dispatch(toggleMenu())
  }

  return (
    <li className="nav_item" onClick={() => handleMenuClose()}>
      <Link to={`/${link}`} className="nav_link">
        <Icon name={img} className="nav_img" isMenu={true} />
        {title}
      </Link>
    </li>
  )
}

export default ListItem
