import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, Link } from 'react-router-dom'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, updateUserInfo } from '@features/auth/authSlice'

const Info = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectUser)
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = (data) => {
    const validData = {
      ...data,
      id: user.id,
      history
    }

    dispatch(updateUserInfo(validData))
  }

  return (
    <Fragment>
      <div className="crud_title mt-15">Заполните все поля           
      <Link to={`/dashboard`} className="table_create link link_w447">Профиль</Link></div>

      <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>

        <div className="crud_form_block">
          <h4 className="crud_form_block_header">ФИО на русском</h4>
          <label htmlFor="last_name_ru">
            Фамилия на русском <span className="input_error">{errors.last_name_ru && errors.last_name_ru.message}</span>
          </label>
          <input type="text" id="last_name_ru" defaultValue={user.last_name_ru} name="last_name_ru" ref={register(required)} />

          <label htmlFor="first_name_ru">
            Имя на русском <span className="input_error">{errors.first_name_ru && errors.first_name_ru.message}</span>
          </label>
          <input type="text" id="first_name_ru" defaultValue={user.first_name_ru} name="first_name_ru" ref={register(required)} />

          <label htmlFor="middle_name_ru">
            Отчество на русском <span className="input_error">{errors.middle_name_ru && errors.middle_name_ru.message}</span>
          </label>
          <input type="text" id="middle_name_ru" defaultValue={user.middle_name_ru} name="middle_name_ru" ref={register(required)} />
        </div>

        <div className="crud_form_block">
          <h4 className="crud_form_block_header">ФИО на казахском</h4>
          <label htmlFor="last_name_kz">
            Фамилия на казахском <span className="input_error">{errors.last_name_kz && errors.last_name_kz.message}</span>
          </label>
          <input type="text" id="last_name_kz" defaultValue={user.last_name_kz} name="last_name_kz" ref={register(required)} />

          <label htmlFor="first_name_kz">
            Имя на казахском <span className="input_error">{errors.first_name_kz && errors.first_name_kz.message}</span>
          </label>
          <input type="text" id="first_name_kz" defaultValue={user.first_name_kz} name="first_name_kz" ref={register(required)} />

          <label htmlFor="middle_name_kz">
            Отчество на казахском <span className="input_error">{errors.middle_name_kz && errors.middle_name_kz.message}</span>
          </label>
          <input type="text" id="middle_name_kz" defaultValue={user.middle_name_kz} name="middle_name_kz" ref={register(required)} />
        </div>

        <div className="crud_form_block">
          <h4 className="crud_form_block_header">ФИО на английском</h4>
          <label htmlFor="last_name_en">
            Фамилия на английском <span className="input_error">{errors.last_name_en && errors.last_name_en.message}</span>
          </label>
          <input type="text" id="last_name_en" defaultValue={user.last_name_en} name="last_name_en" ref={register(required)} />

          <label htmlFor="first_name_en">
            Имя на английском <span className="input_error">{errors.first_name_en && errors.first_name_en.message}</span>
          </label>
          <input type="text" id="first_name_en" defaultValue={user.first_name_en} name="first_name_en" ref={register(required)} />

          <label htmlFor="middle_name_en">
            Отчество на английском <span className="input_error">{errors.middle_name_en && errors.middle_name_en.message}</span>
          </label>
          <input type="text" id="middle_name_en" defaultValue={user.middle_name_en} name="middle_name_en" ref={register(required)} />
        </div>

        <button className="button">Сохранить</button>
      </form>
    </Fragment>
  )
}

export default Info
