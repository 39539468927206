import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { formatDate } from '@utils/helpers'

// Icons
import comission from '@icons/comission.png'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPractices, selectPractices, selectPracticeLoading, togglePractice } from '@features/practice/practiceSlice'

const Practice = () => {
  const dispatch = useDispatch()
  const practices = useSelector(selectPractices)
  const loading = useSelector(selectPracticeLoading)

  useEffect(() => {
    dispatch(getPractices())
  }, [dispatch])

  const handleTogglePractice = (practiceId, status) => {
    const data = {
      practiceId,
      status
    }

    dispatch(togglePractice(data))
  }

  return loading === "pending" ? <Spinner /> : (
    <Fragment>
          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Группы</div>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Руководитель практики (на кафедре)</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="table_body_separated">
                { practices?.slice(0).reverse().map((practice, index) => (
                  <tr key={practice.id}>
                    <td>{++index}</td>
                    <td>{practice.name} 
                      <p>{formatDate(practice.start)} - {formatDate(practice.end)}</p>
                    </td>
                    <td>
                      <p>
                        {`${practice.teacher.user.last_name_ru} ${practice.teacher.user.first_name_ru}`}
                      </p>
                      <p>{practice.teacher.chair.name_ru}</p>
                    </td>
                    <td>
                      <p>
                        <Link className="admin_icons" to={`/method-center-practices/${practice.uuid}/comissions`}><img src={comission} /></Link>
                      
                        <Link className="admin_icons" to={`/method-center-practices/${practice.uuid}/students`}><img src={`${process.env.PUBLIC_URL}/img/icons/menu/my-profile.png`} /></Link>
                      </p>
                      
                      <p>
                        <Link to={`/method-center-practices/${practice.uuid}/students/stats`}><button className="table_create button">Оценки</button></Link>
                      
                        { practice.status ? 
                          <button onClick={() => handleTogglePractice(practice.uuid, 0)} className="table_create button button--red">Закрыть</button> :
                          <button onClick={() => handleTogglePractice(practice.uuid, 1)} className="table_create button">Открыть</button>
                        }
                      </p>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table></div>
          </div>
    </Fragment> )
}

export default Practice
