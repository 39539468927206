import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'

import Spinner from '@components/layout/Spinner'
import DeletePracticeSchedule from './DeletePracticeSchedule'
import DeletePracticeContent from './DeletePracticeContent'
import PracticeInfo from '@features/practice/components/PracticeInfo'

import { formatDate } from '@utils/helpers'
import { url } from '@utils/api'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPractice, selectPractice, getPracticeWithUser, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getPracticeSchedules, selectPracticeSchedules, selectPracticeScheduleLoading,  selectPracticeScheduleCommentLoading } from '@features/practice/practiceScheduleSlice'
import { getPracticeContents, selectPracticeContents, selectPracticeContentLoading, selectPracticeContentCommentLoading } from '@features/practice/practiceContentSlice'
import { getStudent, selectStudent, selectStudentLoading } from '@features/student/studentSlice'
import { getDeanByChair, selectDean, selectDeanLoading } from '@features/dean/deanSlice'
import { addStudentSignature, getPracticeStudentReview, selectPracticeStudentReview, selectPracticeStudentReviewLoading } from '@features/practice/practiceStudentReviewSlice'
import { createPracticePdf, selectPracticePdfLoading, selectPracticePdf, getPracticePdf } from '@features/practice/practicePdfSlice'

const PracticePlan = ({ match }) => {
  const [deleteContentModal, setDeleteContentModal] = useState(false)
  const [deleteScheduleModal, setDeleteScheduleModal] = useState(false)
  const [selectedContent, setSelectedContent] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [reportLoading, setReportLoading] = useState('pending')

  const dispatch = useDispatch()
  const practice = useSelector(selectPractice)
  const dean = useSelector(selectDean)
  const student = useSelector(selectStudent)
  const deanLoading = useSelector(selectDeanLoading)
  const practiceLoading = useSelector(selectPracticeLoading)
  const studentLoading = useSelector(selectStudentLoading)
  const practiceSchedules = useSelector(selectPracticeSchedules)
  const practiceScheduleLoading = useSelector(selectPracticeScheduleLoading)
  const practiceContents = useSelector(selectPracticeContents)
  const practiceContentLoading = useSelector(selectPracticeContentLoading)
  const practiceStudentReview = useSelector(selectPracticeStudentReview)
  const practiceStudentReviewLoading = useSelector(selectPracticeStudentReviewLoading)
  const pdfLoading = useSelector(selectPracticePdfLoading)
  const pdfLink = useSelector(selectPracticePdf)
  const practiceScheduleCommentLoading = useSelector(
    selectPracticeScheduleCommentLoading
  )
  const practiceContentCommentLoading = useSelector(
    selectPracticeContentCommentLoading
  )
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    dispatch(getPracticeWithUser(practiceId)).then(res => {
      dispatch(getDeanByChair(res.payload.data.teacher.chair.id))
    })
    dispatch(getStudent(studentId))
    const data = {
      practiceId,
      studentId
    }
    dispatch(getPracticeSchedules(data))
    dispatch(getPracticeContents(data))
    dispatch(getPracticeStudentReview(data)).then(res => { 
      dispatch(getPracticePdf(data)).then(res => setReportLoading('idle')) 
    })
  }, [dispatch])

  const handleDeleteScheduleForm = (schedule) => {
    setDeleteScheduleModal(!deleteScheduleModal)
    setSelectedSchedule(schedule)
  }

  const handleDeleteContentForm = (content) => {
    setDeleteContentModal(!deleteContentModal)
    setSelectedContent(content)
  }

  const handleAddSignature = () => {
    dispatch(addStudentSignature(practiceStudentReview))
  }

  const handleCreatePdf = () => {
    const data = {
      practiceId,
      studentId,
    };

    dispatch(createPracticePdf(data)).then(res => { 
      dispatch(getPracticePdf(data)).then(res => setReportLoading('idle')) 
    })
  }

  const handleCopy = (e) => {
    e.preventDefault()

    return false
  }

  return (
    (<Fragment>
      {deleteScheduleModal && <DeletePracticeSchedule deleteScheduleModal={deleteScheduleModal} setDeleteScheduleModal={setDeleteScheduleModal} schedule={selectedSchedule} />}

      {deleteContentModal && <DeletePracticeContent deleteContentModal={deleteContentModal} setDeleteContentModal={setDeleteContentModal} content={selectedContent} />}

      <div className="trainee">
        <div className="trainee_title">
          Практика
          <Link to={'/student-practices'} className="table_create link">Список практик</Link>
          {practiceStudentReviewLoading === "pending" ? <span className="table_create">Загружаем...</span> : <button className={practiceStudentReview?.signature ? `button_disabled table_create button_w447` : `button table_create button_w447`} onClick={() => handleAddSignature()} disabled={practiceStudentReview?.signature ? true : false}>{practiceStudentReview?.signature ? "Подпись поставлен" : "Поставить подпись"}</button>}
          <div className="mt-10">
            {reportLoading === "pending" || pdfLoading === "pending" ? (
              <span>Загружаем...</span>
            ) : (
              <button className="button" onClick={() => handleCreatePdf()}>
                Сформировать отчет
              </button>
            )}
            {reportLoading === 'pending' ? (
              <span className="table_create">Загружаем...</span>
            ) : (
              pdfLink && <a target="_blank" rel="noreferrer" download href={`${url}/practice/${practiceId}/${studentId}/${pdfLink}`} className="table_create link">Скачать отчет</a>
            )}
          </div>
        </div>

        <PracticeInfo practice={practice} student={student} dean={dean} practiceLoading={practiceLoading} studentLoading={studentLoading} deanLoading={deanLoading} />
        
        <div className="trainee_table_title">Рабочий план-график производственной практики 
          {practice?.status ? <Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/plan/schedule/create`}><button className="button table_create button_w563">+ Добавить</button></Link> : ''}
        </div>

        { practiceScheduleLoading === "pending" ? "Загружаем..." : <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
          <thead>
            <tr>
              <th>#</th>
              <th>Перечень работ, подлежащих выполнению (изучению) в соответствии с рабочей программой производственной практики</th>
              <th colSpan="2">Сроки выполнения программы практики</th>
              <th>Примечание</th>
              <th>Действия</th>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td>Начало</td>
              <td>Завершение</td>
              <td></td>
              <td></td>
            </tr>
          </thead>

          <tbody className="student_practice_table_body">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            { practiceSchedules?.map((schedule, index) => (
                <Fragment key={schedule?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>{ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(schedule?.content) }} /> }</td>
                    <td>{formatDate(schedule?.start)}</td>
                    <td>{formatDate(schedule?.end)}</td>
                    <td>{schedule?.note}</td>
                    <td className="d-flex">
                      {practice?.status ? <Fragment><Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/plan/schedule/${schedule?.uuid}/update`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteScheduleForm(schedule)} className="admin_icons" src={trash} alt=""/></Fragment> : ''}
                    </td>
                  </tr>
                  
                  <tr>
                    <td></td>
                    <td>
                      {practiceScheduleCommentLoading === "pending"
                        ? "Загружаем..."
                        : schedule?.practiceScheduleComments.map(
                            (comment, index) => (
                              <Fragment key={comment.id}>
                                <div className="comment">
                                  <div>
                                    {++index}. {comment.comment}
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  
                </Fragment>
              ))
            }
          </tbody>
        </table></div>}

        <div className="trainee_table_title">Содержание производственной практики 
          {practice?.status ? <Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/plan/content/create`}><button className="button table_create button_w563">+ Добавить</button></Link> : ''}
        </div>

        { practiceContentLoading === "pending" ? "Загружаем..." : <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
          <thead>
            <tr>
              <th>#</th>
              <th>Наименование и содержание выполненных (изученых) работ в соответствии с рабочей программой производственной практики за каждый день</th>
              <th colSpan="2">Сроки выпол. отдельных тем, работ практики</th>
              <th>Действия</th>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td>Начало</td>
              <td>Завершение</td>
              <td></td>
            </tr>
          </thead>

          <tbody className="student_practice_table_body">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            { practiceContents?.map((content, index) => (
                <Fragment key={content?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>{ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.content) }} /> }</td>
                    <td>{formatDate(content?.start)}</td>
                    <td>{formatDate(content?.end)}</td>
                    <td className="d-flex">
                      {practice?.status ? <Fragment><Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/plan/content/${content?.uuid}/update`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteContentForm(content)} className="admin_icons" src={trash} alt=""/></Fragment> : ''}
                    </td>
                  </tr>
                  
                  <tr>
                    <td></td>
                    <td>
                      {practiceContentCommentLoading === "pending"
                        ? "Загружаем..."
                        : content?.practiceContentComments.map(
                            (comment, index) => (
                              <Fragment key={comment.id}>
                                <div className="comment">
                                  <div>
                                    {++index}. {comment.comment}
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </Fragment>
              ))
            }
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticePlan
