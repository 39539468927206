import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    menuState: false,
  },
  reducers: {
    toggleMenu: state => {
      state.menuState = !state.menuState
    }
  },
})

export const { toggleMenu } = layoutSlice.actions

export const selectMenuState = state => state.layout.menuState

export default layoutSlice.reducer
