import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import DeleteProgram from './DeleteProgram'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPrograms, selectPrograms, selectProgramLoading } from '@features/program/programSlice'

const Program = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const dispatch = useDispatch()
  const programs = useSelector(selectPrograms)
  const loading = useSelector(selectProgramLoading)

  useEffect(() => {
    dispatch(getPrograms())
  }, [dispatch]);

  const handleDeleteForm = (program) => {
    setDeleteModal(!deleteModal)
    setSelectedProgram(program)
  }

  return loading === "pending" ? ( <Spinner /> ) : (
    <Fragment>
          {deleteModal && <DeleteProgram deleteModal={deleteModal} setDeleteModal={setDeleteModal} program={selectedProgram} />}

          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Образовательные программы</div>
              <Link to="/programs/create"><button className="table_create button">+ Добавить</button></Link>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Факультет</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {programs?.map(program => (
                  <tr key={program.id}>
                    <td>{program.id}</td>
                    <td>{program.name_ru}</td>
                    <td>{program.faculty.name_ru}</td>
                    <td>
                      <Link to={`/programs/update/${program.id}`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteForm(program)} className="admin_icons" src={trash} alt=""/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table></div>
          </div>
    </Fragment>
    )
}

export default Program
