import React, { Fragment, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useOutsideClick from '@hooks/useOutsideClick'

import { url } from '@utils/api'

// State
import { useDispatch } from 'react-redux'
import { logout } from '@features/auth/authSlice'

const DropDown = ({ user }) => {
  const [dropDown, setDropDown] = useState(false);
  const history = useHistory();
  const ref = useRef()
  const dispatch = useDispatch()

  useOutsideClick(ref, () => {
    setDropDown(false)
  })

  const handleLogout = () => {
    dispatch(logout())
    history.push('/');
  }

  return (
    <Fragment>     
          <div className="header_user" id="header_user" ref={ref} onClick={() => setDropDown(!dropDown)}>
            <div className="header_user_name"><span className="header_user_last_name">{user?.last_name_ru}</span> <span className="header_user_first_name">{user?.first_name_ru}</span></div>
            <img
              src={user?.avatar ? `${url}/uploads/${user?.id}/avatar/${user?.avatar}` : `${process.env.PUBLIC_URL}/img/icons/no-avatar.png`}
              alt=""
              className="header_user_img"
            />
            <div className="header_user_dropdown_arrow"></div>
            {dropDown && 
            <div id="header_user_dropdown" className="header_user_dropdown shadow b-rad-5">
              <div className="header_user_dropdown_info">
                <div className="header_user_dropdown_avatar">
                  <img src={user?.avatar ? `${url}/uploads/${user?.id}/avatar/${user?.avatar}` : `${process.env.PUBLIC_URL}/img/icons/no-avatar.png`} alt="" />
                </div>

                <div className="header_user_dropdown_name">
                  <p>{`${user?.last_name_ru} ${user?.first_name_ru}`}</p>
                  <span>В сети</span> 
                </div>
              </div>

              <div className="line_grey"></div>

              <div className="header_user_dropdown_exit">
                <Link to="/settings"><img src={`${process.env.PUBLIC_URL}/img/icons/settings.png`} alt="" />
                Настройки</Link>
              </div>

              {/* <div className="header_user_dropdown_settings">
                <p>Помощь</p>
                <p>Настройки</p>
              </div> 

              <div className="line_grey"></div> */}

              <div className="header_user_dropdown_exit pl-0" onClick={handleLogout}>
                <img src={`${process.env.PUBLIC_URL}/img/icons/sign-out.png`} alt="" />
                Выйти
              </div>
            </div>
            }
          </div>
        </Fragment>
  )
}

export default DropDown
