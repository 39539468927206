import React, { useState, useEffect, Fragment } from 'react'

import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'

// Icons
import trash from "@icons/trash.png"

import DeleteTeacher from "./DeleteTeacher"

import Spinner from '@components/layout/Spinner'
import Pagination from '@components/layout/Pagination'

import { getUserFullName } from '@utils/helpers'

// State
import { useSelector, useDispatch } from "react-redux"
import { getChairTeachers, getPaginatedChairTeachers, selectTeachers, selectTeacherLoading, filterChairTeachers, selectFilterTeacherLoading } from "@features/teacher/teacherSlice"
import { getTeacherUser, selectTeacherUser, selectUserLoading } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const Teacher = () => {
  const [loading, setLoading] = useState("pending")
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState(null)
  const [filtered, setFiltered] = useState(false)
  const user = useSelector(selectUser)
  const teacherUser = useSelector(selectTeacherUser)
  const userLoading = useSelector(selectUserLoading)
  const [paginationMeta, setPaginationMeta] = useState({
    total: 0,
    current_page: 0,
    per_page: 0,
    links: []
  })

  const { register, handleSubmit, reset } = useForm()

  const dispatch = useDispatch()
  const teachers = useSelector(selectTeachers)
  const teacherLoading = useSelector(selectTeacherLoading)
  const filterLoading = useSelector(selectFilterTeacherLoading)

  useEffect(() => {
    setLoading("pending")
    dispatch(getTeacherUser(user.id)).then(res => {
      if (!res.payload?.data) {
        setLoading("fullfilled")
        return
      }

      dispatch(getChairTeachers(res.payload.data.chair.id)).then(res => {
        setLoading("fullfilled")
        setPaginationMeta(res.payload.meta)
      })
    })
  }, [user])

  const handleDeleteForm = (teacher) => {
    setDeleteModal(!deleteModal)
    setSelectedTeacher(teacher)
  }

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      chair_id: teacherUser.chair.id
    }

    dispatch(filterChairTeachers(validData)).then(res => {
      if (!res.payload) {
        return
      }

      setPaginationMeta(res.payload.meta)
    })
    setFiltered(true)
  }

  const handleResetFilter = () => {
    setFiltered(false)
    dispatch(getChairTeachers(teacherUser.chair.id)).then(res => {
      if (!res.payload) {
        return
      }

      setPaginationMeta(res.payload.meta)
    })
  }

  const getData = (page) => {
    if (!page.url) {
      return
    }

    dispatch(getPaginatedChairTeachers(page.url)).then(res => {
      if (!res.payload) {
        return
      }
      
      setPaginationMeta(res.payload.meta)
    })
  }

  return (
    loading === "pending" || teacherLoading === "pending" ? (
      <Spinner />
    ) : (
    <Fragment>
      {deleteModal && (
        <DeleteTeacher
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          teacher={selectedTeacher}
        />
      )}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Преподаватели</div>
          <Link to="/teachers/create"><button className="button table_create">
            + Добавить
          </button></Link>
        </div>

        <form className="filter_block mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="filter_block_search">
            <div className="filter_block_group">
              <label htmlFor="last_name">
                Фамилия
              </label>
              <input id="last_name" name="last_name" ref={register} />
            </div>

            <div className="filter_block_group">
              <label htmlFor="first_name">
                Имя
              </label>
              <input id="first_name" name="first_name" ref={register} />
            </div>
          </div>

          <div className="filter_block_bottom mt-10">
            <button className="button">Фильтровать</button>
            { filtered && <div className="filter_button reset_filter_button" onClick={() => handleResetFilter()}>Сбросить фильтр</div> }
          </div>
        </form>

        { filterLoading === "pending" ? (<Spinner />) :
          (<Fragment><div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>E-mail</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {teachers?.map((teacher, index, teachers) => (
              <tr key={teacher.id}>
                <td>{paginationMeta.total-- - ((paginationMeta.current_page - 1) * paginationMeta.per_page)}</td>
                <td>{getUserFullName(teacher.user)}</td>
                <td>{teacher.user.email}</td>
                <td>
                  <img
                    onClick={() => handleDeleteForm(teacher)}
                    className="admin_icons"
                    src={trash}
                    alt=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table></div>
        <Pagination links={paginationMeta.links} getData={getData} /></Fragment>)
        }
      </div>
    </Fragment>
  ))
}

export default Teacher
