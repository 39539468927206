
import { Route, Redirect } from 'react-router-dom'

// State
import { useSelector } from 'react-redux'
import { selectUser } from '@features/auth/authSlice'

const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector(selectUser)

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute
