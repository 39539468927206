import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deleteAcademicYear } from '@features/academic-year/academicYearSlice'

const DeleteAcademicYear = ({ deleteModal, setDeleteModal, academicYear }) => {
  const dispatch = useDispatch()

  const handleDeleteAcademicYear = (academicYear) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteAcademicYear(academicYear?.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Учебный Год <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить учебный год {academicYear?.name}</div>
            <button className="modal_delete_button button" onClick={() => handleDeleteAcademicYear(academicYear)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteAcademicYear
