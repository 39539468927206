import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getUser = createAsyncThunk(
  'user/userById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getUsers = createAsyncThunk(
  'user/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedUsers = createAsyncThunk(
  'user/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeChairLeaders = createAsyncThunk(
  'user/practiceChairLeaders',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/role/practice-chair-leaders`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getStudentUsers = createAsyncThunk(
  'user/getStudentUsers',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/role/student-users`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getTeacherUsers = createAsyncThunk(
  'user/getTeacherUsers',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/role/teacher-users`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createUser = createAsyncThunk(
  'user/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    const history = data.history

    try {
      const response = await axios.post(`${url}/api/users`, data, config)

      history.push("/users")

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))

      throw error
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/users/${data.id}`, data, config)
      data.history.push('/users')

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().user
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/users/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const filterUsers = createAsyncThunk(
  'user/filter',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/filter?email=${data.email}&lastName=${data.last_name}&role=${data.role}`, config)
      /* const response = await axios.get(`${url}/api/users/filter?role=${data.role}`); */
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getStudentUser = createAsyncThunk(
  'user/student',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/student`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getTeacherUser = createAsyncThunk(
  'user/teacher',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/teacher`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getBaseUser = createAsyncThunk(
  'user/baseUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/baseUser`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairHeadUser = createAsyncThunk(
  'user/chairHeadUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/chairHead`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getDeanUser = createAsyncThunk(
  'user/deanUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/dean`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getMethodCenterUser = createAsyncThunk(
  'user/methodCenterUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().user
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/users/${data}/methodCenterUser`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: 'idle',
    filterLoading: 'idle',
    user: null,
    users: [],
    baseUser: undefined,
    deanUser: undefined,
    chairHeadUser: undefined,
    teacherUser: undefined,
    studentUser: undefined,
    methodCenterUser: undefined
  },
  extraReducers: {
    // One
    [getUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getUsers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getUsers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getUsers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated list
    [getPaginatedUsers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedUsers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedUsers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Practice chair leaders
    [getPracticeChairLeaders.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeChairLeaders.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeChairLeaders.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Student users
    [getStudentUsers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudentUsers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudentUsers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Student users
    [getTeacherUsers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTeacherUsers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getTeacherUsers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload.data
        state.users.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload.data
        state.users = state.users.map(user => (
          user.id === action.payload.data.id ? user = action.payload.data : user
        ))
        state.currentRequestId = undefined
      }
    },
    [updateUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = null
        state.users = state.users.filter(user => user.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Filter
    [filterUsers.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [filterUsers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.users = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [filterUsers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.users = undefined
        state.currentRequestId = undefined
      }
    },

    // Base user
    [getBaseUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getBaseUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.baseUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getBaseUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Dean user
    [getDeanUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getDeanUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.deanUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getDeanUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Chair head
    [getChairHeadUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairHeadUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.chairHeadUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairHeadUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Student
    [getStudentUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudentUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.studentUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudentUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Teacher
    [getTeacherUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTeacherUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.teacherUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getTeacherUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Method Center user
    [getMethodCenterUser.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getMethodCenterUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.methodCenterUser = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getMethodCenterUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const { setProgressAvatar } = userSlice.actions

export const selectUserLoading = state => state.user.loading
export const selectUser = state => state.user.user
export const selectUsers = state => state.user.users
export const selectFilterLoading = state => state.user.filterLoading
export const selectDeanUser = state => state.user.deanUser
export const selectChairHeadUser = state => state.user.chairHeadUser
export const selectTeacherUser = state => state.user.teacherUser
export const selectStudentUser = state => state.user.studentUser
export const selectBaseUser = state => state.user.baseUser
export const selectMethodCenterUser = state => state.user.methodCenterUser

export default userSlice.reducer
