import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_inner">
          <img src={`${process.env.PUBLIC_URL}/img/icons/logo-footer.png`} alt="" className="footer_logo" />
          <div className="footer_list">
            <div className="line_white"></div>
            <div className="footer_item"><a href="https://skma.edu.kz" target="_blank" className="footer_link">О нас</a></div>
            <div className="footer_item"><a href="/" className="footer_link">Условия пользования</a></div>
            <div className="footer_item"><a href="/" className="footer_link">Помощь</a></div>
            <div className="footer_item"><a href="/" className="footer_link">Контакты</a></div>
          </div>

          <div className="footer_subscribe">
            <div className="line_white"></div>
            <p className="footer_subscribe_title">Подпишись на нас</p>
            <ul className="footer_subscribe_list">
              <li className="footer_subscribe_item"><a href="/" className="footer_subscribe_link"><img src={`${process.env.PUBLIC_URL}/img/icons/social-instagram.png`} alt="" className="footer_subscribe_img" /></a></li>
              <li className="footer_subscribe_item"><a href="/" className="footer_subscribe_link"><img src={`${process.env.PUBLIC_URL}/img/icons/social-facebook.png`} alt="" className="footer_subscribe_img" /></a></li>
              <li className="footer_subscribe_item"><a href="/" className="footer_subscribe_link"><img src={`${process.env.PUBLIC_URL}/img/icons/social-youtube.png`} alt="" className="footer_subscribe_img" /></a></li>
            </ul>
          </div>

          <div className="footer_copyright">
            <div className="line_white"></div>
            Все права защищены {currentYear}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
