import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAuthLoading
} from '@features/auth/authSlice'

// Layout Components
import Spinner from './Spinner'
import Header from '@components/layout/header/Header'
import Sidebar from '@components/layout/sidebar/Sidebar'
import Footer from '@components/layout/Footer'

const Layout = ({ children }) => {
  const loading = useSelector(selectAuthLoading)

  return (
    <Fragment>
      <Header />
        <div className="container">
          <main className="main">
            <Sidebar />
            <div className="center">
              { loading === 'pending' ? <Spinner /> : children}
            </div>
            {/* <Utils /> */}
          </main>
        </div>
      <Footer />
    </Fragment>
  )
}

export default Layout
