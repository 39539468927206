import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createUser, selectUserLoading } from '@features/user/userSlice'
import { getChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'

const CreateMethodCenterUser = () => {
  const dispatch = useDispatch()
  const userLoading = useSelector(selectUserLoading)
  const chairs = useSelector(selectChairs)
  const chairLoading = useSelector(selectChairLoading)

  useEffect(() => {
    dispatch(getChairs())
  }, [dispatch])

  const { register, handleSubmit, reset, errors } = useForm()
  const history = useHistory()

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      roles: ["practiceChairLeader"],
      history,
      route: "/practice-chair-leaders"
    }

    dispatch(createUser(validData))
  }

  return userLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Руководитель практики</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            E-mail
            <Error name={errors.email} />
          </label>
          <input type="email" id="email" name="email" ref={register(required)} />

          <label htmlFor="chair_id">Выберите Кафедру <Error name={errors.chair_id} /></label>
          <select name="chair_id" id="chair_id" ref={register(required)}>
          { chairs?.map(chair => (
            <option key={chair.id} value={chair.id}>{chair.name_ru}</option>
          ))}
          </select> 

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/practice-chair-leaders">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateMethodCenterUser
