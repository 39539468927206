import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getPractice = createAsyncThunk(
  'practice/practiceById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeWithoutAuth = createAsyncThunk(
  'practice/getPracticeWithoutAuth',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    try {
      const response = await axios.get(`${url}/api/practices/${data.practiceId}/withoutAuth`, config)
      return response.data
    } catch (error) {
      data.history.push("/404")
      throw error
    }
  }
)

export const getPracticeWithUser = createAsyncThunk(
  'practice/practiceWithUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data}/withUser`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPractices = createAsyncThunk(
  'practice/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getHeadOfChairPractices = createAsyncThunk(
  'practice/headOfChairList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/head-of-chair-practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairLeaderPractices = createAsyncThunk(
  'practice/chairLeaderList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/chair-leader-practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getBaseLeaderPractices = createAsyncThunk(
  'practice/baseLeaderList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/base-leader-practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getComissionPractices = createAsyncThunk(
  'practice/comissionLeaderList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/comission-practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPractice = createAsyncThunk(
  'practice/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practices`, data, config)
      data.history.push('/chair-practices')

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePractice = createAsyncThunk(
  'practice/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practices/${data.uuid}`, data, config)
      data.history.push('/chair-practices')

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePractice = createAsyncThunk(
  'practice/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practices/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getStudentPractices = createAsyncThunk(
  'practice/studentPractices',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students/${data}/practices`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStats = createAsyncThunk(
  'practice/stats',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data}/students/stats`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStat = createAsyncThunk(
  'practice/stat',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data.practiceId}/students/${data.userId}/stat`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const togglePractice = createAsyncThunk(
  'practice/toggle',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practice
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practices/${data.practiceId}/toggle`, data, config)

      dispatch(createAlert({
        message: "Статус практики успешно изменена",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

/* export const createPdf = createAsyncThunk(
  'practice/createPdf',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, pdfLoading } = getState().practice
    if (pdfLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practices/create-pdf`, data, config)

      dispatch(createAlert({
        message: "Отчет сформирован",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
) */

/* export const getPdfLink = createAsyncThunk(
  'practice/getPdfLink',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, pdfLoading } = getState().practice
    if (pdfLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data.practiceId}/students/${data.studentId}/pdf-link`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
) */

export const practiceSlice = createSlice({
  name: 'practice',
  initialState: {
    loading: 'idle',
    practice: null,
    practices: [],
    practiceStats: null,
    practiceStat: null
  },
  reducers: {},
  extraReducers: {
    // One
    [getPractice.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPractice.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPractice.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // One without auth
    [getPracticeWithoutAuth.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeWithoutAuth.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeWithoutAuth.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // With user
    [getPracticeWithUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeWithUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeWithUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Chair Head List
    [getHeadOfChairPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getHeadOfChairPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getHeadOfChairPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Chair Leader List
    [getChairLeaderPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairLeaderPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairLeaderPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Base Leader List
    [getBaseLeaderPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getBaseLeaderPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getBaseLeaderPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Comission List
    [getComissionPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getComissionPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getComissionPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createPractice.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPractice.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = action.payload.data
        state.practices.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPractice.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePractice.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePractice.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = action.payload.data
        state.practices = state.practices.map(practice =>
          practice.uuid === action.payload.data.uuid ? practice = action.payload.data : practice
        )
        state.currentRequestId = undefined
      }
    },
    [updatePractice.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

        // Close Practice
        [togglePractice.pending]: (state, action) => {
          if (state.loading === 'idle') {
            state.loading = 'pending'
            state.currentRequestId = action.meta.requestId
          }
        },
        [togglePractice.fulfilled]: (state, action) => {
          const { requestId } = action.meta
          if (state.loading === 'pending' && state.currentRequestId === requestId) {
            state.loading = 'idle'
            state.practice = action.payload.data
            state.practices = state.practices.map(practice =>
              practice.uuid === action.payload.data.uuid ? practice = action.payload.data : practice
            )
            state.currentRequestId = undefined
          }
        },
        [togglePractice.rejected]: (state, action) => {
          const { requestId } = action.meta
          if (state.loading === 'pending' && state.currentRequestId === requestId) {
            state.loading = 'idle'
            state.currentRequestId = undefined
          }
        },

    // Delete
    [deletePractice.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePractice.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practice = null
        state.practices = state.practices.filter(practice => practice.uuid !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePractice.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Student Practice List
    [getStudentPractices.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudentPractices.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practices = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudentPractices.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

        // Practice Stats
        [getPracticeStats.pending]: (state, action) => {
          if (state.loading === 'idle') {
            state.loading = 'pending'
            state.currentRequestId = action.meta.requestId
          }
        },
        [getPracticeStats.fulfilled]: (state, action) => {
          const { requestId } = action.meta
          if (state.loading === 'pending' && state.currentRequestId === requestId) {
            state.loading = 'idle'
            state.practiceStats = action.payload.data
            state.currentRequestId = undefined
          }
        },
        [getPracticeStats.rejected]: (state, action) => {
          const { requestId } = action.meta
          if (state.loading === 'pending' && state.currentRequestId === requestId) {
            state.loading = 'idle'
            state.currentRequestId = undefined
          }
        },

                // Practice Stat
                [getPracticeStat.pending]: (state, action) => {
                  if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                  }
                },
                [getPracticeStat.fulfilled]: (state, action) => {
                  const { requestId } = action.meta
                  if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.practiceStat = action.payload.data
                    state.currentRequestId = undefined
                  }
                },
                [getPracticeStat.rejected]: (state, action) => {
                  const { requestId } = action.meta
                  if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                  }
                },

    // create pdf
/*     [createPdf.pending]: (state, action) => {
      if (state.pdfLoading === 'idle') {
        state.pdfLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPdf.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.pdfLoading === 'pending' && state.currentRequestId === requestId) {
        state.pdfLoading = 'idle'
        state.pdfLink = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [createPdf.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.pdfLoading === 'pending' && state.currentRequestId === requestId) {
        state.pdfLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    [getPdfLink.pending]: (state, action) => {
      if (state.pdfLoading === 'idle') {
        state.pdfLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPdfLink.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.pdfLoading === 'pending' && state.currentRequestId === requestId) {
        state.pdfLoading = 'idle'
        state.pdfLink = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPdfLink.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.pdfLoading === 'pending' && state.currentRequestId === requestId) {
        state.pdfLoading = 'idle'
        state.currentRequestId = undefined
      }
    }, */
  }
})

export const selectPracticeLoading = state => state.practice.loading
export const selectPractice = state => state.practice.practice
export const selectPractices = state => state.practice.practices
export const selectPracticeBases = state => state.practice.practice?.baseUsers?.map(({ baseUser }) => {
  return {
    'id': baseUser.id,
    'name_ru': `${baseUser.base.name_ru} (${baseUser.user.last_name_ru} ${baseUser.user.first_name_ru})`
  }
})
export const selectPracticeStats = state => state.practice.practiceStats
export const selectPracticeStat = state => state.practice.practiceStat
/* export const selectPdfLoading = state => state.practice.pdfLoading
export const selectPdfLink = state => state.practice.pdfLink */

export default practiceSlice.reducer
