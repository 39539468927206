import { cloneElement } from 'react'
import { Route } from 'react-router-dom'

import NotFound from '@components/layout/NotFound'
import Spinner from '@components/layout/Spinner'

// State
import { useSelector } from 'react-redux'
import { selectUser, selectAuthLoading } from "@features/auth/authSlice";

import { is } from '@utils/helpers'

const AdminRoute = ({ children, ...rest }) => {
  const user = useSelector(selectUser)
  const loading = useSelector(selectAuthLoading)

  return (
    loading === "pending" ? (<Spinner />) : (<Route
      {...rest}
      render={(props) =>
        is("admin", user) ? (
          cloneElement(children, { ...props })
        ) : (
          <NotFound />
        )
      }
    />)
  );
}

export default AdminRoute