import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice schedules requests
export const getPracticeSchedule = createAsyncThunk(
  'practiceSchedule/practiceScheduleById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceSchedule
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-schedules/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeSchedules = createAsyncThunk(
  'practiceSchedule/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceSchedule
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-schedules/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeSchedule = createAsyncThunk(
  'practiceSchedule/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceSchedule
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-schedules/${data.practiceId}/students/${data.studentId}`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      data.history.push(`/student-practices/${data.practiceId}/students/${data.studentId}/plan`)

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeScheduleComment = createAsyncThunk(
  'practiceScheduleComment/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceSchedule
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-schedules/${data.uuid}/comment`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeSchedule = createAsyncThunk(
  'practice/updateSchedule',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceSchedule
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-schedules/${data.scheduleId}`, data, config)
      data.history.push(`/student-practices/${data.practiceId}/students/${data.studentId}/plan`)

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

// Update practice schedule comment
export const updatePracticeScheduleComment = createAsyncThunk(
  'practiceScheduleComment/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceSchedule
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-schedules/${data.uuid}/comment`, data, config)

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeSchedule = createAsyncThunk(
  'practiceSchedule/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceSchedule
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-schedules/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeScheduleComment = createAsyncThunk(
  'practiceScheduleComment/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceSchedule
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-schedules/${data}/comment`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceScheduleSlice = createSlice({
  name: 'practiceSchedule',
  initialState: {
    loading: 'idle',
    commentLoading: 'idle',
    practiceSchedule: null,
    practiceSchedules: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Student reducers
    // One
    [getPracticeSchedule.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeSchedule.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceSchedule = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeSchedule.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeSchedules.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeSchedules.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceSchedules = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeSchedules.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeSchedule.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeSchedule.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceSchedule = action.payload.data
        state.practiceSchedules.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeSchedule.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Create practice schedule comment
    [createPracticeScheduleComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeScheduleComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceSchedule = action.payload.data
        state.practiceSchedules = state.practiceSchedules.map(practiceSchedule =>
          practiceSchedule.uuid === action.payload.data.uuid ? practiceSchedule = action.payload.data : practiceSchedule
        )
        state.currentRequestId = undefined
      }
    },
    [createPracticeScheduleComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeSchedule.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeSchedule.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceSchedule = action.payload.data
        state.practiceSchedules = state.practiceSchedules.map(practiceSchedule =>
          practiceSchedule.uuid === action.payload.data.uuid ? practiceSchedule = action.payload.data : practiceSchedule
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeSchedule.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Create practice schedule comment
    [updatePracticeScheduleComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeScheduleComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceSchedule = action.payload.data
        state.practiceSchedules = state.practiceSchedules.map(practiceSchedule =>
          practiceSchedule.uuid === action.payload.data.uuid ? practiceSchedule = action.payload.data : practiceSchedule
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeScheduleComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeSchedule.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeSchedule.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceSchedule = null
        state.practiceSchedules = state.practiceSchedules.filter(practiceSchedule => practiceSchedule.uuid !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeSchedule.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete practice schedule comment
    [deletePracticeScheduleComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeScheduleComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceSchedule = null
        state.practiceSchedules.map(practiceSchedule => practiceSchedule.practiceScheduleComments = practiceSchedule.practiceScheduleComments.filter(comment => comment.uuid !== action.payload))
        state.currentRequestId = undefined
      }
    },
    [deletePracticeScheduleComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticeScheduleLoading = state => state.practiceSchedule.loading
export const selectPracticeSchedule = state => state.practiceSchedule.practiceSchedule
export const selectPracticeSchedules = state => state.practiceSchedule.practiceSchedules
export const selectPracticeScheduleCommentLoading = state => state.practiceSchedule.commentLoading

export default practiceScheduleSlice.reducer
