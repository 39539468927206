import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import JoditEditor from "jodit-react"
import { createAlert } from '@features/alert/alertSlice'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeChairReview, updatePracticeChairReview, selectPracticeChairReview, selectPracticeChairReviewLoading } from '@features/practice/practiceChairReviewSlice'

const PracticeReview = ({ match }) => {
  const [content, setContent] = useState('')
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const editor = useRef(null)
  const dispatch = useDispatch()
  const loading = useSelector(selectPracticeChairReviewLoading)
  const practiceChairReview = useSelector(selectPracticeChairReview)
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    const data = {
      practiceId,
      studentId
    }
    
    dispatch(getPracticeChairReview(data)).then(res => setContent(res.payload.data.teacher_review))
  }, [dispatch])

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: true
    },
  }

  const onBlurEditor = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
    
  const onEditorChange = (editorNewValue) => {
    editor.current.value = editorNewValue
  }

  const onSubmit = data => {
    if (!editor.current.value) {
      dispatch(createAlert({
        message: "Заполните все поля",
        type: 'fail'
      }))
      
      return
    }

    setContent(editor.current.value)
    
    const updateData = {
      ...data,
      uuid: practiceChairReview?.uuid,
      teacher_review: editor.current.value,
      history,
      practiceId
    }
    
    dispatch(updatePracticeChairReview(updateData))
  }

  return (
    loading === "pending" ? (<Spinner />) : (
      <Fragment>
        <div className="trainee">
          <div className="trainee_title">
            Отзыв
            <Link to={`/chair-practices/${practiceId}/students`} className="table_create link_w447">Список практикантов</Link>
          </div>

          <form action="" className="flex-column mt-10" onSubmit={handleSubmit(onSubmit)}>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
/*               onBlur={newContent => setContent(newContent.target.innerHTML)}
              onChange={newContent => {}} */
              onBlur={onBlurEditor}
              onChange={onEditorChange}
            />
            <label htmlFor="teacher_grade" className="mt-15">Оценка на кафедре <Error name={errors.teacher_grade} /></label>
            <input type="number" name="teacher_grade" defaultValue={practiceChairReview?.teacher_grade} id="teacher_grade" ref={register(required)} />
            <label htmlFor="total_grade" className="mt-15">Общая оценка <Error name={errors.total_grade} /></label>
            <input type="number" name="total_grade" defaultValue={practiceChairReview?.total_grade} id="total_grade" ref={register(required)} />
            <button className="button" type="submit">Сохранить</button>
          </form>
        </div>
      </Fragment>
    )
  )
}

export default PracticeReview
