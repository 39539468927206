import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "./react-style.css"
import "./style.css"

// Layout
import Layout from './components/layout/Layout'
import NotFound from './components/layout/NotFound'
import Dashboard from './components/layout/dashboard/Dashboard'
import Info from './components/layout/settings/Info'
import Settings from './components/layout/settings/Settings'

import Alerts from './features/alert/Alerts'

// Route
import AdminRoute from './components/routing/AdminRoute'
import UserRoute from './components/routing/UserRoute'
import PrivateRoute from './components/routing/PrivateRoute'
import MethodCenterRoute from './components/routing/MethodCenterRoute'
import PracticeChairLeaderRoute from './components/routing/PracticeChairLeaderRoute'
import ChairHeadRoute from './components/routing/ChairHeadRoute'
import ComissionRoute from './components/routing/ComissionRoute'
import BaseRoute from './components/routing/BaseRoute'
import StudentRoute from './components/routing/StudentRoute'

import Help from './components/layout/help/Help'

// Auth
import Login from "./features/auth/Login"
import ForgotPassword from './features/auth/ForgotPassword'

import StudentQr from './features/qr/StudentQr'
import PracticeComissionQr from './features/qr/PracticeComissionQr'
import ChairHeadQr from './features/qr/ChairHeadQr'
import PracticeChairHeadQr from './features/qr/PracticeChairHeadQr'
import PracticeBaseHeadQr from './features/qr/PracticeBaseHeadQr'

import AcademicYear from './features/academic-year/AcademicYear'
import CreateAcademicYear from './features/academic-year/CreateAcademicYear'
import UpdateAcademicYear from './features/academic-year/UpdateAcademicYear'

import Faculty from './features/faculty/Faculty'
import CreateFaculty from './features/faculty/CreateFaculty'
import UpdateFaculty from './features/faculty/UpdateFaculty'

import Program from './features/program/Program'
import CreateProgram from './features/program/CreateProgram'
import UpdateProgram from './features/program/UpdateProgram'

import Chair from './features/chair/Chair'
import CreateChair from './features/chair/CreateChair'
import UpdateChair from './features/chair/UpdateChair'

import User from './features/user/User'
import CreateUser from './features/user/CreateUser'
import UpdateUser from './features/user/UpdateUser'
import MethodCenterUser from './features/user/MethodCenterUser'
import CreateMethodCenterUser from './features/user/CreateMethodCenterUser'

import Base from './features/base/Base'
import CreateBase from './features/base/CreateBase'
import UpdateBase from './features/base/UpdateBase'

import Group from './features/group/Group'
import CreateGroup from './features/group/CreateGroup'
import UpdateGroup from './features/group/UpdateGroup'

// User
import PracticeStudent from './features/practice/components/PracticeStudent'
import PracticePlan from './features/practice/components/PracticePlan'
import PracticeReview from './features/practice/components/PracticeReview'
import PracticeComission from './features/practice/components/PracticeComission'
import PracticeStats from './features/practice/components/PracticeStats'

import CreateChairPractice from './features/practice/practice-chair-leader/CreatePractice'
import UpdateChairPractice from './features/practice/practice-chair-leader/UpdatePractice'
import CreateChairPracticeStudent from './features/practice/practice-chair-leader/CreatePracticeStudent'

import ChairPractice from '@features/practice/practice-chair-leader/Practice'
import BasePractice from '@features/practice/practice-base-leader/Practice'
import ChairHeadPractice from '@features/practice/head-of-chair/Practice'
import ComissionPractice from '@features/practice/comission/Practice'
import StudentPractice from '@features/practice/student/Practice'
import MethodCenterPractice from '@features/practice/method-center/Practice'

import PracticeChairStudentPlan from '@features/practice/practice-chair-leader/PracticePlan'
import PracticeBaseStudentPlan from '@features/practice/practice-base-leader/PracticePlan'
import PracticeHeadOfChairStudentPlan from '@features/practice/head-of-chair/PracticePlan'
import PracticeComissionStudentPlan from '@features/practice/comission/PracticePlan'
import PracticeStudentPlan from '@features/practice/student/PracticePlan'
import MethodCenterUserStudentPlan from '@features/practice/method-center/PracticePlan'

import PracticeChairLeaderStudent from '@features/practice/practice-chair-leader/PracticeStudent'
import PracticeBaseLeaderStudent from '@features/practice/practice-base-leader/PracticeStudent'
import HeadOfChairStudent from '@features/practice/head-of-chair/PracticeStudent'
import ComissionStudent from '@features/practice/comission/PracticeStudent'
import MethodCenterUserStudent from '@features/practice/method-center/PracticeStudent'

import PracticeChairReview from '@features/practice/practice-chair-leader/PracticeReview'
import PracticeBaseReview from '@features/practice/practice-base-leader/PracticeReview'
import PracticeStudentReview from '@features/practice/student/PracticeReview'

import CreatePracticeContent from './features/practice/student/CreatePracticeContent'
import CreatePracticeSchedule from './features/practice/student/CreatePracticeSchedule'
import UpdatePracticeContent from './features/practice/student/UpdatePracticeContent'
import UpdatePracticeSchedule from './features/practice/student/UpdatePracticeSchedule'

import CreatePracticeComission from './features/practice/head-of-chair/CreatePracticeComission'

import HeadOfChairComission from '@features/practice/head-of-chair/PracticeComission'
import MethodCenterUserComission from '@features/practice/method-center/PracticeComission'

import MethodCenterUserStudentStats from '@features/practice/method-center/PracticeStats'
import PracticeChairStudentStats from '@features/practice/practice-chair-leader/PracticeStats'
import PracticeHeadOfChairStats from '@features/practice/head-of-chair/PracticeStats'
import PracticeStudentStats from '@features/practice/student/StudentStats'

import Student from './features/student/Student'
import CreateStudent from './features/student/CreateStudent'

import Teacher from './features/teacher/Teacher'
import CreateTeacher from './features/teacher/CreateTeacher'

import MethodCenterUserStatistics from '@features/statistics/Statistics'

// State
import store from './store'
import { Provider } from 'react-redux'
import { loadUser } from "@features/auth/authSlice"

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Alerts />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/s/:practiceStudentRandom" component={StudentQr} />
            <Route exact path="/pc/:practiceStudentRandom" component={PracticeComissionQr} />
            <Route exact path="/ch/:practiceStudentRandom" component={ChairHeadQr} />
            <Route exact path="/cph/:practiceStudentRandom" component={PracticeChairHeadQr} />
            <Route exact path="/bph/:practiceStudentRandom" component={PracticeBaseHeadQr} />

            <PrivateRoute exact path="/info"><Info /></PrivateRoute>
            <PrivateRoute exact path="/dashboard"><Dashboard /></PrivateRoute>
            <PrivateRoute exact path="/settings"><Settings /></PrivateRoute>
            <UserRoute exact path="/help"><Help /></UserRoute>

            <AdminRoute exact path="/academic-years"><AcademicYear /></AdminRoute>
            <AdminRoute exact path="/academic-years/create"><CreateAcademicYear /></AdminRoute>
            <AdminRoute exact path="/academic-years/update/:academicYearId"><UpdateAcademicYear /></AdminRoute>

            <AdminRoute exact path="/faculties"><Faculty /></AdminRoute>
            <AdminRoute exact path="/faculties/create"><CreateFaculty /></AdminRoute>
            <AdminRoute exact path="/faculties/update/:facultyId"><UpdateFaculty /></AdminRoute>

            <AdminRoute exact path="/programs"><Program /></AdminRoute>
            <AdminRoute exact path="/programs/create"><CreateProgram /></AdminRoute>
            <AdminRoute exact path="/programs/update/:programId"><UpdateProgram /></AdminRoute>

            <AdminRoute exact path="/chairs"><Chair /></AdminRoute>
            <AdminRoute exact path="/chairs/create"><CreateChair /></AdminRoute>
            <AdminRoute exact path="/chairs/update/:chairId"><UpdateChair /></AdminRoute>

            <AdminRoute exact path="/users"><User /></AdminRoute>
            <AdminRoute exact path="/users/create"><CreateUser /></AdminRoute>
            <AdminRoute exact path="/users/update/:userId"><UpdateUser /></AdminRoute>

            <AdminRoute exact path="/bases"><Base /></AdminRoute>
            <AdminRoute exact path="/bases/create"><CreateBase /></AdminRoute>
            <AdminRoute exact path="/bases/update/:baseId"><UpdateBase /></AdminRoute>

            <AdminRoute exact path="/groups"><Group /></AdminRoute>
            <AdminRoute exact path="/groups/create"><CreateGroup /></AdminRoute>
            <AdminRoute exact path="/groups/update/:groupId"><UpdateGroup /></AdminRoute>

            <StudentRoute exact path="/student-practices"><StudentPractice /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/plan"><PracticeStudentPlan /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/plan/schedule/create"><CreatePracticeSchedule /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/plan/content/create"><CreatePracticeContent /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/plan/schedule/:scheduleId/update"><UpdatePracticeSchedule /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/plan/content/:contentId/update"><UpdatePracticeContent /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/:studentId/review"><PracticeStudentReview /></StudentRoute>
            <StudentRoute exact path="/student-practices/:practiceId/students/stats"><PracticeStudentStats /></StudentRoute>

            <MethodCenterRoute exact path="/practice-chair-leaders"><MethodCenterUser /></MethodCenterRoute>
            <MethodCenterRoute exact path="/practice-chair-leaders/create"><CreateMethodCenterUser /></MethodCenterRoute>
            <MethodCenterRoute exact path="/method-center-practices"><MethodCenterPractice /></MethodCenterRoute>
            <MethodCenterRoute exact path="/method-center-practices/:practiceId/students"><MethodCenterUserStudent /></MethodCenterRoute>
            <MethodCenterRoute exact path="/method-center-practices/:practiceId/students/:studentId/plan"><MethodCenterUserStudentPlan /></MethodCenterRoute>
            <MethodCenterRoute exact path="/method-center-practices/:practiceId/students/stats"><MethodCenterUserStudentStats /></MethodCenterRoute>
            <MethodCenterRoute exact path="/method-center-practices/:practiceId/comissions"><MethodCenterUserComission /></MethodCenterRoute>
            <MethodCenterRoute exact path="/statistics"><MethodCenterUserStatistics /></MethodCenterRoute>
            
            <PracticeChairLeaderRoute exact path="/students"><Student /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/students/create"><CreateStudent /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/teachers"><Teacher /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/teachers/create"><CreateTeacher /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices"><ChairPractice /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/create"><CreateChairPractice /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/update/:practiceId"><UpdateChairPractice /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/:practiceId/students"><PracticeChairLeaderStudent /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/:practiceId/students/add"><CreateChairPracticeStudent /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/:practiceId/students/:studentId/plan"><PracticeChairStudentPlan /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute path="/chair-practices/:practiceId/students/:studentId/review"><PracticeChairReview /></PracticeChairLeaderRoute>
            <PracticeChairLeaderRoute exact path="/chair-practices/:practiceId/students/stats"><PracticeChairStudentStats /></PracticeChairLeaderRoute>

            <ComissionRoute exact path="/comission-practices"><ComissionPractice /></ComissionRoute>
            <ComissionRoute exact path="/comission-practices/:practiceId/students"><ComissionStudent /></ComissionRoute>
            <ComissionRoute exact path="/comission-practices/:practiceId/students/:studentId/plan"><PracticeComissionStudentPlan /></ComissionRoute>
            
            <ChairHeadRoute exact path="/chair-head-practices"><ChairHeadPractice /></ChairHeadRoute>
            <ChairHeadRoute exact path="/chair-head-practices/:practiceId/students"><HeadOfChairStudent /></ChairHeadRoute>
            <ChairHeadRoute exact path="/chair-head-practices/:practiceId/students/:studentId/plan"><PracticeHeadOfChairStudentPlan /></ChairHeadRoute>
            <ChairHeadRoute exact path="/chair-head-practices/:practiceId/students/stats"><PracticeHeadOfChairStats /></ChairHeadRoute>
            <ChairHeadRoute exact path="/chair-head-practices/:practiceId/comissions"><HeadOfChairComission /></ChairHeadRoute>
            <ChairHeadRoute exact path="/chair-head-practices/:practiceId/comissions/add"><CreatePracticeComission /></ChairHeadRoute>

            <BaseRoute exact path="/base-practices"><BasePractice /></BaseRoute>
            <BaseRoute exact path="/base-practices/:practiceId/students"><PracticeBaseLeaderStudent /></BaseRoute>
            <BaseRoute exact path="/base-practices/:practiceId/students/:studentId/plan"><PracticeBaseStudentPlan /></BaseRoute>
            <BaseRoute exact path="/base-practices/:practiceId/students/:studentId/review"><PracticeBaseReview /></BaseRoute>

            <Route exact path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Router>
    </Provider>
  )
}

export default App
