import React, { useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import { Multiselect } from 'multiselect-react-dropdown'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createChairStudent, selectStudentLoading } from '@features/student/studentSlice'
import { getChairGroups, selectGroups, selectGroupLoading } from '@features/group/groupSlice'
import { getTeacherUser, selectTeacherUser } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const CreateStudent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const chairGroups = useSelector(selectGroups)
  const groupLoading = useSelector(selectGroupLoading)
  const studentLoading = useSelector(selectStudentLoading)
  const user = useSelector(selectUser)

  const { register, handleSubmit, setValue, reset, errors } = useForm()

  useEffect(() => {
    dispatch(getTeacherUser(user.id)).then(res => {
      dispatch(getChairGroups(res.payload.data.chair.id))
    })
  }, [user])

  useEffect(() => {
    register("group_id")
  }, [register])

  const onSelect = (selectedList) => {
    const groupId = selectedList.map(({ id }) => id)
    setValue("group_id", groupId)
  }

  const onRemove = (selectedList, selectedItem) => {
    const newList = selectedList.filter(item => item.id !== selectedItem.id)
    const groupId = newList.map(({ id }) => id)
    setValue("group_id", groupId)
  }

  const onSubmit = (data) => {
    const validData = {
      ...data,
      group_id: data.group_id[0],
      history
    }
    dispatch(createChairStudent(validData))
  }

  return (
    studentLoading === "pending" ? <Spinner />  : <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Студент</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            E-mail
            <Error name={errors.email} />
          </label>
          <input type="email" id="email" name="email" className="mb-15" ref={register(required)} />

{/*           <label htmlFor="chair_id">Выберите Группу <Error name={errors.chair_id} /></label>
          <select name="group_id" id="group_id" ref={register(required)}>
            { groupLoading === "pending" ? <option>Загружаем...</option> :
              chairGroups?.map(group => (
            <option key={group.id} value={group.id}>{group.name}</option>
          ))}
          </select> */}

          <Multiselect
            options={chairGroups}
            loading={groupLoading === "pending"}
            loadingMessage="Загружем..."
            emptyRecordMsg="Групп не найдено"
            avoidHighlightFirstOption={true}
            placeholder="Группы"
            hidePlaceholder={true}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            selectionLimit={1}
          />

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/students">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateStudent
