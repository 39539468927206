import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Icons
import edit from '@icons/edit.png'

import Spinner from '@components/layout/Spinner'

import { formatDate } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getStudentPractices, selectPracticeLoading, selectPractices } from '@features/practice/practiceSlice'
import { getStudentUser, selectStudentUser, selectFilterLoading } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const Practice = () => {
  const dispatch = useDispatch()
  const practiceLoading = useSelector(selectPracticeLoading)
  const user = useSelector(selectUser)
  const student = useSelector(selectStudentUser)
  const practices = useSelector(selectPractices)
  const studentLoading = useSelector(selectFilterLoading)

  useEffect(() => {
    dispatch(getStudentUser(user?.id)).then(res => {
      dispatch(getStudentPractices(res.payload?.data.id))
    })
  }, [dispatch])

  return (
    studentLoading === "pending" ? <Spinner /> : (<Fragment>
          <div className="trainee">
            <div className="trainee_title">Практика</div>

            {practiceLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table className="trainee_table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Период</th>
                  <th>План</th>
                  <th>Действия</th>
                </tr>
              </thead>

              <tbody>
                {
                  practices?.map((practice, index) => (
                    <tr key={practice?.id}>
                      <td>{++index}</td>
                      <td>{practice?.name}</td>
                      <td>{`${formatDate(practice?.start)} - ${formatDate(practice?.end)}`}</td>
                      <td><Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/plan`}>Дневник-отчет</Link></td>
                      <td><Link to={`/student-practices/${practice?.uuid}/students/${student?.uuid}/review`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <Link to={`/student-practices/${practice.uuid}/students/stats`}><button className="table_create button">Оценки</button></Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table></div>}
          </div>
    </Fragment>)
  )
}

export default Practice
