import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getUser, updateUser, selectUserLoading, selectUser, getDeanUser, getChairHeadUser, getStudentUser, getTeacherUser, getBaseUser, getMethodCenterUser, selectMethodCenterUser, selectDeanUser, selectChairHeadUser, selectTeacherUser, selectStudentUser, selectBaseUser } from '@features/user/userSlice'
import { getChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'
import { getGroups, selectGroups, selectGroupLoading } from '@features/group/groupSlice'
import { getBases, selectBases, selectBaseLoading } from '@features/base/baseSlice'

const UpdateUser = ({ match }) => {
  const [studentChecked, setStudentChecked] = useState(false)
  const [chairHeadChecked, setChairHeadChecked] = useState(false)
  const [chairPracticeLeaderChecked, setChairPracticeLeaderChecked] = useState(false)
  const [basePracticeLeaderChecked, setBasePracticeLeaderChecked] = useState(false)
  const [deanChecked, setDeanChecked] = useState(false)
  const [teacherChecked, setTeacherChecked] = useState(false)
  const [methodCenterUserChecked, setMethodCenterUserChecked] = useState(false)
  const [adminChecked, setAdminChecked] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchUser() {
      const user = await dispatch(getUser(match.params.userId))
      user.payload.data.roles?.map(role => {
        handleChange(role.name)
        getUserInfo(role.name)
      })
    }

    fetchUser()
  }, [match.params.userId, dispatch])
  
  const user = useSelector(selectUser)

  useEffect(() => {
    dispatch(getChairs())
    dispatch(getGroups())
    dispatch(getBases())
  }, [dispatch])

  const loading = useSelector(selectUserLoading)
  const chairs = useSelector(selectChairs)
  const chairLoading = useSelector(selectChairLoading)
  const groups = useSelector(selectGroups)
  const groupLoading = useSelector(selectGroupLoading)
  const bases = useSelector(selectBases)
  const baseLoading = useSelector(selectBaseLoading)
  
  const deanUser = useSelector(selectDeanUser)
  const chairHeadUser = useSelector(selectChairHeadUser)
  const teacherUser = useSelector(selectTeacherUser)
  const baseUser = useSelector(selectBaseUser)
  const studentUser = useSelector(selectStudentUser)
  const methodCenterUser = useSelector(selectMethodCenterUser)

  const getUserInfo = (roleName) => {
    switch (roleName) {
      case "headOfChair":
        dispatch(getChairHeadUser(match.params.userId))
        break
      case "teacher":
      case "practiceChairLeader":
        dispatch(getTeacherUser(match.params.userId))
        break
      case "practiceBaseLeader":
        dispatch(getBaseUser(match.params.userId))
        break
      case "student":
        dispatch(getStudentUser(match.params.userId))
        break
      case "dean":
        dispatch(getDeanUser(match.params.userId))
        break
      case "methodCenterUser":
        dispatch(getMethodCenterUser(match.params.userId))
        break
      default:
        break
    }
  }

  const { register, handleSubmit, reset, errors } = useForm()
  const history =  useHistory()

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      id: user.id,
      user_id: user.id,
      roles: data.roles,
      history
    }
    dispatch(updateUser(validData))
  }

  const handleChange = (roleName) => {
    switch (roleName) {
      case "headOfChair":
        setChairHeadChecked(!chairHeadChecked)
        break;
      case "teacher":
        setTeacherChecked(!teacherChecked)
        break;
      case "practiceChairLeader":
        setChairPracticeLeaderChecked(!chairPracticeLeaderChecked)
        break;
      case "practiceBaseLeader":
        setBasePracticeLeaderChecked(!basePracticeLeaderChecked)
        break;
      case "student":
        setStudentChecked(!studentChecked)
        break;
      case "dean":
        setDeanChecked(!deanChecked)
        break;
      case 'methodCenterUser':
        setMethodCenterUserChecked(!methodCenterUserChecked)
        break;
      case "admin":
        setAdminChecked(!adminChecked)
        break;
      default:
        break;
    }
  }

  return loading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Обновить Пользователя</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            E-mail
            <Error name={errors.email} />
          </label>
          <input type="email" id="email" defaultValue={user?.email} name="email" ref={register(required)} />

          <label htmlFor="roles">Выберите Должность</label>
            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="admin" ref={register} disabled={studentChecked || basePracticeLeaderChecked} checked={adminChecked} onChange={e => handleChange(e.target.value)} />
              Администратор
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="dean" ref={register} disabled={studentChecked || basePracticeLeaderChecked || chairHeadChecked || methodCenterUserChecked} checked={deanChecked} onChange={e => handleChange(e.target.value)} />
              Декан
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="headOfChair" ref={register} disabled={studentChecked || basePracticeLeaderChecked || deanChecked || methodCenterUserChecked} checked={chairHeadChecked} onChange={e => handleChange(e.target.value)} />
              Заведующий кафедрой
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="teacher" ref={register} disabled={studentChecked || basePracticeLeaderChecked || methodCenterUserChecked} checked={teacherChecked} onChange={e => handleChange(e.target.value)} />
              Преподаватель
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="practiceChairLeader" ref={register} disabled={studentChecked || basePracticeLeaderChecked || methodCenterUserChecked} checked={chairPracticeLeaderChecked} onChange={e => handleChange(e.target.value)} />
              Руководитель практики (на кафедре)
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="practiceBaseLeader" ref={register} disabled={studentChecked || adminChecked || deanChecked || teacherChecked || chairHeadChecked || chairPracticeLeaderChecked || methodCenterUserChecked} checked={basePracticeLeaderChecked} onChange={e => handleChange(e.target.value)} />
              Руководитель практики (на базе)
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="methodCenterUser" ref={register} disabled={studentChecked || adminChecked || deanChecked || teacherChecked || chairHeadChecked || chairPracticeLeaderChecked} checked={methodCenterUserChecked} onChange={e => handleChange(e.target.value)} />
              Сотрудник УМЦ
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="student" ref={register} disabled={basePracticeLeaderChecked || adminChecked || deanChecked || teacherChecked || chairHeadChecked || chairPracticeLeaderChecked || methodCenterUserChecked} checked={studentChecked} onChange={e => handleChange(e.target.value)} />
              Студент
            </label>

          { studentChecked && <Fragment><label htmlFor="chair_id">Выберите Группу</label>
          <select name="group_id" id="group_id" ref={register}>
          { groups?.map(group => (
            <option key={group.id} selected={group.id === studentUser?.group.id} value={group.id}>{groupLoading === "pending" ? "Загружаем..." : group.name}</option>
          ))}
          </select></Fragment> }

          { (chairHeadChecked || teacherChecked || chairPracticeLeaderChecked || deanChecked) && <Fragment><label htmlFor="chair_id">Выберите Кафедру</label>
          <select name="chair_id" id="chair_id" ref={register}>
          { chairs?.map(chair => (
            <option key={chair.id} selected={chair.id === teacherUser?.chair.id || chair.id === chairHeadUser?.chair.id || chair.id === deanUser?.chair.id} value={chair.id}>{chairLoading === "pending" ? "Загружаем..." : chair.name_ru}</option>
          ))}
          </select></Fragment> }

          { basePracticeLeaderChecked && <Fragment><label htmlFor="base_id">Выберите Базу Практики</label>
          <select name="base_id" id="base_id" ref={register}>
          { bases?.map(base => (
            <option key={base.id} selected={base.id === baseUser?.base.id} value={base.id}>{baseLoading === "pending" ? "Загружаем..." : base.name_ru}</option>
          ))}
          </select></Fragment> }

          <button>Изменить</button>

          <Link className="crud_back" to="/users">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default UpdateUser
