import React, { Fragment, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { selectAuthLoading, selectIsAuthenticated, signin } from '@features/auth/authSlice'

const Login = () => {
  const loading = useSelector(selectAuthLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async (data) => {
    dispatch(signin(data))
  }

  if(isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return (
    loading === "pending" ? (<Spinner />) : (<Fragment>
      <div className="auth">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex jcon-center form_header t-center">
            <img src="/img/logo_light.png" className="logo_icon" alt="" />
          </div>
          <div className="form_block form_header t-center">
            Войти | SKMA Online
          </div>
          <div className="form_block">
            <label htmlFor="email">Почта <Error name={errors.email} /></label>
            <input type="email" id="email" name="email" ref={register(required)} />
          </div>

          <div className="form_block">
            <label htmlFor="password">Пароль <Error name={errors.password} /></label>
            <input type="password" id="password" name="password" ref={register(required)} />
          </div>
          
          <button>Войти</button>
          <Link to="/forgot-password" className="form_link">Забыли пароль?</Link>
        </form>
      </div>
    </Fragment>)
  )
}

export default Login
