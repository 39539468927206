import React, { useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

import PracticeInfo from '@features/practice/components/PracticeInfo'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeChairReview, selectPracticeChairReview, selectPracticeChairReviewLoading } from '@features/practice/practiceChairReviewSlice'
import { getPracticeStudentWithPractice, selectPracticeStudent, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'

const PracticeChairHeadQr = ({ match }) => {
  const dispatch = useDispatch()
  const practiceChairReview = useSelector(selectPracticeChairReview)
  const practiceStudent = useSelector(selectPracticeStudent)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const loading = useSelector(selectPracticeChairReviewLoading)

  useEffect(() => {
    dispatch(getPracticeStudentWithPractice(match.params.practiceStudentRandom)).then(res => {
      if (!res.payload) {
        return
      }

      const data = {
        practiceId: res.payload.data.practice.uuid,
        studentId: res.payload.data.student.uuid
      }
  
      dispatch(getPracticeChairReview(data))
    })
  }, [])

  return (
      loading === "pending" ? <Spinner /> : <div>
        <PracticeInfo practice={practiceStudent?.practice} student={practiceStudent?.student} practiceLoading={practiceStudentLoading} studentLoading={practiceStudentLoading} />
        { practiceChairReview?.teacher_signature ? <div className="qr_signature">Руководитель практики (на кафедре) поставил(а) подпись</div> : <div className="qr_signature">Руководитель практики (на кафедре) НЕ поставил(а) подпись</div>}
      </div>
  )
}

export default PracticeChairHeadQr
