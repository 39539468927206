import React from 'react'

import AccordionItem from '@components/layout/accordion/AccordionItem'

import { url } from '@utils/api'

const Help = () => {
  return (
    <div className="mt-20">
      <h3>Помощь</h3>

      <ul className="mt-20">
        <AccordionItem title={`Какой браузер рекомендуется использовать?`}>
          <p>1. Google Chrome</p> 
          <p>2. Firefox</p> 
          <p>3. Safari</p> 
        </AccordionItem>
        <AccordionItem title={`Что делать если появится уведомление "Что-то пошло не так. Попробуйте еще раз"?`}>
          Перезагрузите страницу и работайте дальше.
        </AccordionItem>
        <AccordionItem title={`Что делать если появится белая страница без ничего?`}>
          Перезагрузите страницу и работайте дальше.
        </AccordionItem>
        <AccordionItem title={`Что делать если я перезагрузил страницу и продолжил работу, но опять появилось уведомление "Что-то пошло не так. Попробуйте еще раз"?`}>
          Убедитесь, что Вы подключены к стабильному интернету.
        </AccordionItem>
        <AccordionItem title={`Что делать если появляется ошибка при добавлении плана или содержимого в дневник?`}>
          <p>1. Убедитесь, что Вы подключены к стабильному интернету.</p>
          <p>2. Убедитесь, что Вы пользуетесь одним из рекомендованных браузеров.</p>
          <p>3. Почистите кэш браузера. <br /> 
          3.1. Chrome, Firefox для Windows: Нажмите Shift+F5 или Ctrl+F5 или Ctrl+Shift+R.
          <br />
          3.2. Chrome, Firefox для Mac: Нажмите Shift+Command+R.</p>
          <p>4. Если первые три пункта не сработали, отправляем почтовый адрес, ФИО, скриншот ошибки, вид браузера, дату и время руководителю практики на кафедре.</p>  
        </AccordionItem>
        <AccordionItem title={`Что делать если появляется ошибка при формировании отчета?`}>
          <p>Отправляем почтовый адрес, ФИО, скриншот ошибки, дату и время руководителю практики на кафедре.</p>
        </AccordionItem>
        <AccordionItem title={`Студент говорит что ссылка на регистрацию не пришла.`}>
          <p>1. Убедитесь, что написали почтовый адрес правильно.</p>
          <p>2. Пусть студент проверит папку спам в почте.</p>
          <p>3. Если первые два пункта не сработали, отправляем почтовый адрес студента руководителю практики на кафедре.</p>
        </AccordionItem>
        <AccordionItem title={`Перешел в раздел Практика, а там пусто.`}>
          <p>1. Перезагрузите страницу, должно появится список практик на которых Вас добавили.</p>
          <p>2. Если ничего не появилось, сообщаем руководителю практики что Вы не добавлены к практике.</p>
        </AccordionItem>
        <AccordionItem title={`Сколько аккаунтов мне нужно для прохождения практики?`}>
          <p>1. На протяжении всего периода обучения Вам нужен только ОДИН аккаунт.</p> 
          <p>2. Если у Вас уже есть аккаунт, но руководитель практики требует отправить адрес электронной почты для регистрации, сообщите руководителю практики что у Вас уже есть аккаунт.</p>
        </AccordionItem>
      </ul>
    </div>
  )
}

export default Help
