import React, { useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

import PracticeInfo from '@features/practice/components/PracticeInfo'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeStudentWithPractice, selectPracticeStudent, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'
import { getPracticeStudentReview, selectPracticeStudentReview, selectPracticeStudentReviewLoading } from '@features/practice/practiceStudentReviewSlice'

const StudentQr = ({ match }) => {
  const dispatch = useDispatch()
  const practiceStudentReview = useSelector(selectPracticeStudentReview)
  const practiceStudent = useSelector(selectPracticeStudent)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const loading = useSelector(selectPracticeStudentReviewLoading)

  useEffect(() => {
    dispatch(getPracticeStudentWithPractice(match.params.practiceStudentRandom)).then(res => {
      if (!res.payload) {
        return
      }

      const data = {
        practiceId: res.payload.data.practice.uuid,
        studentId: res.payload.data.student.uuid
      }

      dispatch(getPracticeStudentReview(data))
    })
  }, [])

  return (
    loading === "pending" ? <Spinner /> : 
      <div>
        <PracticeInfo practice={practiceStudent?.practice} student={practiceStudent?.student} practiceLoading={practiceStudentLoading} studentLoading={practiceStudentLoading} />
        { practiceStudentReview?.signature ? <div className="qr_signature">Студент поставил(а) подпись</div> : <div className="qr_signature">Студент НЕ поставил(а) подпись</div>}
      </div>
  )
}

export default StudentQr
