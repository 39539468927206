import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getGroup, updateGroup, selectGroup, selectGroupLoading } from '@features/group/groupSlice'
import { getChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'

const UpdateGroup = ({ match }) => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history =  useHistory()
  const dispatch = useDispatch()
  const group = useSelector(selectGroup)
  const chairs = useSelector(selectChairs)
  const groupLoading = useSelector(selectGroupLoading)
  const chairLoading = useSelector(selectChairLoading)

  useEffect(() => {
    dispatch(getGroup(match.params.groupId))
  }, [match.params.groupId])

  useEffect(() => {
    dispatch(getChairs())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      id: group.id,
      history
    }

    dispatch(updateGroup(validData))
  }

  return groupLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Обновить Группу</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name">
            Название
            <Error name={errors.name} />
          </label>
          <input type="text" id="name" defaultValue={group?.name} name="name" ref={register(required)} />

          <label htmlFor="course">
            Название
            <Error name={errors.course} />
          </label>
          <input type="number" id="course" defaultValue={group?.course} name="course" ref={register(required)} />

          <label htmlFor="chair_id">Выберите Факультет <Error name={errors.chair_id} /></label>
            <select name="chair_id" ref={register(required)}>
            {chairLoading === "pending" ? (
              <option>Загружаем...</option>
            ) : chairs?.map(chair => (
              <option key={chair?.id} value={chair?.id} selected={chair?.id === group?.chair.id}>{chair?.name_ru}</option>
            ))}
          </select>

          <button className="button">Изменить</button>

          <Link className="crud_back" to="/groups">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default UpdateGroup
