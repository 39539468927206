import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'

// Icons
import edit from "@icons/edit.png"
import trash from "@icons/trash.png"

import { getUserFullName, getRoleName } from '@utils/helpers'
 
import DeleteUser from "./DeleteUser"

import Spinner from '@components/layout/Spinner'
import Pagination from '@components/layout/Pagination'

// State
import { useSelector, useDispatch } from "react-redux"
import { getUsers, getPaginatedUsers, selectUsers, selectUserLoading, filterUsers, selectFilterLoading } from "@features/user/userSlice"

const User = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [filtered, setFiltered] = useState(false)
  const [paginationMeta, setPaginationMeta] = useState({
    total: 0,
    current_page: 0,
    per_page: 0,
    links: []
  })

  const { register, handleSubmit, reset } = useForm()

  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const userLoading = useSelector(selectUserLoading)
  const filterLoading = useSelector(selectFilterLoading)

  useEffect(() => {
    dispatch(getUsers()).then(res => {
      if (!res.payload) {
        return
      }

      setPaginationMeta(res.payload.meta)
    })
  }, [dispatch])

  const handleDeleteForm = (user) => {
    setDeleteModal(!deleteModal)
    setSelectedUser(user)
  }

  const onSubmit = async (data) => {
    dispatch(filterUsers(data)).then(res => {
      if (!res.payload) {

      }
      
      setPaginationMeta(res.payload.meta)
    })
    setFiltered(true)
  }

  const handleResetFilter = () => {
    setFiltered(false)
    dispatch(getUsers()).then(res => {
      if (!res.payload) {

      }
      
      setPaginationMeta(res.payload.meta)
    })
  }

  const getData = (page) => {
    if (!page.url) {
      return
    }

    dispatch(getPaginatedUsers(page.url)).then(res => {
      if (!res.payload) {
        return
      }
      
      setPaginationMeta(res.payload.meta)
    })
  }

  return userLoading === "pending" ? (
      <Spinner />
  ) : (
    <Fragment>
      {deleteModal && (
        <DeleteUser
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          user={selectedUser}
        />
      )}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Пользователи</div>
          <Link to="/users/create"><button className="table_create button">
            + Добавить
          </button></Link>
        </div>

        <form className="filter_block mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="filter_block_search">
            <div className="filter_block_group">
              <label htmlFor="email">
                Почта
              </label>
              <input id="email" name="email" ref={register} />
            </div>

            <div className="filter_block_group">
              <label htmlFor="last_name">
                Фамилия
              </label>
              <input id="last_name" name="last_name" ref={register} />
            </div>
          </div>

          <div className="filter_block_bottom mt-10">
            <select className="filter_block_role" name="role" ref={register}>
              <option value="none">Выберите должность</option>
              <option value="admin">Администратор</option>
              <option value="dean">Декан</option>
              <option value="headOfChair">Заведующий кафедрой</option>
              <option value="teacher">Преподаватель</option>
              <option value="practiceChairLeader">Руководитель практики (на кафедре)</option>
              <option value="practiceBaseLeader">Руководитель практики (на базе)</option>
              <option value="methodCenterUser">Сотрудник УМЦ</option>
              <option value="student">Студент</option>
            </select>

            <button className="filter_button button">Фильтровать</button>
            { filtered && <div className="filter_button reset_filter_button" onClick={() => handleResetFilter()}>Сбросить фильтр</div> }
          </div>
        </form>

        { filterLoading === "pending" ? (<Spinner />) :
          (<Fragment><div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>E-mail</th>
              <th>Должность</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {users?.map((user, index, users) => (
              <tr key={user.id}>
                <td>{paginationMeta.total-- - ((paginationMeta.current_page - 1) * paginationMeta.per_page)}</td>
                <td>{getUserFullName(user)}</td>
                <td>{user.email}</td>
                <td>
                  {user.roles.map(role => (
                    <p>{getRoleName(role)}</p>
                  ))}
                </td>
                <td>
                  <img
                    onClick={() => handleDeleteForm(user)}
                    className="admin_icons"
                    src={trash}
                    alt=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <Pagination links={paginationMeta.links} getData={getData} />
        </Fragment>)
        }
      </div>
    </Fragment>
  )
}

export default User
