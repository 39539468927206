import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import userReducer from './features/user/userSlice'
import alertReducer from './features/alert/alertSlice'
import academicYearReducer from './features/academic-year/academicYearSlice'
import facultyReducer from './features/faculty/facultySlice'
import programReducer from './features/program/programSlice'
import chairReducer from './features/chair/chairSlice'
import groupReducer from './features/group/groupSlice'
import baseReducer from './features/base/baseSlice'
import teacherReducer from './features/teacher/teacherSlice'
import practiceReducer from './features/practice/practiceSlice'
import practiceStudentReducer from './features/practice/practiceStudentSlice'
import practiceScheduleReducer from './features/practice/practiceScheduleSlice'
import practiceContentReducer from './features/practice/practiceContentSlice'
import practiceStudentReviewReducer from './features/practice/practiceStudentReviewSlice'
import practiceChairReviewReducer from './features/practice/practiceChairReviewSlice'
import practiceBaseReviewReducer from './features/practice/practiceBaseReviewSlice'
import practiceComissionReducer from './features/practice/practiceComissionSlice'
import practicePdfReducer from './features/practice/practicePdfSlice'
import studentReducer from './features/student/studentSlice'
import deanReducer from './features/dean/deanSlice'
import statisticsReducer from './features/statistics/statisticsSlice'
import layoutReducer from './features/layout/layoutSlice'

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  alert: alertReducer,
  academicYear: academicYearReducer,
  faculty: facultyReducer,
  program: programReducer,
  chair: chairReducer,
  group: groupReducer,
  base: baseReducer,
  teacher: teacherReducer,
  practice: practiceReducer,
  practiceStudent: practiceStudentReducer,
  practiceSchedule: practiceScheduleReducer,
  practiceContent: practiceContentReducer,
  practiceStudentReview: practiceStudentReviewReducer,
  practiceChairReview: practiceChairReviewReducer,
  practiceBaseReview: practiceBaseReviewReducer,
  practiceComission: practiceComissionReducer,
  practicePdf: practicePdfReducer,
  student: studentReducer,
  dean: deanReducer,
  layout: layoutReducer,
  statistics: statisticsReducer
})

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined
    localStorage.removeItem("token")
  }

  return appReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  devTools: false
})
