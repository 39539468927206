import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getTeacher = createAsyncThunk(
  'teacher/teacherById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getTeacherByUser = createAsyncThunk(
  'teacher/teacherByUser',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers/user/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getTeachers = createAsyncThunk(
  'teacher/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairTeachers = createAsyncThunk(
  'teacher/chairTeacherList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers/chair/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedChairTeachers = createAsyncThunk(
  'teacher/paginate',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createTeacher = createAsyncThunk(
  'teacher/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/teachers`, data, config)
      data.history.push('/teachers')
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createChairTeacher = createAsyncThunk(
  'teacher/createChairTeacher',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/teachers/chair`, data, config)
      data.history.push('/teachers')
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateTeacher = createAsyncThunk(
  'teacher/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/teachers/${data.id}`, data, config)
      data.history.push('/teachers')
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteTeacher = createAsyncThunk(
  'teacher/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().teacher
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/teachers/${data}`, config)
      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const filterTeachers = createAsyncThunk(
  'teacher/filter',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().teacher
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers/filter?&lastName=${data.last_name}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const filterChairTeachers = createAsyncThunk(
  'teacher/chairFilter',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().teacher
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/teachers/chair/${data.chair_id}/filter?firstName=${data.first_name}&lastName=${data.last_name}&middleName=${data.middle_name}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const teacherSlice = createSlice({
  name: 'teacher',
  initialState: {
    loading: 'idle',
    filterLoading: 'idle',
    teacher: null,
    teachers: [],
    filteredTeachers: []
  },
  reducers: {
    resetFilteredTeachers: (state, action) => {
      state.filterLoading = 'pending'
      state.filteredTeachers = []
      state.filterLoading = 'idle'
    }
  },
  extraReducers: {
    // One
    [getTeacher.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Get By User
    [getTeacherByUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getTeachers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getTeachers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teachers = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getTeachers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getChairTeachers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairTeachers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teachers = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairTeachers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated list
    [getPaginatedChairTeachers.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedChairTeachers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teachers = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedChairTeachers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },


    // create
    [createTeacher.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = action.payload.data
        state.teachers.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createChairTeacher.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createChairTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = action.payload.data
        state.teachers.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createChairTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateTeacher.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = action.payload.data
        state.teachers = state.teachers.map(teacher => (
          teacher.id === action.payload.data.id ? teacher = action.payload.data : teacher
        ))
        state.currentRequestId = undefined
      }
    },
    [updateTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteTeacher.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteTeacher.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.teacher = null
        state.teachers = state.teachers.filter(teacher => teacher.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteTeacher.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Filter
    [filterTeachers.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [filterTeachers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.filteredTeachers = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [filterTeachers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Filter
    [filterChairTeachers.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [filterChairTeachers.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.teachers = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [filterChairTeachers.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const { resetFilteredTeachers } = teacherSlice.actions

export const selectTeacherLoading = state => state.teacher.loading
export const selectFilterTeacherLoading = state => state.teacher.filterLoading
export const selectTeacher = state => state.teacher.teacher
export const selectTeachers = state => state.teacher.teachers
export const selectFilteredTeachers = state => state.teacher.filteredTeachers

export default teacherSlice.reducer
