import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Icons
import comission from '@icons/comission.png'
import trash from '@icons/trash.png'

import Spinner from '@components/layout/Spinner'

import { formatDate } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getBaseLeaderPractices, selectPractices, selectPracticeLoading } from '@features/practice/practiceSlice'

const Practice = () => {
  const dispatch = useDispatch()
  const practices = useSelector(selectPractices)
  const loading = useSelector(selectPracticeLoading)

  useEffect(() => {
    dispatch(getBaseLeaderPractices())
  }, [dispatch])

  return loading === "pending" ? <Spinner /> : (
    <Fragment>
          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Группы</div>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                { practices?.slice(0).reverse().map((practice, index) => (
                  <tr key={practice.id}>
                    <td>{++index}</td>
                    <td>{practice.name} <p>{formatDate(practice.start)} - {formatDate(practice.end)}</p></td>
                    <td>
                      <Link className="admin_icons" to={`/base-practices/${practice.uuid}/students`}><img src={`${process.env.PUBLIC_URL}/img/icons/menu/my-profile.png`} /></Link>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table></div>
          </div>
    </Fragment> )
}

export default Practice
