import React, { Fragment } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, selectAuthLoading, selectIsAuthenticated } from '@features/auth/authSlice'

const ForgotPassword = () => {
  const { register, handleSubmit, reset, errors } = useForm()
  const dispatch = useDispatch()
  const loading = useSelector(selectAuthLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const history = useHistory()

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }
    dispatch(forgotPassword(validData))
    reset()
  }

  if(isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return loading === "pending" ? (<Fragment><Spinner /></Fragment>) : (
    <Fragment>
      <div className="auth">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex jcon-center form_header t-center">
            <img src="/img/logo_light.png" className="logo_icon" alt="" />
          </div>
          <div className="form_block form_header t-center">
            Восстановление пароля | SKMA Online
          </div>
          <div className="form_block">
            <label htmlFor="email">Почта <Error name={errors.email} /></label>
            <input type="email" id="email" name="email" ref={register(required)} />
          </div>
          
          <button>Восстановить пароль</button>
          <Link to="/" className="form_link">Войти</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default ForgotPassword
