import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getChair, updateChair, selectChair, selectChairLoading } from '@features/chair/chairSlice'
import { getPrograms, selectPrograms, selectProgramLoading } from '@features/program/programSlice'

const UpdateChair = ({ match }) => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history =  useHistory()
  const dispatch = useDispatch()
  const chair = useSelector(selectChair)
  const programs = useSelector(selectPrograms)
  const chairLoading = useSelector(selectChairLoading)
  const programLoading = useSelector(selectProgramLoading)

  useEffect(() => {
    dispatch(getChair(match.params.chairId))
  }, [match.params.chairId])

  useEffect(() => {
    dispatch(getPrograms())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      id: chair.id,
      history
    }

    dispatch(updateChair(validData))
  }

  return chairLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Обновить Кафедру</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name_ru">
            Название на русском
            <Error name={errors.name_ru} />
          </label>
          <input type="text" id="name_ru" defaultValue={chair?.name_ru} name="name_ru" ref={register(required)} />

          <label htmlFor="name_kz">
            Название на казахском
            <Error name={errors.name_kz} />
          </label>
          <input type="text" id="name_kz" defaultValue={chair?.name_kz} name="name_kz" ref={register(required)} />

          <label htmlFor="name_en">
            Название на английском
            <Error name={errors.name_en} />
          </label>
          <input type="text" id="name_en" defaultValue={chair?.name_en} name="name_en" ref={register(required)} />

          <label htmlFor="program_id">Выберите Факультет <Error name={errors.program_id} /></label>
            <select name="program_id" ref={register(required)}>
            {programLoading === "pending" ? (
              <option>Загружаем...</option>
            ) : programs?.map(program => (
              <option key={program?.id} value={program?.id} selected={program?.id === chair?.program.id}>{program?.name_ru}</option>
            ))}
          </select>

          <button className="button">Изменить</button>

          <Link className="crud_back" to="/chairs">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default UpdateChair
