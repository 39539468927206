import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const loadUser = createAsyncThunk(
  'auth/loadUser',
  async (obj, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    if (!localStorage.token) {
      throw new Error;
    }
  
    setAuthToken(localStorage.token);

    const response = await axios.get(`${url}/api/auth`)
    return response.data
  }
)

export const signin = createAsyncThunk(
  'auth/signin', 
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    try {
      const response = await axios.post(`${url}/api/signin`, data)
      localStorage.setItem("token", response.data.token);
      return response.data 
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error, 
        type: 'fail'
      }))
      throw error
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (obj, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.post(`${url}/api/logout`, config)

      dispatch({
        type: "LOGOUT"
      })
    } catch (error) {
      dispatch(createAlert({
        message: "Не удалось выйти. Перезагрузите страницу и попробуйте еще раз", 
        type: 'fail'
      }))
      throw error
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/logout',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.post(`${url}/api/forgot-password`, data, config)

      data.history.push('/')
      dispatch(createAlert({
        message: "Ссылка на воостановление пароля отправлен на Вашу почту. Проверьте почту", type: "success"
      }))
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error, 
        type: 'fail'
      }))
      throw error
    }
  }
)

export const giveRole = createAsyncThunk(
  'auth/giveRole',
  async (obj, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    if (!localStorage.token) {
      throw new Error
    }
  
    setAuthToken(localStorage.token);

    const response = await axios.get(`${url}/api/give-role`)
    return response.data
  }
)

export const updateUserInfo = createAsyncThunk(
  'auth/updateInfo',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/users/${data.id}/infoUpdate`, data, config)
      data.history.push('/dashboard')

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateAvatar = createAsyncThunk(
  'auth/updateAvatar',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/users/avatar`, data, {
        ...config,
        onUploadProgress: data => {
          let number = (100 * data.loaded / data.total).toFixed(2)
          dispatch(setProgressAvatar(number))

          if (Number(number) === 100) {
            dispatch(setProgressAvatar(null))
            dispatch(createAlert({
              message: "Аватар успешно изменен",
              type: 'success'
            }))
          }
        }
      })

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Не удалось загрузить аватар. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteAvatar = createAsyncThunk(
  'auth/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().auth
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/users/deleteAvatar`, config)

      dispatch(createAlert({
        message: "Аватар удален",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Не удалось удалить. Перезагрузите страницу и попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: 'idle',
    token: null,
    user: null,
    isAuthenticated: false,
    progressAvatar: null
  },
  reducers: {
    setProgressAvatar: (state, action) => {
      state.progressAvatar = action.payload
    }
  },
  extraReducers: {
    [signin.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [signin.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload.user
        state.token = action.payload.token
        state.isAuthenticated = true
        state.currentRequestId = undefined
      }
    },
    [signin.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    [loadUser.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [loadUser.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.token = localStorage.getItem("token")
        state.user = action.payload?.user || null
        state.isAuthenticated = true
        state.currentRequestId = undefined
      }
    },
    [loadUser.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
        state.token = null
        state.isAuthenticated = false
        state.user = null
      }
    },
    [logout.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [logout.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state = undefined
      }
    },
    [logout.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // Update Info
    [updateUserInfo.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateUserInfo.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [updateUserInfo.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

                // update avatar
                [updateAvatar.pending]: (state, action) => {
                  if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                  }
                },
                [updateAvatar.fulfilled]: (state, action) => {
                  const { requestId } = action.meta
                  if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.user = action.payload.data
                    state.currentRequestId = undefined
                  }
                },
                [updateAvatar.rejected]: (state, action) => {
                  const { requestId } = action.meta
                  if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                  }
                },

                    // Delete
    [deleteAvatar.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteAvatar.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.user.avatar = null
        state.currentRequestId = undefined
      }
    },
    [deleteAvatar.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

/*     [giveRole.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [giveRole.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
      }
    },
    [giveRole.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    }, */
  }
})

export const { setProgressAvatar } = authSlice.actions

export const selectAuth = state => state.auth
export const selectAuthLoading = state => state.auth.loading
export const selectUser = state => state.auth.user
export const selectIsAuthenticated = state => state.auth.isAuthenticated

export default authSlice.reducer
