import React, { Fragment, useRef, useState } from 'react'
import JoditEditor from "jodit-react"
import { useForm } from 'react-hook-form'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { useHistory, Redirect, Link } from 'react-router-dom'
import { createAlert } from '@features/alert/alertSlice'

import { is } from '@utils/helpers'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createPracticeSchedule, selectPracticeScheduleLoading } from '@features/practice/practiceScheduleSlice'
import { selectUser } from '@features/auth/authSlice'

const StudentPracticePlanAdd = ({ match }) => {
  const [content, setContent] = useState('')
  const [date, setDate] = useState([new Date(), new Date()])
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const loading = useSelector(selectPracticeScheduleLoading)

  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  const history = useHistory()
  const editor = useRef(null)
  const { register, handleSubmit, errors } = useForm()
	
	const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: true
    },
  }

  const onBlurEditor = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
    
  const onEditorChange = (editorNewValue) => {
    editor.current.value = editorNewValue
  }

  const onSubmit = (data) => {
    if (!editor.current.value) {
      dispatch(createAlert({
        message: "Заполните все поля",
        type: 'fail'
      }))
      
      return
    }

    const start = date[0].toLocaleDateString('fr-CA')
    const end = date[1].toLocaleDateString('fr-CA')

    const validData = {
      ...data,
      practiceId,
      studentId,
      content: editor.current.value,
      start,
      end,
      history
    }

    dispatch(createPracticeSchedule(validData))
  }

  if (!is('student', user)) {
    return <Redirect to="/404" />
  }
  
  return loading === 'pending' ? <Spinner /> : (
    <Fragment>
      <div className="trainee">
        <div className="trainee_title">
          Добавить план-график
          <Link to={`/student-practices/${practiceId}/students/${studentId}/plan`} className="table_create link link_w447">Вернуться к личному плану</Link>
        </div>
        <form action="POST" className="flex-column" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="">Контент</label>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={1}
/*             onBlur={newContent => setContent(newContent.target.innerHTML)}
            onChange={newContent => {}} */
            onBlur={onBlurEditor}
            onChange={onEditorChange}
          />

          <label htmlFor="">Примечание { errors.note ? <span className="input_error">Максимальное количество символов 50</span> : null }</label>
          <textarea name="note" id="" ref={register({ maxLength: 200 })} cols="30" rows="10"></textarea>

          <label htmlFor="start">
            Дата практики
          </label>

          <DateTimeRangePicker
            onChange={setDate}
            value={date}
            format="dd.MM.yy"
          />

          <button className="button">+ Добавить</button>
        </form>
      </div>
    </Fragment>
  )
}

export default StudentPracticePlanAdd
