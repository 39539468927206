import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import DeleteFaculty from './DeleteFaculty'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getFaculties, selectFaculties, selectFacultyLoading } from '@features/faculty/facultySlice'

const Faculty = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedFaculty, setSelectedFaculty] = useState(null)
  const faculties = useSelector(selectFaculties)
  const loading = useSelector(selectFacultyLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFaculties())
  }, [dispatch])

  const handleDeleteForm = (faculty) => {
    setDeleteModal(!deleteModal)
    setSelectedFaculty(faculty)
  }

  return loading === "pending" ? (<Spinner />) : (
    <Fragment>
          {
            deleteModal && <DeleteFaculty deleteModal={deleteModal} setDeleteModal={setDeleteModal} faculty={selectedFaculty} />
          }

          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Факультеты</div>
              <Link to="/faculties/create"><button className="table_create button">+ Добавить</button></Link>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {faculties?.map(faculty => (
                  <tr key={faculty.id}>
                    <td>{faculty.id}</td>
                    <td>{faculty.name_ru}</td>
                    <td>
                      <Link to={`/faculties/update/${faculty.id}`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteForm(faculty)} className="admin_icons" src={trash} alt=""/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table></div>
          </div>
    </Fragment>
    )
}

export default Faculty
