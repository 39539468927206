import React, { useState } from 'react'

import AdminNavigation from './AdminNavigation'
import StudentNavigation from './StudentNavigation'
import TeacherNavigation from './TeacherNavigation'
import PracticeBaseNavigation from './PracticeBaseNavigation'
import ChairHeadNavigation from './ChairHeadNavigation'
import MethodCenterUserNavigation from './MethodCenterUserNavigation'
import PracticeChairNavigation from './PracticeChairNavigation'

import ListItem from './ListItem'

import { is } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, selectAuthLoading, selectIsAuthenticated } from '@features/auth/authSlice'
import { selectMenuState, toggleMenu } from '@features/layout/layoutSlice'
import { selectFilterLoading } from '@features/user/userSlice'

const Sidebar = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const authLoading = useSelector(selectAuthLoading)
  const menuState = useSelector(selectMenuState)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const filterLoading = useSelector(selectFilterLoading)

  const handleMenuClose = () => {
    dispatch(toggleMenu())
  }

  return (
    <section className={menuState ? `left menu_open` : `left`}>
      <div className="left_logo_block">
        <a href="https://skma.edu.kz" target="_blank" rel="noreferrer" className="left_logo_link">skma.edu.kz</a>
        <div className="left_logo">
          <img src={`${process.env.PUBLIC_URL}/img/icons/logo.png`} alt="" className="left_logo_img" />
        </div>
        <div className="menu_close" onClick={() => handleMenuClose()}><div></div><div></div></div>
      </div>
      
      { isAuthenticated && authLoading !== "pending" && (
        <nav className="nav">
          { <ul className="nav_list">
            <ListItem img="my-profile" title="Профиль" link="dashboard" />
            { is('admin', user) && <AdminNavigation /> }
            { is('headOfChair', user) && <ListItem img="practice" title="Практика (заведующий кафедрой)" link="chair-head-practices" /> }
            { is('practiceChairLeader', user) && <ListItem img="practice" title="Практика (руководитель практики на кафедре)" link="chair-practices" /> }
            { is('practiceBaseLeader', user) && <ListItem img="practice" title="Практика (руководитель практики на базе)" link="base-practices" /> }
            { is('practiceComission', user) && <ListItem img="practice" title="Практика (комиссия)" link="comission-practices" /> }
            { is('student', user) && <ListItem img="practice" title="Практика" link="student-practices" /> }
            { is('methodCenterUser', user) && <ListItem img="practice" title="Практика (УМЦ)" link="method-center-practices" /> }
            { is('methodCenterUser', user) && <ListItem img="friends" title="Руководители практик" link="practice-chair-leaders" /> }
            { is('methodCenterUser', user) && <ListItem img="academic-performance" title="Статистика" link="statistics" /> }
            { is('practiceChairLeader', user) && <ListItem img="my-profile" title="Студенты" link="students" /> }
            { is('practiceChairLeader', user) && <ListItem img="friends" title="Преподаватели" link="teachers" /> }
            <ListItem img="tasks" title="Помощь" link="help" />
          </ul>}
        </nav>
      ) }
    
    </section>
  )
}

export default Sidebar
