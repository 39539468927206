import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPractice, selectPractice, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getPracticeStudents, selectPracticeStudents, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'

const PracticeStudent = ({ match }) => {
  const dispatch = useDispatch()
  const practice = useSelector(selectPractice)
  const practiceLoading = useSelector(selectPracticeLoading)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const practiceStudents = useSelector(selectPracticeStudents)
  const practiceId = match.params.practiceId

  useEffect(() => {
    dispatch(getPractice(practiceId))
  }, [dispatch])

  useEffect(() => {
    dispatch(getPracticeStudents(practiceId))
  }, [dispatch])

  return (
    practiceLoading === "pending" ? <Spinner /> : (<Fragment>
      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Практиканты "{practice?.name}"</div>
          <Link to={'/method-center-practices'} className="table_create link">Список практик</Link>
        </div>

        {practiceStudentLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Группа</th>
              <th>План</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {practiceStudents?.map((practiceStudent, index) => (
              <tr key={practiceStudent?.id}>
                <td>{++index}</td>
                <td>{`${practiceStudent?.user.first_name_ru} ${practiceStudent?.user.last_name_ru}`}</td>
                <td>{`${practiceStudent?.student.group.name}`}</td>
                <td><Link to={`/method-center-practices/${practiceId}/students/${practiceStudent?.student.uuid}/plan`}>Дневник-отчет</Link></td>
              </tr>
            ))}
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticeStudent
