import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { createChair, selectChairLoading } from '@features/chair/chairSlice'
import { getPrograms, selectPrograms, selectProgramLoading } from '@features/program/programSlice'

const CreateChair = () => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const programs = useSelector(selectPrograms)
  const programLoading = useSelector(selectProgramLoading)
  const chairLoading = useSelector(selectChairLoading)

  useEffect(() => {
    dispatch(getPrograms())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }

    dispatch(createChair(validData))
  }

  return chairLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новая Кафедра</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name_ru">
            Название на русском
            <Error name={errors.name_ru} />
          </label>
          <input type="text" id="name_ru" name="name_ru" ref={register(required)} />

          <label htmlFor="name_kz">
            Название на казахском
            <Error name={errors.name_kz} />
          </label>
          <input type="text" id="name_kz" name="name_kz" ref={register(required)} />

          <label htmlFor="name_en">
            Название на английском
            <Error name={errors.name_en} />
          </label>
          <input type="text" id="name_en" name="name_en" ref={register(required)} />

          <label htmlFor="program_id">Выберите Образовательную программу <Error name={errors.program_id} /></label>
          <select name="program_id" id="program_id" ref={register(required)}>
          {programLoading === "pending" ? (
            <option>Загружаем...</option>
          ) : programs?.map(program => (
            <option key={program.id} value={program.id}>{program.name_ru}</option>
          ))}
          </select>

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/programs">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateChair
