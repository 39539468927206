import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import DeleteAcademicYear from './DeleteAcademicYear'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getAcademicYears, selectAcademicYears, selectAcademicYearLoading } from '@features/academic-year/academicYearSlice'

const AcademicYear = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null)
  const dispatch = useDispatch()
  const academicYears = useSelector(selectAcademicYears)
  const loading = useSelector(selectAcademicYearLoading)

  useEffect(() => {
    dispatch(getAcademicYears())
  }, [dispatch]);

  const handleDeleteForm = (academicYear) => {
    setDeleteModal(!deleteModal)
    setSelectedAcademicYear(academicYear)
  }

  return loading === "pending" ? ( <Spinner /> ) : (
    <Fragment>
          {deleteModal && <DeleteAcademicYear deleteModal={deleteModal} setDeleteModal={setDeleteModal} academicYear={selectedAcademicYear} />}

          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Учебные Годы</div>
              <Link to="/academic-years/create"><button className="table_create button">+ Добавить</button></Link>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Период</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {academicYears?.map(academicYear => (
                  <tr key={academicYear.id}>
                    <td>{academicYear.id}</td>
                    <td>{`${academicYear.start}/${academicYear.end}`}</td>
                    <td>
                      <Link to={`/academic-years/update/${academicYear.id}`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteForm(academicYear)} className="admin_icons" src={trash} alt=""/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table></div>
          </div>
    </Fragment>
    )
}

export default AcademicYear
