import React, { Fragment } from 'react'

// Icons
import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deletePractice } from '@features/practice/practiceSlice'

const DeletePractice = ({ deleteModal, setDeleteModal, practice }) => {
  const dispatch = useDispatch()

  const handleDeletePractice = (practice) => {
    setDeleteModal(!deleteModal)
    dispatch(deletePractice(practice.uuid))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Практику <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить Практику {practice.name}</div>
            <button className="button modal_delete_button" onClick={() => handleDeletePractice(practice)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeletePractice
