import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alerts: []
  },
  reducers: {
    createAlert: (state, action) => {
      const validData = {
        id: uuidv4(),
        message: action.payload.message,
        type: action.payload.type
      }

      state.alerts.push(validData)
    },
    removeAlert: (state, action) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
    }
  }
})

export const { createAlert, removeAlert } = alertSlice.actions

export const selectAlerts = state => state.alert.alerts

export default alertSlice.reducer