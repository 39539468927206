import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Redirect, useHistory } from 'react-router-dom'

import trash from '@icons/trash.png'
import Spinner from '@components/layout/Spinner'

import { is } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPracticeComissions, createPracticeComission, selectPracticeComissionLoading, selectPracticeComissions } from '@features/practice/practiceComissionSlice'
import { filterTeachers, selectFilteredTeachers, selectFilterTeacherLoading, resetFilteredTeachers } from '@features/teacher/teacherSlice'
import { getPractice, selectPractice } from '@features/practice/practiceSlice'
import { selectUser } from '@features/auth/authSlice'

const CreatePracticeComission = ({ match }) => {
  const [filtered, setFiltered] = useState(false)
  const { handleSubmit, register, setValue } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const practiceComissionLoading = useSelector(selectPracticeComissionLoading)
  const practiceComissions = useSelector(selectPracticeComissions)
  const filteredTeachers = useSelector(selectFilteredTeachers)
  const filterTeacherLoading = useSelector(selectFilterTeacherLoading)
  const practice = useSelector(selectPractice)
  const user = useSelector(selectUser)
  const practiceId = match.params.practiceId

  useEffect(() => {
    dispatch(getPracticeComissions(match.params.practiceId))
    dispatch(getPractice(match.params.practiceId))
  }, [match.params.practiceId])

  const onSubmit = async (data) => {
    const validData = {
      ...data
    }

    dispatch(filterTeachers(validData))
    setFiltered(true)
  }

  const handleAddComission = (teacher) => {
    const data = {
      teacher_id: teacher?.uuid,
      practice_id: practice?.uuid
    }

    dispatch(createPracticeComission(data))
  }

  const handleResetFilter = () => {
    setFiltered(false)
    dispatch(resetFilteredTeachers())
  }

  if (!is('headOfChair', user)) {
    return <Redirect to="/404" />
  }

  return (
    <Fragment>
        <form className="filter_block mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="filter_block_search">
            <div className="filter_block_group">
              <label htmlFor="last_name">
                Фамилия
              </label>
              <input id="last_name" name="last_name" ref={register} />
            </div>
          </div>

          <div className="filter_block_bottom mt-10">
            <button className="button">Фильтровать</button>
            { filtered && <div className="filter_button reset_filter_button" onClick={() => handleResetFilter()}>Сбросить фильтр</div> }
          </div>
        </form>

      {filteredTeachers && filteredTeachers.length > 0 && filterTeacherLoading === "pending" ? <Spinner /> : ( <div className="table">
        <div className="table_create_title">
          <div className="table_title">Поиск</div>
          <Link to={`/chair-head-practices/${match.params.practiceId}/comissions`} className="table_create link">Список Комиссии</Link>
        </div>

        {filterTeacherLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {filteredTeachers?.map((teacher, index) => (
              is('teacher', teacher.user) && teacher.id !== practice.teacher.id ? <tr key={teacher.id}>
                <td>{++index}</td>
                <td>{`${teacher.user.last_name_ru} ${teacher.user.first_name_ru}`} {(is('teacher', teacher.user)) && '(Преподаватель)'}</td>
                {
                  practiceComissions?.some(s => s.id === teacher.id) ? <td>Добавлен</td> : <td><button onClick={() => handleAddComission(teacher)} className="button_add">+</button></td>
                }
              </tr> : <tr key={teacher.id}></tr>
            ))}
          </tbody>
        </table></div>}
      </div> )}
    </Fragment>
  )
}

export default CreatePracticeComission
