import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getStatistics = createAsyncThunk(
  'statistics/statistics',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().statistics
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/statistics`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    loading: 'idle',
    statistics: null
  },
  reducers: {},
  extraReducers: {
    // One
    [getStatistics.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStatistics.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.statistics = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStatistics.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectStatisticsLoading = state => state.statistics.loading
export const selectStatistics = state => state.statistics.statistics

export default statisticsSlice.reducer
