import React, { useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

import PracticeInfo from '@features/practice/components/PracticeInfo'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeStudentWithPractice, selectPracticeStudent, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'
import { getPracticeChairReview, selectPracticeChairReview, selectPracticeChairReviewLoading } from '@features/practice/practiceChairReviewSlice'

const ChairHeadQr = ({ match }) => {
  const dispatch = useDispatch()
  const practiceChairReview = useSelector(selectPracticeChairReview)
  const practiceStudent = useSelector(selectPracticeStudent)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const loading = useSelector(selectPracticeChairReviewLoading)

  useEffect(() => {
    dispatch(getPracticeStudentWithPractice(match.params.practiceStudentRandom)).then(res => {
      if (!res.payload) {
        return
      }

      dispatch(getPracticeChairReview({
        practiceId: res.payload.data.practice.uuid,
        studentId: res.payload.data.student.uuid
      }))
    })
  }, [])

  return (
    loading === "pending" ? <Spinner /> : 
      <div key={practiceStudent?.id}>
        <PracticeInfo practice={practiceStudent?.practice} student={practiceStudent?.student} practiceLoading={practiceStudentLoading} studentLoading={practiceStudentLoading} />
        { practiceChairReview?.head_of_chair_signature ? <div className="qr_signature">Заведующий кафедрой поставил(а) подпись</div> : <div className="qr_signature">Заведующий кафедрой НЕ поставил(а) подпись</div>}
      </div>
  )
}

export default ChairHeadQr
