import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import JoditEditor from "jodit-react"
import { createAlert } from '@features/alert/alertSlice'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeStudentReview, updatePracticeStudentReview, selectPracticeStudentReview, selectPracticeStudentReviewLoading } from '@features/practice/practiceStudentReviewSlice'

const PracticeReview = ({ match }) => {
  const [content, setContent] = useState('')
  const { register, handleSubmit } = useForm()
  const history = useHistory()
  const editor = useRef(null)
  const dispatch = useDispatch()
  const loading = useSelector(selectPracticeStudentReviewLoading)
  const practiceStudentReview = useSelector(selectPracticeStudentReview)
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    const data = {
      practiceId,
      studentId
    }
    
    dispatch(getPracticeStudentReview(data)).then(res => setContent(res.payload.data.review))
  }, [dispatch])

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: true
    },
  }

  const onBlurEditor = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
    
  const onEditorChange = (editorNewValue) => {
    editor.current.value = editorNewValue
  }

  const onSubmit = data => {
    if (!editor.current.value) {
      dispatch(createAlert({
        message: "Заполните все поля",
        type: 'fail'
      }))
      
      return
    }

    setContent(editor.current.value)

    const updateData = {
      uuid: practiceStudentReview?.uuid,
      review: editor.current.value,
      history
    }
    
    dispatch(updatePracticeStudentReview(updateData))
  }

  return (
    loading === "pending" ? (<Spinner />) : (
      <Fragment>
        <div className="trainee">
          <div className="trainee_title">
            Отчет студента
            <Link to="/student-practices" className="table_create link">Список практик</Link>
          </div>

          <form action="" className="flex-column mt-10" onSubmit={handleSubmit(onSubmit)}>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              onBlur={onBlurEditor}
              onChange={onEditorChange}
              /* onBlur={newContent => setContent(newContent.target.innerHTML)}
              onChange={newContent => {}} */
            />
            <button className="button" type="submit">Сохранить</button>
          </form>
        </div>
      </Fragment>
    )
  )
}

export default PracticeReview
