import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getChair = createAsyncThunk(
  'chair/chairById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/chairs/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairs = createAsyncThunk(
  'chair/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/chairs`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedChairs = createAsyncThunk(
  'chair/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createChair = createAsyncThunk(
  'chair/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/chairs`, data, config)
      data.history.push('/chairs')

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateChair = createAsyncThunk(
  'chair/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/chairs/${data.id}`, data, config)
      data.history.push('/chairs')

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteChair = createAsyncThunk(
  'chair/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().chair
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/chairs/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const chairSlice = createSlice({
  name: 'chair',
  initialState: {
    loading: 'idle',
    chair: null,
    chairs: []
  },
  reducers: {},
  extraReducers: {
    // One
    [getChair.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChair.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chair = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChair.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getChairs.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairs.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chairs = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairs.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated list
    [getPaginatedChairs.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedChairs.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chairs = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedChairs.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createChair.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createChair.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chair = action.payload.data
        state.chairs.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createChair.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateChair.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateChair.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chair = action.payload.data
        state.chairs = state.chairs.map(chair =>
          chair.id === action.payload.data.id ? chair = action.payload.data : chair
        )
        state.currentRequestId = undefined
      }
    },
    [updateChair.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteChair.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteChair.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.chair = null
        state.chairs = state.chairs.filter(chair => chair.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteChair.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectChairLoading = state => state.chair.loading
export const selectChair = state => state.chair.chair
export const selectChairs = state => state.chair.chairs

export default chairSlice.reducer
