import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'

import Spinner from '@components/layout/Spinner'
import PracticeInfo from '@features/practice/components/PracticeInfo'

import { formatDate } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPractice, selectPractice, getPracticeWithUser, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getPracticeSchedules, selectPracticeSchedules, selectPracticeScheduleLoading } from '@features/practice/practiceScheduleSlice'
import { getPracticeContents, selectPracticeContents, selectPracticeContentLoading } from '@features/practice/practiceContentSlice'
import { getStudent, selectStudent, selectStudentLoading } from '@features/student/studentSlice'
import { getDeanByChair, selectDean, selectDeanLoading } from '@features/dean/deanSlice'
import { getPracticeBaseReview, updateSignaturePracticeBaseReview, selectPracticeBaseReview, selectPracticeBaseReviewLoading } from '@features/practice/practiceBaseReviewSlice'
import { getBaseUser, selectBaseUser, selectUserLoading } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const PracticePlan = ({ match }) => {
  const [signatureLoading, setSignatureLoading] = useState("pending")
  const dispatch = useDispatch()
  const practice = useSelector(selectPractice)
  const dean = useSelector(selectDean)
  const student = useSelector(selectStudent)
  const deanLoading = useSelector(selectDeanLoading)
  const practiceLoading = useSelector(selectPracticeLoading)
  const studentLoading = useSelector(selectStudentLoading)
  const practiceSchedules = useSelector(selectPracticeSchedules)
  const practiceScheduleLoading = useSelector(selectPracticeScheduleLoading)
  const practiceContents = useSelector(selectPracticeContents)
  const practiceContentLoading = useSelector(selectPracticeContentLoading)
  const user = useSelector(selectUser)
  const userLoading = useSelector(selectUserLoading)
  const practiceBaseReview = useSelector(selectPracticeBaseReview)
  const practiceBaseReviewLoading = useSelector(selectPracticeBaseReviewLoading)
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    dispatch(getPracticeWithUser(practiceId)).then(res => {
      dispatch(getDeanByChair(res.payload.data.teacher.chair.id))
    })

    dispatch(getStudent(studentId))
    const data = {
      practiceId,
      studentId
    }
    dispatch(getPracticeSchedules(data))
    dispatch(getPracticeContents(data))
    dispatch(getBaseUser(user?.id)).then(res => {
      dispatch(getPracticeBaseReview({
        ...data,
        baseUserId: res.payload.data.id
      })).then(res => setSignatureLoading("fulfilled"))
    })
  }, [dispatch, practiceId, studentId])

  const handleUpdatePracticeBaseReview = () => {
    setSignatureLoading("pending")
    const data = {
      ...practiceBaseReview,
      signature: 1
    }

    dispatch(updateSignaturePracticeBaseReview(data)).then(res => setSignatureLoading("fulfilled"))
  }

  const handleCopy = (e) => {
    e.preventDefault()

    return false
  }

  return (
    (<Fragment>
      <div className="trainee">
        <div className="trainee_title">
          Практика
          <Link to={`/base-practices/${practiceId}/students`} className="table_create link link_w447">Список практикантов</Link>
          {signatureLoading === "pending" ? <span className="table_create">Загружаем...</span> : <button className={practiceBaseReview?.signature ? `button_disabled table_create button_w563` : `button table_create button_w563` } onClick={() => handleUpdatePracticeBaseReview()} disabled={practiceBaseReview?.signature ? true : false}>{practiceBaseReview?.signature ? "Подпись поставлен" : "Поставить подпись"}</button>}
        </div>

        <PracticeInfo practice={practice} student={student} dean={dean} practiceLoading={practiceLoading} studentLoading={studentLoading} deanLoading={deanLoading} />
        
        <div className="trainee_table_title">Рабочий план-график производственной практики 
        </div>

        { practiceScheduleLoading === "pending" ? "Загружаем..." : <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
          <thead>
            <tr>
              <th>#</th>
              <th>Перечень работ, подлежащих выполнению (изучению) в соответствии с рабочей программой производственной практики</th>
              <th colSpan="2">Сроки выполнения программы практики</th>
              <th>Примечание</th>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td>Начало</td>
              <td>Завершение</td>
              <td></td>
            </tr>
          </thead>

          <tbody className="student_practice_table_body">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            { practiceSchedules?.map((schedule, index) => (
                <Fragment key={schedule?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>{ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(schedule?.content) }} /> }</td>
                    <td>{formatDate(schedule?.start)}</td>
                    <td>{formatDate(schedule?.end)}</td>
                    <td>{schedule?.note}</td>
                  </tr>        
                </Fragment>
              ))
            }
          </tbody>
        </table></div>}

        <div className="trainee_table_title">Содержание производственной практики 
        </div>

        { practiceContentLoading === "pending" ? "Загружаем..." : <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
          <thead>
            <tr>
              <th>#</th>
              <th>Наименование и содержание выполненных (изученых) работ в соответствии с рабочей программой производственной практики за каждый день</th>
              <th colSpan="2">Сроки выпол. отдельных тем, работ практики</th>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td>Начало</td>
              <td>Завершение</td>
            </tr>
          </thead>

          <tbody className="student_practice_table_body">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            { practiceContents?.map((content, index) => (
                <Fragment key={content?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>{ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content?.content) }} /> }</td>
                    <td>{formatDate(content?.start)}</td>
                    <td>{formatDate(content?.end)}</td>
                  </tr>
                </Fragment>
              ))
            }
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticePlan
