import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getFaculty = createAsyncThunk(
  'faculty/facultyById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().faculty
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/faculties/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getFaculties = createAsyncThunk(
  'faculty/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().faculty
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/faculties`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createFaculty = createAsyncThunk(
  'faculty/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().faculty
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/faculties`, data, config)
      data.history.push('/faculties')
      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateFaculty = createAsyncThunk(
  'faculty/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().faculty
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/faculties/${data.id}`, data, config)
      data.history.push('/faculties')
      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteFaculty = createAsyncThunk(
  'faculty/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().faculty
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/faculties/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const facultySlice = createSlice({
  name: 'faculty',
  initialState: {
    loading: 'idle',
    faculty: null,
    faculties: []
  },
  reducers: {},
  extraReducers: {
    // One
    [getFaculty.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getFaculty.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.faculty = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getFaculty.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getFaculties.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getFaculties.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.faculties = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getFaculties.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createFaculty.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createFaculty.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.faculty = action.payload.data
        state.faculties.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createFaculty.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateFaculty.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateFaculty.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.faculty = action.payload.data
        state.faculties = state.faculties.map(faculty => 
          faculty.id === action.payload.data.id ? faculty = action.payload.data : faculty 
        )
        state.currentRequestId = undefined
      }
    },
    [updateFaculty.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteFaculty.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteFaculty.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.faculty = null
        state.faculties = state.faculties.filter(faculty => faculty.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteFaculty.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectFacultyLoading = state => state.faculty.loading
export const selectFaculty = state => state.faculty.faculty
export const selectFaculties = state => state.faculty.faculties

export default facultySlice.reducer
