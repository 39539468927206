import React, { Fragment } from "react"

import cancel from "@icons/cancel.png"

import { getUserFullName } from '@utils/helpers'

// State
import { useDispatch } from "react-redux"
import { deleteUser } from "@features/user/userSlice"

const DeleteUser = ({ deleteModal, setDeleteModal, user }) => {
  const dispatch = useDispatch()

  const handleDeleteUser = (user) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteUser(user?.id))
  }

  return (
    <Fragment>
      {deleteModal && (
        <div className="modal">
          <div className="modal_title">
            Удалить Студент{" "}
            <img
              className="cancel"
              onClick={() => setDeleteModal(!deleteModal)}
              src={cancel}
              alt=""
            />
          </div>

          <div>
            Вы уверены что хотите удалить пользователя {getUserFullName(user)}?
          </div>
          <button
            className="modal_delete_button button"
            onClick={() => handleDeleteUser(user)}
          >
            Удалить
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default DeleteUser
