import React from 'react'

const Pagination = ({ links, getData }) => {
  const firstLink = links[0]
  const lastLink = links[links.length - 1]
  const totalLinksCount = links.length - 1
  const pageLinksCount = links.length - 3
  
  const isPrevNext = (index) => {
    return index === 0 || index === totalLinksCount
  }

    return (
        links.length > 3 ? <ul className="paginated mt-10">
          <li onClick={() => getData(firstLink)} className={!firstLink.url || firstLink.active ? `paginated_item paginated_item_disabled mt-10` : `paginated_item mt-10`}>Назад</li>
          {
            pageLinksCount > 8 ? 
              links?.map((link, index) => (
                !isPrevNext(index) && <li onClick={() => getData(link)} className={!link.url || link.active ? `paginated_item paginated_item_disabled mt-10` : `paginated_item mt-10`}>{link.label}</li>
              )) :
              links?.map((link, index) => (
                !isPrevNext(index) && <li onClick={() => getData(link)} className={!link.url || link.active ? `paginated_item paginated_item_disabled mt-10` : `paginated_item mt-10`}>{link.label}</li>
              ))
          }
          <li onClick={() => getData(lastLink)} className={!lastLink.url || lastLink.active ? `paginated_item paginated_item_disabled mt-10` : `paginated_item mt-10`}>Вперед</li>
        </ul> : null
    )
}

export default Pagination
