import React, { useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

import PracticeInfo from '@features/practice/components/PracticeInfo'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeStudentWithPractice, selectPracticeStudent, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'
import { getPracticeBaseReviewQrs, selectPracticeBaseReviews, selectPracticeBaseReviewLoading } from '@features/practice/practiceBaseReviewSlice'

const PracticeBaseHeadQr = ({ match }) => {
  const dispatch = useDispatch()
  const practiceBaseReviews = useSelector(selectPracticeBaseReviews)
  const practiceStudent = useSelector(selectPracticeStudent)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const loading = useSelector(selectPracticeBaseReviewLoading)

  useEffect(() => {
    dispatch(getPracticeStudentWithPractice(match.params.practiceStudentRandom)).then(res => {
      if (!res.payload) {
        return
      }

      dispatch(getPracticeBaseReviewQrs(res.payload.data.id))
    })
  }, [])

  return (
    loading === "pending" ? <Spinner /> : 
      <div>
        <PracticeInfo practice={practiceStudent?.practice} student={practiceStudent?.student} practiceLoading={practiceStudentLoading} studentLoading={practiceStudentLoading} />
        { practiceBaseReviews?.map(practiceBaseReview => (
          practiceBaseReview.signature ? 
          <div key={practiceBaseReview.id} className="qr_signature">Руководитель практики на базе "{`${practiceBaseReview.practice_base_user.baseUser.base.name_ru}`}" {`${practiceBaseReview.practice_base_user.baseUser.user.last_name_ru} ${practiceBaseReview.practice_base_user.baseUser.user.first_name_ru} поставил(а) подпись`}</div> : 
          <div key={practiceBaseReview.id} className="qr_signature">Член комиссии {`${practiceBaseReview.practice_base_user.baseUser.user.last_name_ru} ${practiceBaseReview.practice_base_user.baseUser.user.first_name_ru} поставил(а) подпись`} НЕ поставил(а) подпись</div>
        )) }
      </div>
  )
}

export default PracticeBaseHeadQr
