import { cloneElement } from 'react'
import { Route, Redirect } from 'react-router-dom'

import NotFound from '@components/layout/NotFound'

import { isNameNull } from '@utils/helpers'

// State
import { useSelector } from 'react-redux'
import { selectUser } from '@features/auth/authSlice'

import { is } from '@utils/helpers'

const ChairHeadRoute = ({ children, ...rest }) => {
  const user = useSelector(selectUser)

  if (isNameNull(user)) {
    return <Redirect to="/info" />
  } 

  return (
    <Route
      {...rest}
      render={(props) =>
        is("headOfChair", user) ? (
          cloneElement(children, { ...props })
        ) : (
          <NotFound />
        )
      }
    />
  )
}

export default ChairHeadRoute
