import React, { useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createChairTeacher, selectTeacherLoading } from '@features/teacher/teacherSlice'
import { getTeacherUser, selectTeacherUser } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const CreateTeacher = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const teacherLoading = useSelector(selectTeacherLoading)
  const user = useSelector(selectUser)
  const teacherUser = useSelector(selectTeacherUser)

  const { register, handleSubmit, reset, errors } = useForm()

  useEffect(() => {
    dispatch(getTeacherUser(user.id))
  }, [user])

  const onSubmit = (data) => {
    const validData = {
      ...data,
      chair_id: teacherUser.chair.id,
      history
    }
    dispatch(createChairTeacher(validData))
  }

  return (
    teacherLoading === "pending" ? <Spinner />  : <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Преподаватель</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            E-mail
            <Error name={errors.email} />
          </label>
          <input type="email" id="email" name="email" ref={register(required)} />

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/teachers">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateTeacher
