import React, { Fragment } from "react"

import cancel from "@icons/cancel.png"

// State
import { useDispatch } from "react-redux"
import { deleteTeacher } from "@features/teacher/teacherSlice"

const DeleteTeacher = ({ deleteModal, setDeleteModal, teacher }) => {
  const dispatch = useDispatch()

  const handleDeleteTeacher = (teacher) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteTeacher(teacher?.id))
  }

  return (
    <Fragment>
      {deleteModal && (
        <div className="modal">
          <div className="modal_title">
            Удалить Студент{" "}
            <img
              className="cancel"
              onClick={() => setDeleteModal(!deleteModal)}
              src={cancel}
              alt=""
            />
          </div>

          <div>
            Вы уверены что хотите удалить пользователя?
          </div>
          <button
            className="button modal_delete_button"
            onClick={() => handleDeleteTeacher(teacher)}
          >
            Удалить
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default DeleteTeacher
