import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice pdf requests
export const getPracticePdf = createAsyncThunk(
  'practicePdf/practicePdfById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practicePdf
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practices/${data.practiceId}/students/${data.studentId}/pdf-link`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticePdf = createAsyncThunk(
  'practicePdf/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practicePdf
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practices/create-pdf`, data, config)

      dispatch(createAlert({
        message: "Отчет сформирован",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practicePdfSlice = createSlice({
  name: 'practicePdf',
  initialState: {
    loading: 'idle',
    practicePdf: null
  },
  reducers: {},
  extraReducers: {
    // *** Practice Comission reducers
    // One
    [getPracticePdf.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticePdf.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practicePdf = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticePdf.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createPracticePdf.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticePdf.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practicePdf = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [createPracticePdf.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticePdfLoading = state => state.practicePdf.loading
export const selectPracticePdf = state => state.practicePdf.practicePdf

export default practicePdfSlice.reducer
