import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import PracticeStatsItem from '@features/practice/components/PracticeStatsItem'
import PracticeStatsReview from '@features/practice/components/PracticeStatsReview'
import NotFound from '@components/layout/NotFound'
import StudentStats from '@features/practice/student/StudentStats'

import { is } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPractice, getPracticeStats, selectPracticeStats, selectPractice, selectPracticeLoading } from '@features/practice/practiceSlice'
/* import { getPracticeStudents, selectPracticeStudents, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice' */
import { selectUser } from '@features/auth/authSlice'

const PracticeStats = ({ match }) => {
  const [reviewModal, setReviewModal] = useState(false)
  const [review, setReview] = useState('')
  const [reviewName, setReviewName] = useState('')
  const [selectedStudent, setSelectedStudent] = useState(null)
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const practice = useSelector(selectPractice)
  const practiceLoading = useSelector(selectPracticeLoading)
/*   const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const practiceStudents = useSelector(selectPracticeStudents) */
  const practiceId = match.params.practiceId
  const practiceStats = useSelector(selectPracticeStats)

  useEffect(() => {
    dispatch(getPractice(practiceId)).then(res => {
      dispatch(getPracticeStats(practiceId))
    })
  }, [dispatch])

  return (
    practiceLoading === "pending" ? <Spinner /> : (<Fragment>

    {reviewModal && <PracticeStatsReview review={review} reviewModal={reviewModal} reviewName={reviewName} setReviewModal={setReviewModal} />}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Практиканты "{practice?.name}"</div>
        </div>

        {<div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Оценки</th>
              <th>План</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {practiceStats?.map((practiceStat, index) => (
              <tr key={practiceStat?.id}>
                <td>{++index}</td>
                <td>{`${practiceStat?.student.user.first_name_ru} ${practiceStat?.student.user.last_name_ru}`}</td>
                <td>
                  <PracticeStatsItem practiceStat={practiceStat} setReview={setReview} setReviewModal={setReviewModal} setReviewName={setReviewName} />
                </td>
                <td><Link to={`/chair-practices/${practiceId}/students/${practiceStat?.student.uuid}/plan`}>Дневник-отчет</Link></td>
              </tr>
            ))}
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticeStats
