import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getGroup = createAsyncThunk(
  'group/groupById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/groups/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getGroups = createAsyncThunk(
  'group/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/groups`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedGroups = createAsyncThunk(
  'group/paginate',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairGroups = createAsyncThunk(
  'group/chairGrouplist',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/groups/chair/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedChairGroups = createAsyncThunk(
  'group/paginatedChairGroups',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createGroup = createAsyncThunk(
  'group/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/groups`, data, config)
      data.history.push('/groups')
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateGroup = createAsyncThunk(
  'group/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/groups/${data.id}`, data, config)
      data.history.push('/groups')
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteGroup = createAsyncThunk(
  'group/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().group
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/groups/${data}`, config)
      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const groupSlice = createSlice({
  name: 'group',
  initialState: {
    loading: 'idle',
    group: null,
    groups: []
  },
  reducers: {},
  extraReducers: {
    // One
    [getGroup.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getGroup.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.group = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getGroup.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getGroups.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getGroups.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.groups = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getGroups.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated list
    [getPaginatedGroups.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedGroups.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.groups = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedGroups.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Chair group List
    [getChairGroups.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairGroups.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.groups = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairGroups.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated chair group List
    [getPaginatedChairGroups.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedChairGroups.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.groups = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedChairGroups.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createGroup.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createGroup.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.group = action.payload.data
        state.groups.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createGroup.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateGroup.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateGroup.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.group = action.payload.data
        state.groups = state.groups.map(group => (
          group.id === action.payload.data.id ? group = action.payload.data : group
        ))
        state.currentRequestId = undefined
      }
    },
    [updateGroup.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteGroup.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteGroup.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.group = null
        state.groups = state.groups.filter(group => group.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteGroup.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectGroupLoading = state => state.group.loading
export const selectGroup = state => state.group.group
export const selectGroups = state => state.group.groups

export default groupSlice.reducer
