import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getAcademicYear, updateAcademicYear, selectAcademicYear, selectAcademicYearLoading } from '@features/academic-year/academicYearSlice'

const UpdateAcademicYear = ({ match }) => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history =  useHistory()
  const dispatch = useDispatch()
  const academicYear = useSelector(selectAcademicYear)
  const academicYearLoading = useSelector(selectAcademicYearLoading)

  useEffect(() => {
    dispatch(getAcademicYear(match.params.academicYearId))
  }, [dispatch, match.params.academicYearId])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      id: academicYear.id,
      history
    }

    dispatch(updateAcademicYear(validData))
  }

  return academicYearLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Обновить Учебный год</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="start">
            Начало
            <Error name={errors.start} />
          </label>
          <input type="number" id="start" defaultValue={academicYear?.start} name="start" ref={register(required)} />

          <label htmlFor="end">
            Окончание
            <Error name={errors.end} />
          </label>
          <input type="number" id="end" defaultValue={academicYear?.end} name="end" ref={register(required)} />

          <button className="button">Изменить</button>

          <Link className="crud_back" to="/academic-years">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default UpdateAcademicYear
