import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { createFaculty, selectFacultyLoading } from '@features/faculty/facultySlice'

const CreateFaculty = () => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(selectFacultyLoading)

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }
    dispatch(createFaculty(validData))
  }

  return loading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Факультет</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name_ru">
            Название на русском
            <Error name={errors.name_ru} />
          </label>
          <input type="text" id="name_ru" name="name_ru" ref={register(required)} />

          <label htmlFor="name_kz">
            Название на казахском
            <Error name={errors.name_kz} />
          </label>
          <input type="text" id="name_kz" name="name_kz" ref={register(required)} />

          <label htmlFor="name_en">
            Название на английском
            <Error name={errors.name_en} />
          </label>
          <input type="text" id="name_en" name="name_en" ref={register(required)} />

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/faculties">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateFaculty
