import React, { useState } from 'react'

const AccordionItem = ({ title, children }) => {
  const [active, setActive] = useState(false)

  const handleState = () => {
    setActive(!active)
  }

  return (
    <li className="accordion_item shadow b-rad-5 mt-10">
      <div className="accordion_item_heading" onClick={() => handleState()}>
        <p>{ title }</p>
        <span className="accordion_item_state">{ active ? `-` : `+` }</span>
      </div>
      { active && <div className="accordion_item_body">
        <div className="accordion_item_content"><strong>{ children }</strong></div>
      </div> }
    </li>
  )
}

export default AccordionItem
