import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice students requests
export const getPracticeStudent = createAsyncThunk(
  'practiceStudent/practiceStudentById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-students/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStudentWithPractice = createAsyncThunk(
  'practiceStudent/practiceStudentWithPractice',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-students/practice/${data}`)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStudents = createAsyncThunk(
  'practiceStudent/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-students/${data}/students`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeStudentsByChair = createAsyncThunk(
  'practiceStudent/listByChair',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-students/${data.practiceId}/students/chair/${data.chairId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeStudent = createAsyncThunk(
  'practiceStudent/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-students/${data.practice_id}/students`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeStudent = createAsyncThunk(
  'practiceStudent/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceStudent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-students/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceStudentSlice = createSlice({
  name: 'practiceStudent',
  initialState: {
    loading: 'idle',
    practiceStudent: null,
    practiceStudents: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Student reducers
    // One
    [getPracticeStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudent = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudentWithPractice.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudentWithPractice.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudent = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudentWithPractice.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeStudents.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudents = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeStudentsByChair.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeStudentsByChair.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudents = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeStudentsByChair.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudent = action.payload.data
        state.practiceStudents.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceStudent = null
        state.practiceStudents = state.practiceStudents.filter(practiceStudent => practiceStudent.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticeStudentLoading = state => state.practiceStudent.loading
export const selectPracticeStudentFilterLoading = state => state.practiceStudent.filterLoading
export const selectPracticeStudent = state => state.practiceStudent.practiceStudent
export const selectPracticeStudents = state => state.practiceStudent.practiceStudents

export default practiceStudentSlice.reducer
