import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'

// Icons
import trash from "@icons/trash.png"

import DeleteUser from "./DeleteUser"

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from "react-redux"
import { getPracticeChairLeaders, selectUsers, selectUserLoading, filterUsers, selectFilterLoading } from "@features/user/userSlice"

const User = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [filtered, setFiltered] = useState(false)

  const { register, handleSubmit, reset } = useForm()

  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const userLoading = useSelector(selectUserLoading)
  const filterLoading = useSelector(selectFilterLoading)

  useEffect(() => {
    dispatch(getPracticeChairLeaders())
  }, [dispatch])

  const handleDeleteForm = (user) => {
    setDeleteModal(!deleteModal)
    setSelectedUser(user)
  }

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      role: "practiceChairLeader"
    }

    dispatch(filterUsers(validData))
    setFiltered(true)
  }

  const handleResetFilter = () => {
    setFiltered(false)
    dispatch(getPracticeChairLeaders())
  }

  return userLoading === "pending" ? (
      <Spinner />
  ) : (
    <Fragment>
      {deleteModal && (
        <DeleteUser
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          user={selectedUser}
        />
      )}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Руководители практик</div>
          <Link to="/practice-chair-leaders/create"><button className="table_create button">
            + Добавить
          </button></Link>
        </div>

        <form className="filter_block mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="filter_block_search">
            <div className="filter_block_group">
              <label htmlFor="email">
                Почта
              </label>
              <input type="email" id="email" name="email" ref={register} />
            </div>

            <div className="filter_block_group">
              <label htmlFor="last_name">
                Фамилия
              </label>
              <input id="last_name" name="last_name" ref={register} />
            </div>
          </div>

          <div className="filter_block_bottom mt-10">
            <button className="button">Фильтровать</button>
            { filtered && <div className="filter_button reset_filter_button" onClick={() => handleResetFilter()}>Сбросить фильтр</div> }
          </div>
        </form>

        { filterLoading === "pending" ? (<Spinner />) :
          (<div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>E-mail</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {users?.slice(0).reverse().map((user, index, users) => (
              <tr key={user?.id}>
                <td>{++index}</td>
                <td>{user?.last_name_ru + " " + user?.first_name_ru}</td>
                <td>{user?.email}</td>
                <td>
                  <img
                    onClick={() => handleDeleteForm(user)}
                    className="admin_icons"
                    src={trash}
                    alt=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table></div>)
        }
      </div>
    </Fragment>
  )
}

export default User
