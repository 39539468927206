import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice students requests
export const getStudent = createAsyncThunk(
  'student/studentById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getStudentWithoutAuth = createAsyncThunk(
  'student/studentWithoutAuth',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    try {
      const response = await axios.get(`${url}/api/students/${data}/withoutAuth`, config)
      return response.data
    } catch (error) {
      data.history.push("/404")
      throw error
    }
  }
)

export const getStudents = createAsyncThunk(
  'student/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getChairStudents = createAsyncThunk(
  'student/chairStudentList',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students/chair/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPaginatedChairStudents = createAsyncThunk(
  'student/paginate',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createStudent = createAsyncThunk(
  'student/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/students`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createChairStudent = createAsyncThunk(
  'student/createChairStudent',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/students/chair`, data, config)
      data.history.push('/students')

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteStudent = createAsyncThunk(
  'student/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().student
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/students/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const filterStudents = createAsyncThunk(
  'student/filter',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().student
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students/filter?firstName=${data.first_name}&lastName=${data.last_name}&middleName=${data.middle_name}&groupId=${data.group_id || 'none'}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const filterChairStudents = createAsyncThunk(
  'student/chairFilter',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, filterLoading } = getState().student
    if (filterLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/students/chair/${data.id}/filter?firstName=${data.first_name}&lastName=${data.last_name}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const studentSlice = createSlice({
  name: 'student',
  initialState: {
    loading: 'idle',
    filterLoading: 'idle',
    student: null,
    students: [],
    filteredStudents: []
  },
  reducers: {
    resetFilteredStudents: (state, action) => {
      state.filterLoading = 'pending'
      state.filteredStudents = []
      state.filterLoading = 'idle'
    }
  },
  extraReducers: {
    // *** Practice Student reducers
    // One
    [getStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.student = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Without Auth
    [getStudentWithoutAuth.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudentWithoutAuth.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.student = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudentWithoutAuth.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getStudents.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.students = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    
    // Chair Student List
    [getChairStudents.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getChairStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.students = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getChairStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Paginated chair Student List
    [getPaginatedChairStudents.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPaginatedChairStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.students = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPaginatedChairStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.student = action.payload.data
        state.students.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create chair student
    [createChairStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createChairStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.student = action.payload.data
        state.students.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createChairStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteStudent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteStudent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.student = null
        state.students = state.students.filter(student => student.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteStudent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Filter
    [filterStudents.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [filterStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.filteredStudents = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [filterStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Filter
    [filterChairStudents.pending]: (state, action) => {
      if (state.filterLoading === 'idle') {
        state.filterLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [filterChairStudents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.students = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [filterChairStudents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.filterLoading === 'pending' && state.currentRequestId === requestId) {
        state.filterLoading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const { resetFilteredStudents } = studentSlice.actions

export const selectStudentLoading = state => state.student.loading
export const selectFilterStudentLoading = state => state.student.filterLoading
export const selectStudent = state => state.student.student
export const selectStudents = state => state.student.students
export const selectFilteredStudents = state => state.student.filteredStudents

export default studentSlice.reducer
