import React, { Fragment } from 'react'

import { getRoleName } from '@utils/helpers'

const DashboardRoles = ({ user }) => {
  return (
    <Fragment>
      <div className="line_grey"></div>

      <div className="user_private_block">
        <div className="user_private_title">Должность:</div>
        <div className="user_private_info">
          {user?.roles.map(role => (
            <p key={role.id}>{getRoleName(role)}</p>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default DashboardRoles
