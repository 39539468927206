import React, { Fragment, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { isNameNull, is } from '@utils/helpers'

import Spinner from '@components/layout/Spinner'

import AdminDashboard from './AdminDashboard'
import MethodCenterUserDashboard from './MethodCenterUserDashboard'
import PracticeBaseLeaderDashboard from './PracticeBaseLeaderDashboard'
import StudentDashboard from './StudentDashboard'
import TeacherDashboard from './TeacherDashboard'
import DeanDashboard from './DeanDashboard'
import ChairHeadDashboard from './ChairHeadDashboard'

// State
import { useSelector } from 'react-redux'
import { selectUser as selectAuthUser, selectIsAuthenticated } from '@features/auth/authSlice'
import { selectFilterLoading } from '@features/user/userSlice'

const Dashboard = () => {
  const user = useSelector(selectAuthUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const filterLoading = useSelector(selectFilterLoading)

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  if(isNameNull(user)) {
    return <Redirect to="/info" />
  } 

  return (
    <Fragment>
      { is('admin', user) && <AdminDashboard user={user} />  ||
       is('dean', user) && <DeanDashboard user={user} />  ||
       is('headOfChair', user) && <ChairHeadDashboard user={user} /> ||
       is('teacher', user) && <TeacherDashboard user={user} /> ||
       is('practiceBaseLeader', user) && <PracticeBaseLeaderDashboard user={user} /> || 
       is('student', user) && <StudentDashboard user={user} /> ||
       is('methodCenterUser', user) && <MethodCenterUserDashboard user={user} /> }
    </Fragment>
  )
}

export default Dashboard
