import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deletePracticeSchedule } from '@features/practice/practiceScheduleSlice'

const DeletePracticeSchedule = ({ deleteScheduleModal, setDeleteScheduleModal, schedule }) => {
  const dispatch = useDispatch()

  const handleDeleteSchedule = (scheduleId) => {
    setDeleteScheduleModal(!deleteScheduleModal)
    dispatch(deletePracticeSchedule(scheduleId))
  }

  return (
    <Fragment>
      {
        deleteScheduleModal &&
          <div className="modal">
            <div className="modal_title">Удалить план-график <img className="cancel" 
            onClick={() => setDeleteScheduleModal(!deleteScheduleModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить?</div>
            <button className="button modal_delete_button" onClick={() => handleDeleteSchedule(schedule.uuid)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeletePracticeSchedule
