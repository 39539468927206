import React, { Fragment, useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getStatistics, selectStatisticsLoading, selectStatistics } from '@features/statistics/statisticsSlice'

const Statistics = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectStatisticsLoading)
  const statistics = useSelector(selectStatistics)

  useEffect(() => {
    dispatch(getStatistics())
  }, [dispatch])

  return loading === 'pending' ? (<Spinner />) : (
    <Fragment>
      <div className="statistics">
        <h2>Общая статистика</h2>
        <ul>
          <li><p>Количество практик:</p> <strong>{ statistics?.practices }</strong></li>

          <li><p> Количество дневников:</p> <strong>{ statistics?.practiceStudents }</strong></li>

          <li><p> Количество план-графиков:</p> <strong>{ statistics?.practiceSchedules }</strong></li>
          <li><p> Количество комментариев к план-графикам:</p> <strong>{ statistics?.practiceScheduleComments }</strong></li>

          <li><p> Количество содержании:</p> <strong>{ statistics?.practiceContents }</strong></li>
          <li><p> Количество комментариев к содержаниям:</p> <strong>{ statistics?.practiceContentComments }</strong></li>

          <li><p> Количество отзывов руководителей практик на базе:</p> <strong>{ statistics?.practiceBaseReviews }</strong></li>
          <li><p> Количество отзывов руководителей практик на кафедре:</p> <strong>{ statistics?.practiceChairReviews }</strong></li>
          <li><p> Количество отчетов студентов:</p> <strong>{ statistics?.practiceStudentReviews }</strong></li>

          <li><p> Количество подписей руководителей практик на базе:</p> <strong>{ statistics?.practiceBaseSignatures }</strong></li>
          <li><p> Количество подписей заведующих кафедр:</p> <strong>{ statistics?.practiceHeadOfChairSignatures }</strong></li>
          <li><p> Количество подписей руководителей практик на кафедре:</p> <strong>{ statistics?.practiceChairLeaderSignatures }</strong></li>
          <li><p> Количество подписей комиссии практик:</p> <strong>{ statistics?.practiceComissionSignatures }</strong></li>

          <li><p> Количество комиссий:</p> <strong>{ statistics?.practiceComissions }</strong></li>
          <li><p> Количество сформированных отчетов в формате PDF:</p> <strong>{ statistics?.practicePdfs }</strong></li>
        </ul>
      </div>
    </Fragment>
  )
}

export default Statistics