import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deletePracticeComission } from '@features/practice/practiceComissionSlice'

const DeleteComission = ({ deleteModal, setDeleteModal, practiceComission, practiceId }) => {
  const dispatch = useDispatch()
  
  const handleDeletePractice = (practiceComission) => {
    const data = {
      teacher_id: practiceComission.id,
      practice_id: practiceId
    }

    setDeleteModal(!deleteModal)
    dispatch(deletePracticeComission(data))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Комиссию из Практики <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить Комиссию {`${practiceComission.user.last_name_ru} ${practiceComission.user.first_name_ru}`} из практики</div>
            <button className="modal_delete_button button" onClick={() => handleDeletePractice(practiceComission)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteComission
