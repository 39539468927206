import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { createGroup, selectGroupLoading } from '@features/group/groupSlice'
import { getChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'

const CreateGroup = () => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const chairs = useSelector(selectChairs)
  const chairLoading = useSelector(selectChairLoading)
  const groupLoading = useSelector(selectGroupLoading)

  useEffect(() => {
    dispatch(getChairs())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }

    dispatch(createGroup(validData))
  }

  return groupLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новая Группа</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name">
            Название
            <Error name={errors.name} />
          </label>
          <input type="text" id="name" name="name" ref={register(required)} />

          <label htmlFor="course">
            Курс
            <Error name={errors.course} />
          </label>
          <input type="number" id="course" name="course" ref={register(required)} />

          <label htmlFor="chair_id">Выберите Кафедру <Error name={errors.chair_id} /></label>
          <select name="chair_id" id="chair_id" ref={register(required)}>
          {chairLoading === "pending" ? (
            <option>Загружаем...</option>
          ) : chairs?.map(chair => (
            <option key={chair.id} value={chair.id}>{chair.name_ru}</option>
          ))}
          </select>

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/groups">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateGroup
