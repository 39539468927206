import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import DeleteComission from './DeleteComission.js'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import Spinner from '@components/layout/Spinner'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPractice, selectPractice, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getPracticeComissions, selectPracticeComissions, selectPracticeComissionLoading } from '@features/practice/practiceComissionSlice'

const PracticeComission = ({ match }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const dispatch = useDispatch()
  const practice = useSelector(selectPractice)
  const practiceLoading = useSelector(selectPracticeLoading)
  const practiceComissionLoading = useSelector(selectPracticeComissionLoading)
  const practiceComissions = useSelector(selectPracticeComissions)
  const practiceId = match.params.practiceId

  const handleDeleteForm = (practiceComission) => {
    setDeleteModal(!deleteModal)
    setSelectedStudent(practiceComission)
  }

  useEffect(() => {
    dispatch(getPractice(practiceId))
  }, [practiceId])

  useEffect(() => {
    dispatch(getPracticeComissions(practiceId))
  }, [practiceId])

  return (
    practiceLoading === "pending" ? <Spinner /> : (<Fragment>
      {deleteModal && <DeleteComission deleteModal={deleteModal} setDeleteModal={setDeleteModal} practiceComission={selectedStudent} practiceId={practiceId} />}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Комиссия "{practice?.name}"</div>
          <Link to={`/chair-head-practices/${practiceId}/comissions/add`}><button className="table_create button">+ Добавить</button></Link>
          <Link to={'/chair-head-practices'} className="table_create link">Список практик</Link>
        </div>

        {practiceComissionLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {practiceComissions?.map((practiceComission, index) => (
              <tr key={practiceComission?.id}>
                <td>{++index}</td>
                <td>{`${practiceComission?.user.first_name_ru} ${practiceComission?.user.last_name_ru}`}</td>
                <td>
                  <img onClick={() => handleDeleteForm(practiceComission)} className="admin_icons" src={trash} alt=""/>
                </td>
              </tr>
            ))}
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticeComission
