import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice comissions requests
export const getPracticeComission = createAsyncThunk(
  'practiceComission/practiceComissionById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-comissions/${data.teacherId}/practices/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeComissions = createAsyncThunk(
  'practiceComission/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-comissions/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeComissionQrs = createAsyncThunk(
  'practiceComission/qrs',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-comissions/qr/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeComission = createAsyncThunk(
  'practiceComission/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-comissions/${data.practice_id}`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeComission = createAsyncThunk(
  'practiceComission/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-comissions/${data.uuid}`, data, config)

      dispatch(createAlert({
        message: "Подпись поставлен",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeComission = createAsyncThunk(
  'practiceComission/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceComission
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-comissions/${data.teacher_id}/practice/${data.practice_id}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceComissionSlice = createSlice({
  name: 'practiceComission',
  initialState: {
    loading: 'idle',
    practiceComission: null,
    practiceComissions: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Comission reducers
    // One
    [getPracticeComission.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeComission.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComission = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeComission.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeComissions.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeComissions.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComissions = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeComissions.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getPracticeComissionQrs.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeComissionQrs.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComissions = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeComissionQrs.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createPracticeComission.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeComission.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComission = action.payload.data
        state.practiceComissions.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeComission.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeComission.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeComission.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComission = action.payload.data
        state.practiceComissions = state.practiceComissions.map(practiceComission =>
          practiceComission.uuid === action.payload.data.uuid ? practiceComission = action.payload.data : practiceComission
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeComission.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeComission.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeComission.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceComission = null
        state.practiceComissions = state.practiceComissions.filter(practiceComission => practiceComission.id !== action.payload.teacher_id)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeComission.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticeComissionLoading = state => state.practiceComission.loading
export const selectPracticeComissionFilterLoading = state => state.practiceComission.filterLoading
export const selectPracticeComission = state => state.practiceComission.practiceComission
export const selectPracticeComissions = state => state.practiceComission.practiceComissions

export default practiceComissionSlice.reducer
