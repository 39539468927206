import React, { useEffect } from 'react'

import DashboardRoles from './components/DashboardRoles'
import DashboardAvatar from './components/DashboardAvatar'
import DashboardUserName from './components/DashboardUserName'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getBaseUser, selectBaseUser } from '@features/user/userSlice'

const PracticeBaseLeaderDashboard = ({ user }) => {
  const dispatch = useDispatch()
  const baseUser = useSelector(selectBaseUser)

  useEffect(() => {
    dispatch(getBaseUser(user?.id))
  }, [dispatch])

  return (
    <div>
          <div className="mt-15">
          <div className="user">
            <DashboardAvatar user={user} />

            <div className="user_info">
              <DashboardUserName user={user} />

              <span className="user_online">В сети</span>

              <div className="user_private">
                <div className="user_private_heading">Личная информация:</div>

                <div className="user_private_block">
                  <div className="user_private_title">База Практики:</div>
                  <div className="user_private_info">
                    "{baseUser?.base.name_ru}"
                  </div>
                </div>

                <DashboardRoles user={user} />
              </div>
            </div>
          </div>
    </div>
    </div>
  )
}

export default PracticeBaseLeaderDashboard
