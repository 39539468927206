import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

export const getAcademicYear = createAsyncThunk(
  'academicYear/academicYearById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().academicYear
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/academic-years/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getAcademicYears = createAsyncThunk(
  'academicYear/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().academicYear
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/academic-years`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createAcademicYear = createAsyncThunk(
  'academicYear/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().academicYear
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/academic-years`, data, config)
      data.history.push('/academic-years')

      dispatch(createAlert({
        message: 'Данные сохранены',
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updateAcademicYear = createAsyncThunk(
  'academicYear/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().academicYear
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/academic-years/${data.id}`, data, config)
      data.history.push('/academic-years')

      dispatch(createAlert({
        message: 'Данные изменены',
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deleteAcademicYear = createAsyncThunk(
  'academicYear/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().academicYear
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/academic-years/${data}`, config)

      dispatch(createAlert({
        message: 'Данные удалены',
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error,
        type: 'fail'
      }))
      throw error
    }
  }
)

export const academicYearSlice = createSlice({
  name: 'academicYear',
  initialState: {
    loading: 'idle',
    academicYear: null,
    academicYears: []
  },
  reducers: {},
  extraReducers: {
    // One
    [getAcademicYear.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getAcademicYear.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.academicYear = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getAcademicYear.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // List
    [getAcademicYears.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getAcademicYears.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.academicYears = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getAcademicYears.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // create
    [createAcademicYear.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createAcademicYear.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.academicYear = action.payload.data
        state.academicYears.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createAcademicYear.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updateAcademicYear.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updateAcademicYear.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.academicYear = action.payload.data
        state.academicYears = state.academicYears.map(academicYear => 
          academicYear.id === action.payload.data.id ? academicYear = action.payload.data : academicYear 
        )
        state.currentRequestId = undefined
      }
    },
    [updateAcademicYear.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deleteAcademicYear.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deleteAcademicYear.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.academicYear = null
        state.academicYears = state.academicYears.filter(academicYear => academicYear.id !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deleteAcademicYear.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectAcademicYearLoading = state => state.academicYear.loading
export const selectAcademicYear = state => state.academicYear.academicYear
export const selectAcademicYears = state => state.academicYear.academicYears

export default academicYearSlice.reducer
