import React from "react"

import Alert from './Alert'

// State
import { useSelector } from "react-redux"
import { selectAlerts } from "@features/alert/alertSlice"

const Alerts = () => {
  const alerts = useSelector(selectAlerts)
  
  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map(alert => (
      <Alert key={alert.id} alert={alert} />
    ))
  )
}

export default Alerts
