import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import Spinner from "@components/layout/Spinner";
import PracticeInfo from "@features/practice/components/PracticeInfo";
import CreateComment from "./CreateComment";
import UpdateComment from "./UpdateComment";

import { formatDate } from "@utils/helpers";
import { url } from '@utils/api'

// Icons
import edit from "@icons/edit.png";
import cancel from "@icons/cancel.png";

// State
import { useSelector, useDispatch } from "react-redux";
import {
  getPractice,
  selectPractice,
  getPracticeWithUser,
  selectPracticeLoading
} from "@features/practice/practiceSlice";
import {
  getPracticeSchedules,
  selectPracticeSchedules,
  selectPracticeScheduleLoading,
  deletePracticeScheduleComment,
  selectPracticeScheduleCommentLoading,
} from "@features/practice/practiceScheduleSlice";
import {
  getPracticeContents,
  selectPracticeContents,
  selectPracticeContentLoading,
  deletePracticeContentComment,
  selectPracticeContentCommentLoading,
} from "@features/practice/practiceContentSlice";
import {
  getStudent,
  selectStudent,
  selectStudentLoading,
} from "@features/student/studentSlice";
import {
  getDeanByChair,
  selectDean,
  selectDeanLoading,
} from "@features/dean/deanSlice";
import {
  addPracticeChairSignature,
  getPracticeChairReview,
  selectPracticeChairReview,
  selectPracticeChairReviewLoading,
} from "@features/practice/practiceChairReviewSlice";
import {
  getPracticePdf,
  selectPracticePdf,
  selectPracticePdfLoading
} from '@features/practice/practicePdfSlice';

const PracticePlan = ({ match }) => {
  const [contentSelected, setContentSelected] = useState(false);
  const [content, setContent] = useState(null);
  const [scheduleSelected, setScheduleSelected] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [updateContentSelected, setUpdateContentSelected] = useState(false);
  const [updateScheduleSelected, setUpdateScheduleSelected] = useState(false);
  const [reportLoading, setReportLoading] = useState('pending');
  const dispatch = useDispatch();
  const practice = useSelector(selectPractice);
  const dean = useSelector(selectDean);
  const student = useSelector(selectStudent);
  const deanLoading = useSelector(selectDeanLoading);
  const practiceLoading = useSelector(selectPracticeLoading);
  const studentLoading = useSelector(selectStudentLoading);
  const practiceSchedules = useSelector(selectPracticeSchedules);
  const practiceScheduleLoading = useSelector(selectPracticeScheduleLoading);
  const practiceContents = useSelector(selectPracticeContents);
  const practiceContentLoading = useSelector(selectPracticeContentLoading);
  const practiceChairReview = useSelector(selectPracticeChairReview);
  const practiceChairReviewLoading = useSelector(
    selectPracticeChairReviewLoading
  );
  const practiceScheduleCommentLoading = useSelector(
    selectPracticeScheduleCommentLoading
  );
  const practiceContentCommentLoading = useSelector(
    selectPracticeContentCommentLoading
  );
  const pdfLoading = useSelector(selectPracticePdfLoading)
  const pdfLink = useSelector(selectPracticePdf)
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    dispatch(getPracticeWithUser(practiceId)).then((res) => {
      dispatch(getDeanByChair(res.payload.data.teacher.chair.id));
    });
    dispatch(getStudent(studentId));
    const data = {
      practiceId,
      studentId,
    };
    dispatch(getPracticeSchedules(data))
    dispatch(getPracticeContents(data))
    dispatch(getPracticeChairReview(data)).then(res => { 
      dispatch(getPracticePdf(data)).then(res => setReportLoading('idle')) 
    })
  }, [dispatch, practiceId, studentId]);

  const handleUpdatePracticeChairReview = () => {
    const data = {
      ...practiceChairReview,
      teacher_signature: 1,
    };
    dispatch(addPracticeChairSignature(data));
  };

  const handleCreateContentComment = (content) => {
    setContentSelected(true);
    setContent(content);
  };

  const handleCreateScheduleComment = (schedule) => {
    setScheduleSelected(true);
    setSchedule(schedule);
  };

  const handleUpdateContentComment = (content) => {
    setUpdateContentSelected(true);
    setContent(content);
  };

  const handleUpdateScheduleComment = (schedule) => {
    setUpdateScheduleSelected(true);
    setSchedule(schedule);
  };

  const handleScheduleCommentDelete = (schedule) => {
    dispatch(deletePracticeScheduleComment(schedule.uuid));
  };

  const handleContentCommentDelete = (content) => {
    dispatch(deletePracticeContentComment(content.uuid));
  };

  const handleCopy = (e) => {
    e.preventDefault()

    return false
  }

  return (
    <Fragment>
      {contentSelected && (
        <CreateComment
          isContent={true}
          selected={contentSelected}
          setSelected={setContentSelected}
          value={content}
        />
      )}
      {scheduleSelected && (
        <CreateComment
          isContent={false}
          selected={scheduleSelected}
          setSelected={setScheduleSelected}
          value={schedule}
        />
      )}
      {updateContentSelected && (
        <UpdateComment
          isContent={true}
          selected={updateContentSelected}
          setSelected={setUpdateContentSelected}
          value={content}
        />
      )}
      {updateScheduleSelected && (
        <UpdateComment
          isContent={false}
          selected={updateScheduleSelected}
          setSelected={setUpdateScheduleSelected}
          value={schedule}
        />
      )}

      <div className="trainee">
        <div className="trainee_title">
          Практика
          <Link
            to={`/chair-practices/${practiceId}/students`}
            className="table_create link link_w447"
          >
            Список практикантов
          </Link>
          {practiceChairReviewLoading === "pending" ? (
            <span className="table_create">Загружаем...</span>
          ) : (
            <button
              className={`${practiceChairReview?.teacher_signature ? "button_disabled table_create button_w563" : "button table_create button_w563" }`}
              onClick={() => handleUpdatePracticeChairReview()}
              disabled={practiceChairReview?.teacher_signature ? true : false}
            >
              {practiceChairReview?.teacher_signature
                ? "Подпись поставлен"
                : "Поставить подпись"}
            </button>
          )}
          <div className="mt-10">
            {reportLoading === 'pending' ? (
              <span>Загружаем...</span>
            ) : (
              pdfLink ? <a target="_blank" rel="noreferrer" download href={`${url}/practice/${practiceId}/${studentId}/${pdfLink}`} className="link">Скачать отчет</a> : <span className="table_create">Отчет не готов</span>
            )}
          </div>
        </div>

        <PracticeInfo
          practice={practice}
          student={student}
          dean={dean}
          practiceLoading={practiceLoading}
          studentLoading={studentLoading}
          deanLoading={deanLoading}
        />

        <div className="trainee_table_title">
          Рабочий план-график производственной практики
        </div>

        {practiceScheduleLoading === "pending" ? (
          "Загружаем..."
        ) : (
          <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Перечень работ, подлежащих выполнению (изучению) в
                  соответствии с рабочей программой производственной практики
                </th>
                <th colSpan="2">Сроки выполнения программы практики</th>
                <th>Примечание</th>
                <th>Комментарии</th>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>Начало</td>
                <td>Завершение</td>
                <td></td>
                <td></td>
              </tr>
            </thead>

            <tbody className="student_practice_table_body">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              {practiceSchedules?.map((schedule, index) => (
                <Fragment key={schedule?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(schedule?.content),
                          }}
                        />
                      }
                    </td>
                    <td>{formatDate(schedule?.start)}</td>
                    <td>{formatDate(schedule?.end)}</td>
                    <td>{schedule?.note}</td>
                    <td>
                      <button
                        onClick={() => handleCreateScheduleComment(schedule)}
                        className="button_add"
                      >
                        +
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      {practiceScheduleCommentLoading === "pending"
                        ? "Загружаем..."
                        : schedule?.practiceScheduleComments.map(
                            (comment, index) => (
                              <Fragment key={comment.id}>
                                <div className="comment">
                                  <div>
                                    {++index}. {comment.comment}
                                  </div>
                                  <div>
                                    <img
                                      src={edit}
                                      onClick={() =>
                                        handleUpdateScheduleComment(comment)
                                      }
                                      alt=""
                                    />
                                    <img
                                      src={cancel}
                                      onClick={() =>
                                        handleScheduleCommentDelete(comment)
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table></div>
        )}

        <div className="trainee_table_title">
          Содержание производственной практики
        </div>

        {practiceContentLoading === "pending" ? (
          "Загружаем..."
        ) : (
          <div className="table_wrapper" onCopy={(e) => handleCopy(e)}><table className="trainee_table_plan">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  Наименование и содержание выполненных (изученых) работ в
                  соответствии с рабочей программой производственной практики за
                  каждый день
                </th>
                <th colSpan="2">Сроки выпол. отдельных тем, работ практики</th>
                <th>Действия</th>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>Начало</td>
                <td>Завершение</td>
                <td></td>
              </tr>
            </thead>

            <tbody className="student_practice_table_body">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              {practiceContents?.map((content, index) => (
                <Fragment key={content?.id}>
                  <tr>
                    <td>{++index}</td>
                    <td>
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(content?.content),
                          }}
                        />
                      }
                    </td>
                    <td>{formatDate(content?.start)}</td>
                    <td>{formatDate(content?.end)}</td>
                    <td>
                      <button
                        onClick={() => handleCreateContentComment(content)}
                        className="button_add"
                      >
                        +
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      {practiceContentCommentLoading === "pending"
                        ? "Загружаем..."
                        : content?.practiceContentComments.map(
                            (comment, index) => (
                              <Fragment key={comment.id}>
                                <div className="comment">
                                  <div>
                                    {++index}. {comment.comment}
                                  </div>
                                  <div>
                                    <img
                                      src={edit}
                                      onClick={() =>
                                        handleUpdateContentComment(comment)
                                      }
                                      alt=""
                                    />
                                    <img
                                      src={cancel}
                                      onClick={() =>
                                        handleContentCommentDelete(comment)
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </Fragment>
                            )
                          )}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table></div>
        )}
      </div>
    </Fragment>
  );
};

export default PracticePlan;
