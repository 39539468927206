import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { createAcademicYear, selectAcademicYearLoading } from '@features/academic-year/academicYearSlice'

const CreateAcademicYear = () => {
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const academicYearLoading = useSelector(selectAcademicYearLoading)

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }

    dispatch(createAcademicYear(validData))
  }

  return academicYearLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Учебный Год</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="start">
            Начало
            <Error name={errors.start} />
          </label>
          <input type="number" id="start" name="start" ref={register(required)} />

          <label htmlFor="end">
            Окончание
            <Error name={errors.end} />
          </label>
          <input type="number" id="end" name="end" ref={register(required)} />

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/academic-years">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateAcademicYear
