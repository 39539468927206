import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createUser, selectUserLoading } from '@features/user/userSlice'
import { getChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'
import { getGroups, selectGroups, selectGroupLoading } from '@features/group/groupSlice'
import { getBases, selectBases, selectBaseLoading } from '@features/base/baseSlice'

const CreateUser = () => {
  const [studentChecked, setStudentChecked] = useState(false)
  const [chairHeadChecked, setChairHeadChecked] = useState(false)
  const [chairPracticeLeaderChecked, setChairPracticeLeaderChecked] = useState(false)
  const [basePracticeLeaderChecked, setBasePracticeLeaderChecked] = useState(false)
  const [deanChecked, setDeanChecked] = useState(false)
  const [teacherChecked, setTeacherChecked] = useState(false)
  const [methodCenterUserChecked, setMethodCenterUserChecked] = useState(false)
  const [adminChecked, setAdminChecked] = useState(false)

  const dispatch = useDispatch()
  const userLoading = useSelector(selectUserLoading)
  const chairs = useSelector(selectChairs)
  const chairLoading = useSelector(selectChairLoading)
  const groups = useSelector(selectGroups)
  const groupLoading = useSelector(selectGroupLoading)
  const bases = useSelector(selectBases)
  const baseLoading = useSelector(selectBaseLoading)

  useEffect(() => {
    dispatch(getChairs())
    dispatch(getGroups())
    dispatch(getBases())
  }, [dispatch])

  const { register, handleSubmit, reset, errors } = useForm()
  const history = useHistory()

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      history
    }

    dispatch(createUser(validData))
  }

  const handleChange = (roleName) => {
    switch (roleName) {
      case "headOfChair":
        setChairHeadChecked(!chairHeadChecked)
        break;
      case "teacher":
        setTeacherChecked(!teacherChecked)
        break;
      case "practiceChairLeader":
        setChairPracticeLeaderChecked(!chairPracticeLeaderChecked)
        break;
      case "practiceBaseLeader":
        setBasePracticeLeaderChecked(!basePracticeLeaderChecked)
        break;
      case "student":
        setStudentChecked(!studentChecked)
        break;
      case "dean":
        setDeanChecked(!deanChecked)
        break;
      case 'methodCenterUser':
        setMethodCenterUserChecked(!methodCenterUserChecked)
        break;
      case "admin":
        setAdminChecked(!adminChecked)
        break;
      default:
        break;
    }
  }

  return userLoading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новый Пользователь</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">
            E-mail
            <Error name={errors.email} />
          </label>
          <input type="email" id="email" name="email" ref={register(required)} />

          <label htmlFor="roles">Выберите Должность <Error name={errors.roles} /></label>
            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="admin" ref={register} disabled={studentChecked || basePracticeLeaderChecked || deanChecked || chairHeadChecked || methodCenterUserChecked || teacherChecked || chairPracticeLeaderChecked} onChange={e => handleChange(e.target.value)} />
              Администратор
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="dean" ref={register} disabled={studentChecked || basePracticeLeaderChecked || chairHeadChecked || methodCenterUserChecked || adminChecked || teacherChecked || chairPracticeLeaderChecked} onChange={e => handleChange(e.target.value)} />
              Декан
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="headOfChair" ref={register} disabled={studentChecked || deanChecked || methodCenterUserChecked || adminChecked} onChange={e => handleChange(e.target.value)} />
              Заведующий кафедрой
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="teacher" ref={register} disabled={studentChecked || methodCenterUserChecked || adminChecked || deanChecked} onChange={e => handleChange(e.target.value)} />
              Преподаватель
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="practiceChairLeader" ref={register} disabled={studentChecked || methodCenterUserChecked || adminChecked || deanChecked} onChange={e => handleChange(e.target.value)} />
              Руководитель практики (на кафедре)
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="practiceBaseLeader" ref={register} disabled={studentChecked || adminChecked || deanChecked || methodCenterUserChecked} onChange={e => handleChange(e.target.value)} />
              Руководитель практики (на базе)
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="methodCenterUser" ref={register} disabled={studentChecked || adminChecked || deanChecked || teacherChecked || chairHeadChecked || chairPracticeLeaderChecked || basePracticeLeaderChecked} onChange={e => handleChange(e.target.value)} />
              Сотрудник УМЦ
            </label>

            <label className="crud_checkbox">
              <input type="checkbox" name="roles[]" defaultValue="student" ref={register} disabled={basePracticeLeaderChecked || adminChecked || deanChecked || teacherChecked || chairHeadChecked || chairPracticeLeaderChecked || methodCenterUserChecked} onChange={e => handleChange(e.target.value)} />
              Студент
            </label>

          { studentChecked && <Fragment><label htmlFor="chair_id">Выберите Группу</label>
          <select name="group_id" id="group_id" ref={register}>
          { groups?.map(group => (
            <option key={group.id} value={group.id}>{group.name}</option>
          ))}
          </select></Fragment> }

          { (deanChecked || chairHeadChecked || teacherChecked || chairPracticeLeaderChecked) && <Fragment><label htmlFor="chair_id">Выберите Кафедру</label>
          <select name="chair_id" id="chair_id" ref={register}>
          { chairs?.map(chair => (
            <option key={chair.id} value={chair.id}>{chair.name_ru}</option>
          ))}
          </select></Fragment> }

          { basePracticeLeaderChecked && <Fragment><label htmlFor="base_id">Выберите Базу Практики</label>
          <select name="base_id" id="base_id" ref={register}>
          { bases?.map(base => (
            <option key={base.id} value={base.id}>{base.name_ru}</option>
          ))}
          </select></Fragment> }

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/users">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreateUser
