import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getStudentUser, selectStudentUser } from '@features/user/userSlice'
import DashboardAvatar from './components/DashboardAvatar'
import DashboardUserName from './components/DashboardUserName'

const StudentDashboard = ({ user }) => {
  const dispatch = useDispatch()
  const studentUser = useSelector(selectStudentUser)

  useEffect(() => {
    dispatch(getStudentUser(user?.id))
  }, [dispatch])

  return (
    <div>
        <div className="mt-15">
          <div className="user">
            <DashboardAvatar user={user} />

            <div className="user_info">
              <DashboardUserName user={user} />

              <span className="user_online">В сети</span>

              <div className="user_private">
                <div className="user_private_heading">Личная информация:</div>

                <div className="user_private_block">
                  <div className="user_private_title">Факультет:</div>
                  <div className="user_private_info">
                    "{studentUser?.group.chair.program.faculty.name_ru}"
                  </div>
                </div>

                <div className="user_private_block">
                  <div className="user_private_title">Образовательная программа:</div>
                  <div className="user_private_info">
                    "{studentUser?.group.chair.program.name_ru}"
                  </div>
                </div>

                <div className="user_private_block">
                  <div className="user_private_title">Кафедра:</div>
                  <div className="user_private_info">
                    "{studentUser?.group.chair.name_ru}"
                  </div>
                </div>

                <div className="user_private_block">
                  <div className="user_private_title">Группа:</div>
                  <div className="user_private_info">
                    "{studentUser?.group.name}"
                  </div>
                </div>

                <div className="user_private_block">
                  <div className="user_private_title">Курс:</div>
                  <div className="user_private_info">{studentUser?.group.course}</div>
                </div>

                <div className="line_grey"></div>

                <div className="user_private_block">
                  <div className="user_private_title">Должность:</div>
                  <div className="user_private_info">
                    Студент
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>
  )
}

export default StudentDashboard
