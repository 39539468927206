import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import DeleteStudent from './DeleteStudent.js'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import Spinner from '@components/layout/Spinner'

// State
import { useDispatch, useSelector } from 'react-redux'
import { getPractice, selectPractice, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getPracticeStudents, selectPracticeStudents, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'

const PracticeStudent = ({ match }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const dispatch = useDispatch()
  const practice = useSelector(selectPractice)
  const practiceLoading = useSelector(selectPracticeLoading)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const practiceStudents = useSelector(selectPracticeStudents)
  const practiceId = match.params.practiceId

  const handleDeleteForm = (practiceStudent) => {
    setDeleteModal(!deleteModal)
    setSelectedStudent(practiceStudent)
  }

  useEffect(() => {
    dispatch(getPractice(practiceId))
  }, [dispatch])

  useEffect(() => {
    dispatch(getPracticeStudents(practiceId))
  }, [dispatch])

  return (
    practiceLoading === "pending" ? <Spinner /> : (<Fragment>
      {deleteModal && <DeleteStudent deleteModal={deleteModal} setDeleteModal={setDeleteModal} practiceStudent={selectedStudent} />}

      <div className="table">
        <div className="table_create_title">
          <div className="table_title">Практиканты "{practice?.name}"</div>
          <Link to={`/chair-practices/${practiceId}/students/add`}><button className="button table_create">+ Добавить</button></Link>
          <Link to={'/chair-practices'} className="table_create link link_w447">Список практик</Link>
        </div>

        {practiceStudentLoading === "pending" ? <Spinner /> : <div className="table_wrapper"><table>
          <thead>
            <tr>
              <th>#</th>
              <th>ФИО</th>
              <th>Группа</th>
              <th>План</th>
              <th>Действия</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {practiceStudents?.map((practiceStudent, index) => (
              <tr key={practiceStudent?.id}>
                <td>{++index}</td>
                <td>{`${practiceStudent?.user.first_name_ru} ${practiceStudent?.user.last_name_ru}`}</td>
                <td>{`${practiceStudent?.student.group.name}`}</td>
                <td><Link to={`/chair-practices/${practiceId}/students/${practiceStudent?.student.uuid}/plan`}>Дневник-отчет</Link></td>
                <td>
                  <Link to={`/chair-practices/${practiceId}/students/${practiceStudent?.student.uuid}/review`}><img className="admin_icons" src={edit} alt=""/></Link>
                  <img onClick={() => handleDeleteForm(practiceStudent)} className="admin_icons" src={trash} alt=""/>
                </td>
              </tr>
            ))}
          </tbody>
        </table></div>}
      </div>
    </Fragment>)
  )
}

export default PracticeStudent
