import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'

// Icons
import cancel from '@icons/cancel.png'

import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useDispatch, useSelector } from 'react-redux'
import { createPracticeContentComment, selectPracticeContentCommentLoading } from '@features/practice/practiceContentSlice'
import { createPracticeScheduleComment, selectPracticeScheduleCommentLoading } from '@features/practice/practiceScheduleSlice'

const CreateComment = ({ isContent, setSelected, value }) => {
  const { handleSubmit, register, errors } = useForm()
  const dispatch = useDispatch()
  const practiceContentCommentLoading = useSelector(selectPracticeContentCommentLoading)
  const practiceScheduleCommentLoading = useSelector(selectPracticeScheduleCommentLoading)

  const onSubmit = (data) => {
    const validData = {
      ...data,
      uuid: value.uuid
    }

    if (isContent) {
      dispatch(createPracticeContentComment(validData)).then(res => setSelected(false))
    } else {
      dispatch(createPracticeScheduleComment(validData)).then(res => setSelected(false))
    }
  }

  return (
    <div className="modal">
      <div className="modal_title">
        <div>Добавить комментарий { isContent ? ("Контент") : ("План-график") }</div>
        <img src={cancel} className="cancel" onClick={() => setSelected(false)} alt=""/>
      </div>
      <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="comment">Комментарий <Error name={errors.comment} /></label>
        <textarea name="comment" id="comment" ref={register(required)}></textarea>
        <button className="button">{practiceContentCommentLoading === "pending" || practiceScheduleCommentLoading === "pending" ? "Добавляем..." : "Добавить комментарий"}</button>
      </form>
    </div>
  )
}

export default CreateComment
