import React, { useEffect } from 'react'

import Spinner from '@components/layout/Spinner'

import PracticeInfo from '@features/practice/components/PracticeInfo'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeStudentWithPractice, selectPracticeStudent, selectPracticeStudentLoading } from '@features/practice/practiceStudentSlice'
import { getPracticeComissionQrs, selectPracticeComissions, selectPracticeComissionLoading } from '@features/practice/practiceComissionSlice'

const PracticeComissionQr = ({ match }) => {
  const dispatch = useDispatch()
  const practiceComissions = useSelector(selectPracticeComissions)
  const practiceStudent = useSelector(selectPracticeStudent)
  const practiceStudentLoading = useSelector(selectPracticeStudentLoading)
  const loading = useSelector(selectPracticeComissionLoading)

  useEffect(() => {
    dispatch(getPracticeStudentWithPractice(match.params.practiceStudentRandom)).then(res => {
      if (!res.payload) {
        return
      }

      dispatch(getPracticeComissionQrs(res.payload.data.id))
    })
  }, [])

  return (
    loading === "pending" ? <Spinner /> : 
      <div>
        <PracticeInfo practice={practiceStudent?.practice} student={practiceStudent?.student} practiceLoading={practiceStudentLoading} studentLoading={practiceStudentLoading} />
        
        { practiceComissions?.map(practiceComission => (
          practiceComission.signature ? 
          <div key={practiceComission.id} className="qr_signature">Член комиссии {`${practiceComission.teacher.user.last_name_ru} ${practiceComission.teacher.user.first_name_ru} поставил(а) подпись`}</div> : 
          <div key={practiceComission.id} className="qr_signature">Член комиссии {`${practiceComission.teacher.user.last_name_ru} ${practiceComission.teacher.user.first_name_ru} поставил(а) подпись`} НЕ поставил(а) подпись</div>
        ))}
      </div>
  )
}

export default PracticeComissionQr
