import React from 'react'

import { url } from '@utils/api'

// State
import { useDispatch } from 'react-redux'
import { updateAvatar, deleteAvatar } from '@features/auth/authSlice'
import { createAlert } from '@features/alert/alertSlice'

const DashboardAvatar = ({ user }) => {
  const dispatch = useDispatch()

  const handleAvatarChange = (e) => {
    if (e.target.files[0].size > 2048000) {
      dispatch(createAlert({
        message: "Максимально допустимый размер изображения 2 МБ",
        type: 'fail'
      }))

      return
    }

    let validData = new FormData()
    validData.append("avatar", e.target.files[0])

    dispatch(updateAvatar(validData))
  }

  const handleDeleteAvatar = () => {
    dispatch(deleteAvatar())
  }

  return (
    <div className="user_avatar_block">
      <div className="user_avatar shadow b-rad-50">
        <img
          src={user?.avatar ? `${url}/uploads/${user?.id}/avatar/${user?.avatar}` : `${process.env.PUBLIC_URL}/img/icons/no-avatar.png`}
          className="user_avatar_img"
          alt=""
        />
      </div>

      <label className="user_edit shadow b-rad-5" htmlFor="avatar">Новый аватар</label>
      <input type="file" name="avatar" id="avatar" accept="image/png, image/gif, image/jpeg" className="d-none" onChange={(e) => handleAvatarChange(e)} />

      { user?.avatar ? <label className="user_edit shadow b-rad-5" htmlFor="avatar" onClick={() => handleDeleteAvatar()}>Удалить аватар</label> : '' }
    </div>
  )
}

export default DashboardAvatar
