import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Icons
import edit from '@icons/edit.png'
import trash from '@icons/trash.png'

import DeleteChair from './DeleteChair'

import Spinner from '@components/layout/Spinner'
import Pagination from '@components/layout/Pagination'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getChairs, getPaginatedChairs, selectChairs, selectChairLoading } from '@features/chair/chairSlice'

const Chair = () => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedChair, setSelectedChair] = useState(null)
  const dispatch = useDispatch()
  const chairs = useSelector(selectChairs)
  const loading = useSelector(selectChairLoading)
  const [paginationMeta, setPaginationMeta] = useState({
    total: 0,
    current_page: 0,
    per_page: 0,
    links: []
  })

  useEffect(() => {
    dispatch(getChairs()).then(res => {
      if (!res.payload) {
        return
      }
      
      setPaginationMeta(res.payload.meta)
    })
  }, [dispatch])

  const handleDeleteForm = (chair) => {
    setDeleteModal(!deleteModal)
    setSelectedChair(chair)
  }

  const getData = (page) => {
    if (!page.url) {
      return
    }

    dispatch(getPaginatedChairs(page.url)).then(res => {
      if (!res.payload) {
        return
      }
      
      setPaginationMeta(res.payload.meta)
    })
  }

  return loading === "pending" ? ( <Spinner /> ) : (
    <Fragment>
          {deleteModal && <DeleteChair deleteModal={deleteModal} setDeleteModal={setDeleteModal} chair={selectedChair} />}

          <div className="table">
            <div className="table_create_title">
              <div className="table_title">Кафедры</div>
              <Link to="/chairs/create"><button className="table_create button">+ Добавить</button></Link>
            </div>
      
            <div className="table_wrapper"><table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Образовательная программа</th>
                  <th>Действия</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {chairs?.map(chair => (
                  <tr key={chair.id}>
                    <td>{chair.id}</td>
                    <td>{chair.name_ru}</td>
                    <td>{chair.program.name_ru}</td>
                    <td>
                      <Link to={`/chairs/update/${chair.id}`}><img className="admin_icons" src={edit} alt=""/></Link>
                      <img onClick={() => handleDeleteForm(chair)} className="admin_icons" src={trash} alt=""/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table></div>
            <Pagination links={paginationMeta.links} getData={getData} />
          </div>
    </Fragment>
    )
}

export default Chair
