import React, { Fragment, useEffect } from 'react'

import DropDown from '@components/layout/header/DropDown'

// State 
import { useSelector, useDispatch } from 'react-redux'
import { selectUser as selectAuthUser, selectIsAuthenticated } from '@features/auth/authSlice'
import { toggleMenu } from '@features/layout/layoutSlice'

const Header = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAuthUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const handleMenuOpen = () => {
    dispatch(toggleMenu())
  }

  return (
    <header className="header">
      <div className="container">
        <div className="header_inner">
          {isAuthenticated ? 
            <Fragment>
{/*               <form action="" className="header_search_form" method="POST">
                <label htmlFor="header_search_input" className="header_search_label">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icons/search.png`}
                    alt=""
                    className="header_search_input_img"
                  />
                  <input
                    type="text"
                    id="header_search_input"
                    name="header_search_input"
                    className="header_search_input"
                    placeholder="Поиск"
                  />
                </label>
              </form>

              <div className="header_notification_block">
                <img
                  src={`${process.env.PUBLIC_URL}/img/icons/notification.png`}
                  className="header_notification_img"
                  alt=""
                />
              </div> */}
              <div className="hamburger" onClick={() => handleMenuOpen()}>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <DropDown user={user} />
            </Fragment> : <a href="https://skma.edu.kz" target="_blank" rel="noreferrer" className="not_auth_header_link">skma.edu.kz</a>
          }
        </div>
      </div>
    </header>
  )
}

export default Header
