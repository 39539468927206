import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'

// State
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from '@features/auth/authSlice'

const NotFound = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const history = useHistory()
  
  return (
    <Fragment>
      <div className="not_found flex-1">
        <p className="not_found_number">404</p>
        <p className="not_found_text">Страница не найдена</p>
        { isAuthenticated ? <Fragment><Link to="/dashboard" className="not_found_link link">Вернуться в личный кабинет</Link><Link to="" onClick={() => history.goBack()} className="not_found_link link">Вернуться назад</Link></Fragment> : <Link to="/" className="not_found_link link">Главная</Link> }
      </div>
    </Fragment>
  )
}

export default NotFound
