import React, { Fragment } from 'react'

const PracticeStatsItem = ({ practiceStat, setReview, setReviewModal, setReviewName }) => {
  return (
    <Fragment>
      <p className="mb-10 b-top-dashed">
        {
          practiceStat?.practiceChairReview?.teacher_grade ? 
            <Fragment>Оценка на кафедре <strong>{practiceStat?.practiceChairReview?.teacher_grade}%</strong>
            </Fragment> : 
            <Fragment>Оценка на кафедре <strong>отсутствует</strong></Fragment>
        }
      </p>
      <p className="mb-10">
        {
          practiceStat?.practiceChairReview?.total_grade ? 
            <Fragment>
              Итоговая оценка <strong>{practiceStat?.practiceChairReview?.total_grade}%</strong>
            </Fragment> : 
            <Fragment>Итоговая оценка <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceBaseReview?.grade ? 
            <Fragment>
              Оценка руководителя базы практики <strong>{practiceStat?.practiceBaseReview?.grade}%</strong>
            </Fragment> : 
            <Fragment>Оценка руководителя базы практики <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceChairReview?.teacher_signature ? 
            <Fragment>Подпись руководителя практики на кафедре <strong>есть</strong></Fragment> : 
            <Fragment>Подпись руководителя практики на кафедре <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceChairReview?.head_of_chair_signature ? 
            <Fragment>Подпись заведующего кафедры <strong>есть</strong></Fragment> : 
            <Fragment>Подпись руководителя заведующего кафедры <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceBaseReview?.signature ? 
            <Fragment>Подпись руководителя базы практики <strong>есть</strong></Fragment> : 
            <Fragment>Подпись руководителя базы практики <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceStudentReview?.signature ? 
            <Fragment>Подпись студента <strong>есть</strong></Fragment> : 
            <Fragment>Подпись студента <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceChairReview?.teacher_review ? 
            <Fragment>Отзыв руководителя практики на кафедре <button className="button" onClick={() => {
              setReview(practiceStat?.practiceChairReview?.teacher_review)
              setReviewModal(true)
              setReviewName('Отзыв руководителя практики на кафедре')
            }}>есть</button></Fragment> : 
            <Fragment>Отзыв руководителя практики на кафедре <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="mb-10">
        {
          practiceStat?.practiceBaseReview?.review ? 
            <Fragment>Отзыв руководителя базы практики <button className="button" onClick={() => {
              setReview(practiceStat?.practiceBaseReview?.review)
              setReviewModal(true)
              setReviewName('Отзыв руководителя базы практики')
            }}>есть</button></Fragment> : 
            <Fragment>Отзыв руководителя базы практики <strong>отсутствует</strong></Fragment>
        }
      </p>

      <p className="b-bottom-dashed">
        {
          practiceStat?.practiceStudentReview?.review ? 
            <Fragment>Отчет студента <button className="button" onClick={() => {
              setReview(practiceStat?.practiceStudentReview?.review)
              setReviewModal(true)
              setReviewName('Отчет студента')
            }}>есть</button></Fragment> : 
            <Fragment>Отчет студента <strong>отсутствует</strong></Fragment>
        }
      </p>
    </Fragment>
  )
}

export default PracticeStatsItem
