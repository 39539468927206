import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useSelector, useDispatch } from 'react-redux'
import { deleteChair } from '@features/chair/chairSlice'

const DeleteChair = ({ deleteModal, setDeleteModal, chair }) => {
  const dispatch = useDispatch()

  const handleDeleteChair = (chair) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteChair(chair?.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Кафедру <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить кафедру {chair?.name_ru}</div>
            <button className="modal_delete_button button" onClick={() => handleDeleteChair(chair)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteChair
