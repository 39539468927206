import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Redirect, useHistory } from 'react-router-dom'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { Multiselect } from 'multiselect-react-dropdown'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { is, required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { createPractice, selectPracticeLoading } from '@features/practice/practiceSlice'
import { getAcademicYears, selectAcademicYears, selectAcademicYearLoading } from '@features/academic-year/academicYearSlice'
import { getBasesWithUser, selectBases, selectBaseLoading } from '@features/base/baseSlice'
import { selectUser } from '@features/auth/authSlice'
import { getTeacherUser, selectTeacherUser } from '@features/user/userSlice'

const CreatePractice = () => {
  const { register, handleSubmit, setValue, errors } = useForm()
  const [date, setDate] = useState([new Date(), new Date()])
  const history = useHistory()
  const dispatch = useDispatch()
  const academicYears = useSelector(selectAcademicYears)
  const bases = useSelector(selectBases)
  const loading = useSelector(selectPracticeLoading)
  const academicYearLoading = useSelector(selectAcademicYearLoading)
  const baseLoading = useSelector(selectBaseLoading)
  const user = useSelector(selectUser)
  const teacher = useSelector(selectTeacherUser)

  useEffect(() => {
    dispatch(getAcademicYears())
    dispatch(getBasesWithUser())
    dispatch(getTeacherUser(user?.id))
  }, [dispatch])

  useEffect(() => {
    register("baseUsers")
  }, [register])

  const onSelect = (selectedList) => {
    const baseUserIds = selectedList.map(({ id }) => id)
    setValue("baseUsers", baseUserIds)
  }

  const onRemove = (selectedList, selectedItem) => {
    const newList = selectedList.filter(item => item.id !== selectedItem.id)
    const baseIds = newList.map(({ id }) => id)
    setValue("baseUsers", baseIds)
  }

  const onSubmit = async (data) => {
    const start = date[0].toLocaleDateString('fr-CA')
    const end = date[1].toLocaleDateString('fr-CA')

    const validData = {
      ...data,
      teacher_id: teacher.id,
      start,
      end,
      history
    }
    
    dispatch(createPractice(validData))
  }

  if (!is('practiceChairLeader', user)) {
    return <Redirect to="/404" />
  }

  return loading === "pending" ? <Spinner /> : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Новая Практика</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name">
            Название
            <Error name={errors.name} />
          </label>
          <input type="text" id="name" name="name" ref={register(required)} />

          <label htmlFor="duration">
            Количество часов
            <Error name={errors.duration} />
          </label>
          <input type="number" id="duration" name="duration" ref={register(required)} />

          <label htmlFor="academic_year_id">Выберите Учебный год <Error name={errors.academic_year_id} /></label>
          <select name="academic_year_id" id="academic_year_id" ref={register(required)}>
          {academicYearLoading === "pending" ? <option value="">Загружаем...</option> : academicYears?.map(academicYear => (
            <option key={academicYear.id} value={academicYear.id}>{`${academicYear.start}/${academicYear.end}`}</option>
          ))}
          </select>

          <label htmlFor="bases">Выберите Базу Практики</label>
          <Multiselect
            options={bases}
            loading={baseLoading === "pending"}
            loadingMessage="Загружем..."
            emptyRecordMsg="Баз не найдено"
            avoidHighlightFirstOption={true}
            placeholder="Базы практик"
            hidePlaceholder={true}
            displayValue="name_ru"
            onSelect={onSelect}
            onRemove={onRemove}
          />

          <label htmlFor="language_id">Выберите Язык Практики <Error name={errors.language_id} /></label>
          <select name="language" id="language_id" ref={register(required)}>
            <option value="kz">Казахский</option>
            <option value="ru">Русский</option>
            <option value="en">Английский</option>
          </select>

          <label htmlFor="start">
            Дата практики
          </label>

          <DateTimeRangePicker
            onChange={setDate}
            value={date}
            format="dd.MM.yy"
          />

          <button className="button">Добавить</button>

          <Link className="crud_back" to="/chair-practices">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default CreatePractice
