import React, { Fragment } from 'react'

import { formatDate } from '@utils/helpers'

const PracticeInfo = ({ practice, student, dean, practiceLoading, studentLoading, deanLoading }) => {
  return (
    <Fragment>
      <div className="trainee_info">
        <div className="trainee_info_title">
          Дневник о прохождении производственной практики "{practiceLoading === "pending" ? "Загружаем..." : practice?.name}"
        </div>

        <ul className="trainee_info_list">
          <li className="trainee_info_item">
            Образовательная программа: "{studentLoading === "pending" ? "Загружаем..." : `${student?.group.chair.program.name_ru}`}"
          </li>
          <li className="trainee_info_item">
            Обучающийся: {studentLoading === "pending" ? "Загружаем..." : `${student?.user.last_name_ru} ${student?.user.first_name_ru}`}
          </li>
          <li className="trainee_info_item"> Учебный год: {practiceLoading === "pending" ? "Загружаем..." : `${practice?.academicYear.start}-${practice?.academicYear.end}`} г.г. Курс: {studentLoading === "pending" ? "Загружаем..." : `${student?.group.course} Группа: ${student?.group.name}`}</li>
          <li className="trainee_info_item">Дата прохождения практики: {practiceLoading === "pending" ? "Загружаем..." : `${formatDate(practice?.start)} - ${formatDate(practice?.end)}`}</li>
          <li className="trainee_info_item">Количество часов: {practiceLoading === "pending" ? "Загружаем..." : practice?.duration} (2 кредита)</li>
          <li className="trainee_info_item">Специалист по практике от ЮКМА: {practiceLoading === "pending" ? "Загружаем..." : `${practice?.teacher.user?.last_name_ru} ${practice?.teacher.user?.first_name_ru}`}</li>
        </ul>
      </div>
    </Fragment>
  )
}

export default PracticeInfo
