import React, { Fragment } from "react"

import cancel from "@icons/cancel.png"

// State
import { useDispatch } from "react-redux"
import { deleteStudent } from "@features/student/studentSlice"

const DeleteStudent = ({ deleteModal, setDeleteModal, student }) => {
  const dispatch = useDispatch()

  const handleDeleteStudent = (student) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteStudent(student?.id))
  }

  return (
    <Fragment>
      {deleteModal && (
        <div className="modal">
          <div className="modal_title">
            Удалить Студент{" "}
            <img
              className="cancel"
              onClick={() => setDeleteModal(!deleteModal)}
              src={cancel}
              alt=""
            />
          </div>

          <div>
            Вы уверены что хотите удалить пользователя?
          </div>
          <button
            className="button modal_delete_button"
            onClick={() => handleDeleteStudent(student)}
          >
            Удалить
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default DeleteStudent
