import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deletePracticeStudent } from '@features/practice/practiceStudentSlice'

const DeleteStudent = ({ deleteModal, setDeleteModal, practiceStudent }) => {
  const dispatch = useDispatch()
  
  const handleDeletePractice = (practiceStudent) => {
    setDeleteModal(!deleteModal)
    dispatch(deletePracticeStudent(practiceStudent.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Студента из Практики <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить Студента {`${practiceStudent.user.last_name_ru} ${practiceStudent.user.first_name_ru}`} из практики</div>
            <button className="button modal_delete_button" onClick={() => handleDeletePractice(practiceStudent)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteStudent
