import React from 'react'

import DashboardRoles from './components/DashboardRoles'
import DashboardAvatar from './components/DashboardAvatar'
import DashboardUserName from './components/DashboardUserName'

const AdminDashboard = ({ user }) => {
  return (
    <div className="mt-15">
          <div className="user">
            <DashboardAvatar user={user} />

            <div className="user_info">
              <DashboardUserName user={user} />

              <span className="user_online">В сети</span>

              <div className="user_private">
                <div className="user_private_heading">Личная информация:</div>

                <div className="user_private_block">
                  <div className="user_private_title">Кто Вы?</div>
                  <div className="user_private_info">
                    "Администратор SKMA Online"
                  </div>
                </div>

                <DashboardRoles user={user} />
              </div>
            </div>
          </div>
    </div>
  )
}

export default AdminDashboard
