import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useDispatch } from 'react-redux'
import { deletePracticeContent } from '@features/practice/practiceContentSlice'

const DeletePracticeContent = ({ deleteContentModal, setDeleteContentModal, content }) => {
  const dispatch = useDispatch()

  const handleDeleteContent = (contentId) => {
    setDeleteContentModal(!deleteContentModal)
    dispatch(deletePracticeContent(contentId))
  }

  return (
    <Fragment>
      {
        deleteContentModal &&
          <div className="modal">
            <div className="modal_title">Удалить контент <img className="cancel" 
            onClick={() => setDeleteContentModal(!deleteContentModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить?</div>
            <button className="button modal_delete_button" onClick={() => handleDeleteContent(content.uuid)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeletePracticeContent
