import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useSelector, useDispatch } from 'react-redux'
import { deleteGroup } from '@features/group/groupSlice'

const DeleteGroup = ({ deleteModal, setDeleteModal, group }) => {
  const dispatch = useDispatch()

  const handleDeleteGroup = (group) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteGroup(group?.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Группу <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить группу {group?.name}</div>
            <button className="modal_delete_button button" onClick={() => handleDeleteGroup(group)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteGroup
