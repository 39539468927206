import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getProgram, updateProgram, selectProgram, selectProgramLoading } from '@features/program/programSlice'
import { getFaculties, selectFaculties, selectFacultyLoading } from '@features/faculty/facultySlice'

const UpdateProgram = ({ match }) => {
  const { register, handleSubmit, reset, errors } = useForm()
  const history =  useHistory()
  const dispatch = useDispatch()
  const program = useSelector(selectProgram)
  const faculties = useSelector(selectFaculties)
  const loading = useSelector(selectProgramLoading)
  const facultyLoading = useSelector(selectFacultyLoading)

  useEffect(() => {
    dispatch(getProgram(match.params.programId))
  }, [dispatch])

  useEffect(() => {
    dispatch(getFaculties())
  }, [dispatch])

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      id: program.id,
      history
    }

    dispatch(updateProgram(validData))
  }

  return loading === "pending" ? (<Spinner />) : (
    <Fragment>
      <div className="crud">
        <div className="crud_title">Обновить Образовательную программу</div>

        <form className="crud_form" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name_ru">
            Название на русском
            <Error name={errors.name_ru} />
          </label>
          <input type="text" id="name_ru" defaultValue={program?.name_ru} name="name_ru" ref={register(required)} />

          <label htmlFor="name_kz">
            Название на казахском
            <Error name={errors.name_kz} />
          </label>
          <input type="text" id="name_kz" defaultValue={program?.name_kz} name="name_kz" ref={register(required)} />

          <label htmlFor="name_en">
            Название на английском
            <Error name={errors.name_en} />
          </label>
          <input type="text" id="name_en" defaultValue={program?.name_en} name="name_en" ref={register(required)} />

          <label htmlFor="faculty_id">Выберите Факультет</label>
            <select name="faculty_id" ref={register}>
            {facultyLoading === "pending" ? (
              <option>Загружаем...</option>
            ) : faculties?.map(faculty => (
              <option key={faculty?.id} value={faculty?.id} selected={faculty?.id === program?.faculty.id}>{faculty?.name_ru}</option>
            ))}
          </select>

          <button className="button">Изменить</button>

          <Link className="crud_back" to="/programs">Назад</Link>
        </form>
      </div>
    </Fragment>
  )
}

export default UpdateProgram
