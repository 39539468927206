import React, { Fragment } from 'react'

import DOMPurify from "dompurify";

// Icons
import cancel from '@icons/cancel.png'

const PracticeStatsReview = ({ review, reviewModal, reviewName, setReviewModal }) => {
  return (
    <Fragment>
      {
        reviewModal &&
          <div className="modal">
            <div className="modal_title">{reviewName} <img className="cancel" 
            onClick={() => setReviewModal(!reviewModal)} src={cancel} alt=""/></div>

            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(review),
              }}
            />
            
          </div>
      }
    </Fragment>
  )
}

export default PracticeStatsReview
