import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice contents requests
export const getPracticeContent = createAsyncThunk(
  'practiceContent/practiceContentById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceContent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-contents/${data}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeContents = createAsyncThunk(
  'practiceContent/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceContent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-contents/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeContent = createAsyncThunk(
  'practiceContent/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceContent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-contents/${data.practiceId}/students/${data.studentId}`, data, config)
      data.history.push(`/student-practices/${data.practiceId}/students/${data.studentId}/plan`)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeContentComment = createAsyncThunk(
  'practiceContentComment/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceContent
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-contents/${data.uuid}/comment`, data, config)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeContent = createAsyncThunk(
  'practice/updateContent',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceContent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-contents/${data.contentId}`, data, config)
      data.history.push(`/student-practices/${data.practiceId}/students/${data.studentId}/plan`)

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeContentComment = createAsyncThunk(
  'practiceContentComment/update',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceContent
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-contents/${data.uuid}/comment`, data, config)

      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeContent = createAsyncThunk(
  'practiceContent/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceContent
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-contents/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeContentComment = createAsyncThunk(
  'practiceContentComment/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, commentLoading } = getState().practiceContent
    if (commentLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-contents/${data}/comment`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceContentSlice = createSlice({
  name: 'practiceContent',
  initialState: {
    loading: 'idle',
    commentLoading: 'idle',
    practiceContent: null,
    practiceContents: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Student reducers
    // One
    [getPracticeContent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeContent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceContent = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeContent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeContents.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeContents.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceContents = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeContents.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeContent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeContent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceContent = action.payload.data
        state.practiceContents.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeContent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Create practice content comment
    [createPracticeContentComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeContentComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceContent = action.payload.data
        state.practiceContents = state.practiceContents.map(practiceContent =>
          practiceContent.uuid === action.payload.data.uuid ? practiceContent = action.payload.data : practiceContent
        )
        state.currentRequestId = undefined
      }
    },
    [createPracticeContentComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeContent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeContent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceContent = action.payload.data
        state.practiceContents = state.practiceContents.map(practiceContent =>
          practiceContent.uuid === action.payload.data.uuid ? practiceContent = action.payload.data : practiceContent
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeContent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update practice content comment
    [updatePracticeContentComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeContentComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceContent = action.payload.data
        state.practiceContents = state.practiceContents.map(practiceContent =>
          practiceContent.uuid === action.payload.data.uuid ? practiceContent = action.payload.data : practiceContent
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeContentComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeContent.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeContent.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceContent = null
        state.practiceContents = state.practiceContents.filter(practiceContent => practiceContent.uuid !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeContent.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete practice content comment
    [deletePracticeContentComment.pending]: (state, action) => {
      if (state.commentLoading === 'idle') {
        state.commentLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeContentComment.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.practiceContent = null
        state.practiceContents.map(practiceContent => practiceContent.practiceContentComments = practiceContent.practiceContentComments.filter(comment => comment.uuid !== action.payload))
        state.currentRequestId = undefined
      }
    },
    [deletePracticeContentComment.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.commentLoading === 'pending' && state.currentRequestId === requestId) {
        state.commentLoading = 'idle'
        state.currentRequestId = undefined
      }
    },
  }
})

export const selectPracticeContentLoading = state => state.practiceContent.loading
export const selectPracticeContent = state => state.practiceContent.practiceContent
export const selectPracticeContents = state => state.practiceContent.practiceContents
export const selectPracticeContentCommentLoading = state => state.practiceContent.commentLoading

export default practiceContentSlice.reducer
