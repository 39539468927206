import React, { Fragment } from 'react'

import cancel from '@icons/cancel.png'

// State
import { useSelector, useDispatch } from 'react-redux'
import { deleteProgram } from '@features/program/programSlice'

const DeleteProgram = ({ deleteModal, setDeleteModal, program }) => {
  const dispatch = useDispatch()

  const handleDeleteProgram = (program) => {
    setDeleteModal(!deleteModal)
    dispatch(deleteProgram(program?.id))
  }

  return (
    <Fragment>
      {
        deleteModal &&
          <div className="modal">
            <div className="modal_title">Удалить Образовательную программу <img className="cancel" 
            onClick={() => setDeleteModal(!deleteModal)} src={cancel} alt=""/></div>

            <div>Вы уверены что хотите удалить образовательную программу {program?.name}</div>
            <button className="modal_delete_button button" onClick={() => handleDeleteProgram(program)}>Удалить</button>
          </div>
      }
    </Fragment>
  )
}

export default DeleteProgram
