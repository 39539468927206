import React, { Fragment } from "react"
import ListItem from './ListItem'

const AdminNavigation = () => {
  return (
    <Fragment>
      <ListItem img="my-profile" title="Факультеты" link="faculties" />
      <ListItem img="my-profile" title="Образовательные программы" link="programs" />
      <ListItem img="my-profile" title="Кафедры" link="chairs" />
      <ListItem img="my-profile" title="Группы" link="groups" />
      <ListItem img="my-profile" title="Пользователи" link="users" />
      <ListItem img="my-profile" title="Учебные годы" link="academic-years" />
      <ListItem img="my-profile" title="Базы Практик" link="bases" />
    </Fragment>
  )
}

export default AdminNavigation
