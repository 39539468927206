import React, { useState, useEffect } from 'react'

// State
import { useDispatch } from 'react-redux'
import { removeAlert } from '@features/alert/alertSlice'

const Alert = ({ alert }) => {
  const dispatch = useDispatch()
  const [display, setDisplay] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setDisplay(false)
      dispatch(removeAlert(alert.id))
    }, 5000)
  }, [alert.id])

  return (
    display &&       
      <div key={alert.id} className={`alert alert-${alert.type}`}>
        {alert.message}
      </div>
  )
}

export default Alert
