import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import JoditEditor from "jodit-react"
import { createAlert } from '@features/alert/alertSlice'

import Spinner from '@components/layout/Spinner'
import Error from '@components/layout/Error'

import { required } from '@utils/helpers'

// State
import { useSelector, useDispatch } from 'react-redux'
import { getPracticeBaseReview, updatePracticeBaseReview, selectPracticeBaseReview, selectPracticeBaseReviewLoading } from '@features/practice/practiceBaseReviewSlice'
import { getBaseUser, selectBaseUser, selectUserLoading } from '@features/user/userSlice'
import { selectUser } from '@features/auth/authSlice'

const PracticeReview = ({ match }) => {
  const [reviewLoading, setReviewLoading] = useState("pending")
  const [content, setContent] = useState('')
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const editor = useRef(null)
  const dispatch = useDispatch()
  const loading = useSelector(selectPracticeBaseReviewLoading)
  const practiceBaseReview = useSelector(selectPracticeBaseReview)
  const userLoading = useSelector(selectUserLoading)
  const user = useSelector(selectUser)
  const practiceId = match.params.practiceId
  const studentId = match.params.studentId

  useEffect(() => {
    const data = {
      practiceId,
      studentId
    }

    dispatch(getBaseUser(user?.id)).then(res => {
      dispatch(getPracticeBaseReview({
        ...data,
        baseUserId: res.payload.data.id
      })).then(res => {
        setContent(res.payload.data.review)
        setReviewLoading("fulfilled")
      })
    })
  }, [dispatch])

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: true
    },
  }

  const onBlurEditor = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
    
  const onEditorChange = (editorNewValue) => {
    editor.current.value = editorNewValue
  }

  const onSubmit = data => {
    if (!editor.current.value) {
      dispatch(createAlert({
        message: "Заполните все поля",
        type: 'fail'
      }))
      
      return
    }

    setContent(editor.current.value)
    
    setReviewLoading("pending")
    const updateData = {
      ...data,
      uuid: practiceBaseReview?.uuid,
      review: editor.current.value,
      history,
      practiceId
    }
    
    dispatch(updatePracticeBaseReview(updateData)).then(res => {
      setReviewLoading("fulfilled")
      history.push(`/base-practices/${practiceId}/students`)
    })
  }

  return (
    reviewLoading === "pending" ? (<Spinner />) : (
      <Fragment>
        <div className="trainee">
          <div className="trainee_title">
            Отзыв
            <Link to={`/base-practices/${practiceId}/students`} className="table_create link">Список практикантов</Link>
          </div>

          <form action="" className="flex-column mt-10" onSubmit={handleSubmit(onSubmit)}>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              /* onBlur={newContent => setContent(newContent.target.innerHTML)}
              onChange={newContent => {}} */
              onBlur={onBlurEditor}
              onChange={onEditorChange}
            />

            <label htmlFor="base_grade" className="mt-15">Оценка на базе практики <Error name={errors.grade} /></label>
            <input type="number" name="grade" id="base_grade" defaultValue={practiceBaseReview?.grade} ref={register(required)} />
            <button className="button" type="submit">Сохранить</button>
          </form>
        </div>
      </Fragment>
    )
  )
}

export default PracticeReview
