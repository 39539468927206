import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { url, config } from '@utils/api'
import setAuthToken from '@utils/setAuthToken'
import { createAlert } from '@features/alert/alertSlice'

// *** Practice schedules requests
export const getPracticeChairReview = createAsyncThunk(
  'practiceChairReview/practiceChairReviewById',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceChairReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-chair-reviews/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const getPracticeChairReviews = createAsyncThunk(
  'practiceChairReview/list',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceChairReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.get(`${url}/api/practice-chair-reviews/${data.practiceId}/students/${data.studentId}`, config)
      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const createPracticeChairReview = createAsyncThunk(
  'practiceChairReview/create',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceChairReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.post(`${url}/api/practice-chair-reviews/${data.practiceId}/students/${data.studentId}`, data, config)
      data.history.push(`/chair-practices/${data.practiceId}/students/${data.studentId}/plan`)

      dispatch(createAlert({
        message: "Данные сохранены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const updatePracticeChairReview = createAsyncThunk(
  'practice/updateReview',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceChairReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-chair-reviews/${data.uuid}`, data, config)
      dispatch(createAlert({
        message: "Данные изменены",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const deletePracticeChairReview = createAsyncThunk(
  'practiceChairReview/delete',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, loading } = getState().practiceChairReview
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      await axios.delete(`${url}/api/practice-chair-reviews/${data}`, config)

      dispatch(createAlert({
        message: "Данные удалены",
        type: 'success'
      }))

      return data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const addPracticeChairSignature = createAsyncThunk(
  'practice/addPracticeChairSignature',
  async (data, { getState, requestId, dispatch }) => {
    const { currentRequestId, addSignatureLoading } = getState().practiceChairReview
    if (addSignatureLoading !== 'pending' || requestId !== currentRequestId) {
      return
    }

    setAuthToken(getState().auth.token)

    try {
      const response = await axios.put(`${url}/api/practice-chair-reviews/${data.uuid}/signature`, data, config)

      dispatch(createAlert({
        message: "Подпись поставлен",
        type: 'success'
      }))

      return response.data
    } catch (error) {
      dispatch(createAlert({
        message: error.response.data.error || "Что-то пошло не так. Попробуйте еще раз",
        type: 'fail'
      }))
      throw error
    }
  }
)

export const practiceChairReviewSlice = createSlice({
  name: 'practiceChairReview',
  initialState: {
    loading: 'idle',
    addSignatureLoading: 'idle',
    practiceChairReview: null,
    practiceChairReviews: []
  },
  reducers: {},
  extraReducers: {
    // *** Practice Chair reducers
    // One
    [getPracticeChairReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeChairReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceChairReview = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeChairReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // List
    [getPracticeChairReviews.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [getPracticeChairReviews.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceChairReviews = action.payload.data
        state.currentRequestId = undefined
      }
    },
    [getPracticeChairReviews.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },
    // create
    [createPracticeChairReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [createPracticeChairReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceChairReview = action.payload.data
        state.practiceChairReviews.push(action.payload.data)
        state.currentRequestId = undefined
      }
    },
    [createPracticeChairReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Update
    [updatePracticeChairReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [updatePracticeChairReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceChairReview = action.payload.data
        state.practiceChairReviews = state.practiceChairReviews.map(practiceChairReview =>
          practiceChairReview.uuid === action.payload.data.uuid ? practiceChairReview = action.payload.data : practiceChairReview
        )
        state.currentRequestId = undefined
      }
    },
    [updatePracticeChairReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Delete
    [deletePracticeChairReview.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [deletePracticeChairReview.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.practiceChairReview = null
        state.practiceChairReviews = state.practiceChairReviews.filter(practiceChairReview => practiceChairReview.uuid !== action.payload)
        state.currentRequestId = undefined
      }
    },
    [deletePracticeChairReview.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.currentRequestId = undefined
      }
    },

    // Add signature
    [addPracticeChairSignature.pending]: (state, action) => {
      if (state.addSignatureLoading === 'idle') {
        state.addSignatureLoading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    },
    [addPracticeChairSignature.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (state.addSignatureLoading === 'pending' && state.currentRequestId === requestId) {
        state.addSignatureLoading = 'idle'
        state.practiceChairReview = action.payload.data
        state.practiceChairReviews = state.practiceChairReviews.map(practiceChairReview =>
          practiceChairReview.uuid === action.payload.data.uuid ? practiceChairReview = action.payload.data : practiceChairReview
        )
        state.currentRequestId = undefined
      }
    },
    [addPracticeChairSignature.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (state.addSignatureLoading === 'pending' && state.currentRequestId === requestId) {
        state.addSignatureLoading = 'idle'
        state.currentRequestId = undefined
      }
    },

  }
})

export const selectPracticeChairReviewLoading = state => state.practiceChairReview.loading
export const selectAddSignatureLoading = state => state.practiceChairReview.addSignatureLoading
export const selectPracticeChairReview = state => state.practiceChairReview.practiceChairReview
export const selectPracticeChairReviews = state => state.practiceChairReview.practiceChairReviews

export default practiceChairReviewSlice.reducer
