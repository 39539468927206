export const url = "https://online-api.skma.edu.kz"
/* export const url = "https://online-api.torero.kz" */
/* export const url = "http://localhost:8000" */
/* export const url = "https://forget-api.skma.edu.kz" */
export const config = {
  header: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
}